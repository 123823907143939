import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { APIURL } from "../../../Config";
import { toast } from "react-toastify";

function ApprovedUpdateResidencd({ data, setFormStates, formStates }) {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");

  console.log("data::: ", data);

  const residenceInitialValues = {
    clientId: data?.clientId,
    doneBy: data?.doneBy,
    doneOn: data?.doneOn,
    residenceUpdatedBy: id,
    residenceDateOfVisit: data?.residenceDateOfVisit,
    residenceTimeOfVisit: data?.residenceTimeOfVisit,
    residenceApplicantName: data?.residenceApplicantName,
    residenceContactNo: data?.residenceContactNo,
    residenceCurrentAddress: data?.residenceCurrentAddress,
    residencePermanentAddress: data?.residencePermanentAddress,
    residenceNearestLandmark: data?.residenceNearestLandmark,
    residenceNameOfPersonMet: data?.residenceNameOfPersonMet,
    residenceContactnoOfPersonMet: data?.residenceContactnoOfPersonMet,
    residenceRelationWithApplicant: data?.residenceRelationWithApplicant,
    residenceRelationshipWithApplicant:
      data?.residenceRelationshipWithApplicant,
    residenceEaseOfLocating: data?.residenceEaseOfLocating,
    residenceLandmarkOwnership: data?.residenceLandmarkOwnership,
    residenceLandloardName: data?.residenceLandloardName,
    residenceLandloardContactno: data?.residenceLandloardContactno,
    residenceCustomerPreviousAddress: data?.residenceCustomerPreviousAddress,
    residenceNoOfYearsStaying: data?.residenceNoOfYearsStaying,
    residenceNoOfMonthsStaying: data?.residenceNoOfMonthsStaying,
    residenceStayConfirmedBy: data?.residenceStayConfirmedBy,
    residenceTypeOfResidence: data?.residenceTypeOfResidence,
    residenceAreaLocality: data?.residenceAreaLocality,
    residenceTotalMemberInFamily: data?.residenceTotalMemberInFamily,
    residenceDetailsOfFamilyMember: data?.residenceDetailsOfFamilyMember,
    residenceEarningMemberInFamily: data?.residenceEarningMemberInFamily,
    residenceDetailsOfEarningMember: data?.residenceDetailsOfEarningMember,
    residenceFirstNeighbourName: data?.residenceFirstNeighbourName,
    residenceFirstNeighbourMobno: data?.residenceFirstNeighbourMobno,
    residenceFirstNeighbourAddress: data?.residenceFirstNeighbourAddress,
    residenceFirstNeighbourHowlongknow:
      data?.residenceFirstNeighbourHowlongknow,
    residenceFirstNeighbourBehaviour: data?.residenceFirstNeighbourBehaviour,
    residenceFirstNeighbourNegativeBehaviour:
      data?.residenceFirstNeighbourNegativeBehaviour,
    residenceFirstNeighbourAnyIllegalActivity:
      data?.residenceFirstNeighbourAnyIllegalActivity,
    residenceSecondNeighbourName: data?.residenceSecondNeighbourName,
    residenceSecondNeighbourMobno: data?.residenceSecondNeighbourMobno,
    residenceSecondNeighbourAddress: data?.residenceSecondNeighbourAddress,
    residenceSecondNeighbourHowlongknow:
      data?.residenceSecondNeighbourHowlongknow,
    residenceSecondNeighbourBehaviour: data?.residenceSecondNeighbourBehaviour,
    residenceSecondNeighbourNegativeBehaviour:
      data?.residenceSecondNeighbourNegativeBehaviour,
    residenceSecondNeighbourAnyIllegalActivity:
      data?.residenceSecondNeighbourAnyIllegalActivity,
    residenceAditionalCheckName: data?.residenceAditionalCheckName,
    residenceAditionalCheckMobno: data?.residenceAditionalCheckMobno,
    residenceAditionalCheckAddress: data?.residenceAditionalCheckAddress,
    residenceAditionalCheckFeedbackHowlongknow:
      data?.residenceAditionalCheckFeedbackHowlongknow,
    residenceAditionalCheckFeedbackBehaviour:
      data?.residenceAditionalCheckFeedbackBehaviour,
    residenceAditionalCheckNegativeBehaviour:
      data?.residenceAditionalCheckNegativeBehaviour,
    residenceAditionalCheckAnyIllegalActivity:
      data?.residenceAditionalCheckAnyIllegalActivity,
    residenceAnyIllegalActivity: data?.residenceAnyIllegalActivity,
    residenceProfileFallingStatus: data?.residenceProfileFallingStatus,
    residenceDetailsRemark: data?.residenceDetailsRemark,
    residenceDedupStatus: data?.residenceDedupStatus,
    residenceDedupDetails: data?.residenceDedupDetails,
    residenceFinalStatus: data?.residenceFinalStatus,
    residenceVerifierName: data?.residenceVerifierName,
    residenceManagerName: data?.residenceManagerName,
    residenceAgencyName: data?.residenceAgencyName,
    residenceVerificationStatus: data?.residenceVerificationStatus,
    residenceLandmark: data?.residenceLandmark,
    residenceAnyMatchFoundInNegativeDatabase:
      data?.residenceAnyMatchFoundInNegativeDatabase,
    residenceProfileOfApplicant: data?.residenceProfileOfApplicant,
  };

  const residenceSchema = Yup.object().shape({
    residenceApplicantName: Yup.string().required("Applicant name is required"),
    residenceContactNo: Yup.number()
      .required("Contact number is required")
      .min(10, "Contact number must be 10 digits")
      .max(10, "Contact number must be 10 digits"),
    residenceCurrentAddress: Yup.string().required(
      "Current address is required"
    ),
    residencePermanentAddress: Yup.string().required(
      "Permanent address is required"
    ),
    residenceNearestLandmark: Yup.string().required(
      "Nearest landmark is required"
    ),
    residenceNameOfPersonMet: Yup.string().required(
      "Name of person met is required"
    ),
    residenceContactnoOfPersonMet: Yup.number()
      .required("Contact number of person met is required")
      .min(10, "Contact number must be 10 digits")
      .max(10, "Contact number must be 10 digits"),
    residenceRelationWithApplicant: Yup.string().required(
      "Relation with applicant is required"
    ),
    residenceEaseOfLocating: Yup.string().required(
      "Ease of locating is required"
    ),
    residenceLandmarkOwnership: Yup.string().required(
      "Landmark ownership is required"
    ),
    residenceNoOfYearsStaying: Yup.number().required(
      "Number of years staying is required"
    ),
    residenceNoOfMonthsStaying: Yup.number().required(
      "Number of months staying is required"
    ),
    residenceStayConfirmedBy: Yup.string().required(
      "Stay confirmed by is required"
    ),
    residenceTypeOfResidence: Yup.string().required(
      "Type of residence is required"
    ),
    residenceAreaLocality: Yup.string().required("Area locality is required"),
    residenceTotalMemberInFamily: Yup.number().required(
      "Total members in family is required"
    ),
    residenceDetailsOfFamilyMember: Yup.string().required(
      "Details of family member is required"
    ),
    residenceEarningMemberInFamily: Yup.string().required(
      "Earning members in family is required"
    ),
    residenceDetailsOfEarningMember: Yup.string().required(
      "Details of earning member is required"
    ),
    residenceFirstNeighbourName: Yup.string().required(
      "First neighbour name is required"
    ),
    residenceFirstNeighbourMobno: Yup.number()
      .required("First neighbour mobile number is required")
      .min(10, "Mobile number must be 10 digits")
      .max(10, "Mobile number must be 10 digits"),
    residenceFirstNeighbourAddress: Yup.string().required(
      "First neighbour address is required"
    ),
    residenceFirstNeighbourHowlongknow: Yup.string().required(
      "How long known is required"
    ),
    residenceFirstNeighbourBehaviour: Yup.string().required(
      "Behaviour is required"
    ),
    residenceFirstNeighbourNegativeBehaviour: Yup.string().required(
      "Negative behaviour is required"
    ),
    residenceFirstNeighbourAnyIllegalActivity: Yup.string().required(
      "Any illegal activity is required"
    ),
    residenceFirstNeighbourAdditionalCheckName: Yup.string().required(
      "Additional check name is required"
    ),
    residenceFirstNeighbourAdditionalCheckMobno: Yup.number()
      .required("Additional check mobile number is required")
      .min(10, "Mobile number must be 10 digits")
      .max(10, "Mobile number must be 10 digits"),
    residenceFirstNeighbourAdditionalCheckAddress: Yup.string().required(
      "Additional check address is required"
    ),
    residenceFirstNeighbourAdditionalCheckFeedbackHowlongknow:
      Yup.string().required("Feedback how long known is required"),
    residenceFirstNeighbourAdditionalCheckFeedbackBehaviour:
      Yup.string().required("Feedback behaviour is required"),
    residenceFirstNeighbourAdditionalCheckNegativeBehaviour:
      Yup.string().required("Feedback negative behaviour is required"),
    residenceFirstNeighbourAdditionalCheckAnyIllegalActivity:
      Yup.string().required("Feedback any illegal activity is required"),
    residenceSecondNeighbourName: Yup.string().required(
      "Second neighbour name is required"
    ),
    residenceSecondNeighbourMobno: Yup.number()
      .required("Second neighbour mobile number is required")
      .min(10, "Mobile number must be 10 digits")
      .max(10, "Mobile number must be 10 digits"),
    residenceSecondNeighbourAddress: Yup.string().required(
      "Second neighbour address is required"
    ),
    residenceSecondNeighbourHowlongknow: Yup.string().required(
      "How long known is required"
    ),
    residenceSecondNeighbourBehaviour: Yup.string().required(
      "Behaviour is required"
    ),
    residenceSecondNeighbourNegativeBehaviour: Yup.string().required(
      "Negative behaviour is required"
    ),
    residenceSecondNeighbourAnyIllegalActivity: Yup.string().required(
      "Any illegal activity is required"
    ),
    residenceAditionalCheckName: Yup.string().required(
      "Additional check name is required"
    ),
    residenceAditionalCheckMobno: Yup.number()
      .required("Additional check mobile number is required")
      .min(10, "Mobile number must be 10 digits")
      .max(10, "Mobile number must be 10 digits"),
    residenceAditionalCheckAddress: Yup.string().required(
      "Additional check address is required"
    ),
    residenceAditionalCheckFeedbackHowlongknow: Yup.string().required(
      "Feedback how long known is required"
    ),
    residenceAditionalCheckFeedbackBehaviour: Yup.string().required(
      "Feedback behaviour is required"
    ),
    residenceAditionalCheckNegativeBehaviour: Yup.string().required(
      "Feedback negative behaviour is required"
    ),
    residenceAditionalCheckAnyIllegalActivity: Yup.string().required(
      "Feedback any illegal activity is required"
    ),
    residenceDetailsRemark: Yup.string().required("Details remark is required"),
    residenceFinalStatus: Yup.string().required("Final status is required"),
    residenceVerifierName: Yup.string().required("Verifier name is required"),
    residenceManagerName: Yup.string().required("Manager name is required"),
    residenceVerificationStatus: Yup.number().required(
      "Verification status is required"
    ),
    residenceLandmark: Yup.string().required("Landmark is required"),
    residenceAnyMatchFoundInNegativeDatabase: Yup.string().required(
      "Any match found in negative database is required"
    ),
  });

  const handleSubmitResidence = (values, { setSubmitting }) => {
    console.log("Form values:", values);
    // Here you would typically send the form values to your API
    setSubmitting(false);
    axios
      .request(`${APIURL}/v2/api/cpv/updateCpvResidenceVerification`, {
        method: "POST",
        data: values,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        console.log("response::: ", response);
        toast.success(response?.data);
        setFormStates(!formStates.residence);
      })
      .catch((error) => {
        console.log(error);
        // setIsLoader(false);
      });
  };
  return (
    <div className="mt-3">
      <h4>Residence Verification</h4>
      <Formik
        initialValues={residenceInitialValues}
        // validationSchema={residenceSchema}
        onSubmit={handleSubmitResidence}
      >
        {({ isSubmitting, errors, touched, values }) => (
          <Form>
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Applicant Name</label>
                  <Field
                    type="text"
                    name="residenceApplicantName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceApplicantName"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Contact No</label>
                  <Field
                    type="number"
                    name="residenceContactNo"
                    className="form-control"
                    min="0"
                    max="9999999999"
                    onInput={(e) => {
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                    }}
                  />
                  <ErrorMessage
                    name="residenceContactNo"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Current Address</label>
                  <Field
                    type="text"
                    name="residenceCurrentAddress"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceCurrentAddress"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Permanent Address</label>
                  <Field
                    type="text"
                    name="residencePermanentAddress"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residencePermanentAddress"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Nearest Landmark</label>
                  <Field
                    type="text"
                    name="residenceNearestLandmark"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceNearestLandmark"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Name of Person Met</label>
                  <Field
                    type="text"
                    name="residenceNameOfPersonMet"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceNameOfPersonMet"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Contact No of Person Met</label>
                  <Field
                    type="number"
                    name="residenceContactnoOfPersonMet"
                    className="form-control"
                    min="0"
                    max="9999999999"
                    onInput={(e) => {
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                    }}
                  />
                  <ErrorMessage
                    name="residenceContactnoOfPersonMet"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Relation with Applicant</label>
                  <Field
                    type="text"
                    name="residenceRelationWithApplicant"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceRelationWithApplicant"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Relationship with Applicant</label>
                  <Field
                    as="select"
                    id="residenceRelationshipWithApplicant"
                    name="residenceRelationshipWithApplicant"
                    className={`form-control ${
                      touched.residenceRelationshipWithApplicant &&
                      errors.residenceRelationshipWithApplicant
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Select Relationship</option>
                    <option value="Self">Self</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Wife">Wife</option>
                    <option value="Son/Daughter">Son/Daughter</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Relative">Relative</option>
                    <option value="Other">Other</option>
                  </Field>
                  <ErrorMessage
                    name="residenceRelationshipWithApplicant"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Ease of Locating</label>
                  <Field
                    as="select"
                    id="residenceEaseOfLocating"
                    name="residenceEaseOfLocating"
                    className="form-control"
                  >
                    <option value="">Select Ease of Locating</option>
                    <option value="Easy">Easy</option>
                    <option value="Difficult">Difficult</option>
                    <option value="Untrackable">Untrackable</option>
                  </Field>

                  <ErrorMessage
                    name="residenceEaseOfLocating"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Ownership of Residence</label>
                  <Field
                    as="select"
                    id="residenceLandmarkOwnership"
                    name="residenceLandmarkOwnership"
                    className="form-control"
                  >
                    <option value="">Select Ownership Type</option>
                    <option value="Self Owned">Self Owned</option>
                    <option value="Owned by Parent">Owned by Parent</option>
                    <option value="Owned by Relative">Owned by Relative</option>
                    <option value="Rented">Rented</option>
                    <option value="Co. Accommodation">Co. Accommodation</option>
                    <option value="Paying Guest">Paying Guest</option>
                  </Field>

                  <ErrorMessage
                    name="residenceLandmarkOwnership"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>No of Years Staying</label>
                  <Field
                    type="number"
                    name="residenceNoOfYearsStaying"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceNoOfYearsStaying"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>No of Months Staying</label>
                  <Field
                    type="number"
                    name="residenceNoOfMonthsStaying"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceNoOfMonthsStaying"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Stay Confirmed by</label>
                  <Field
                    as="select"
                    id="residenceStayConfirmedBy"
                    name="residenceStayConfirmedBy"
                    className="form-control"
                  >
                    <option value="">Select Stay Confirmed By</option>
                    <option value="Name Plate">Name Plate</option>
                    <option value="Neighbour">Neighbour</option>
                    <option value="Security">Security</option>
                    <option value="Person Met">Person Met</option>
                    <option value="Could not confirm">Could not confirm</option>
                    <option value="Other">Other</option>
                  </Field>

                  <ErrorMessage
                    name="residenceStayConfirmedBy"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Type of Residence</label>
                  <Field
                    as="select"
                    id="residenceTypeOfResidence"
                    name="residenceTypeOfResidence"
                    className="form-control"
                  >
                    <option value="">Select Type of Residence</option>
                    <option value="Independent House">Independent House</option>
                    <option value="Flat">Flat</option>
                    <option value="Chawl">Chawl</option>
                    <option value="Bungalow">Bungalow</option>
                    <option value="Row House">Row House</option>
                    <option value="Temporary Shed">Temporary Shed</option>
                  </Field>

                  <ErrorMessage
                    name="residenceTypeOfResidence"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Area Locality</label>
                  <Field
                    type="text"
                    name="residenceAreaLocality"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceAreaLocality"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Total Members in Family</label>
                  <Field
                    type="number"
                    name="residenceTotalMemberInFamily"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceTotalMemberInFamily"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Details of Family Members</label>
                  <Field
                    type="text"
                    name="residenceDetailsOfFamilyMember"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceDetailsOfFamilyMember"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Earning Members in Family</label>
                  <Field
                    type="text"
                    name="residenceEarningMemberInFamily"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceEarningMemberInFamily"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Details of Earning Members</label>
                  <Field
                    type="text"
                    name="res idenceDetailsOfEarningMember"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceDetailsOfEarningMember"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>First Neighbour Name</label>
                  <Field
                    type="text"
                    name="residenceFirstNeighbourName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceFirstNeighbourName"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>First Neighbour Mobile No</label>
                  <Field
                    type="number"
                    name="residenceFirstNeighbourMobno"
                    className="form-control"
                    min="0"
                    max="9999999999"
                    onInput={(e) => {
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                    }}
                  />
                  <ErrorMessage
                    name="residenceFirstNeighbourMobno"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>First Neighbour Address</label>
                  <Field
                    type="text"
                    name="residenceFirstNeighbourAddress"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceFirstNeighbourAddress"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>How Long Known</label>
                  <Field
                    type="text"
                    name="residenceFirstNeighbourHowlongknow"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceFirstNeighbourHowlongknow"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Behaviour</label>
                  <Field
                    type="text"
                    name="residenceFirstNeighbourBehaviour"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceFirstNeighbourBehaviour"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Negative Behaviour</label>
                  <Field
                    type="text"
                    name="residenceFirstNeighbourNegativeBehaviour"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceFirstNeighbourNegativeBehaviour"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Any Illegal Activity</label>
                  <Field
                    type="text"
                    name="residenceFirstNeighbourAnyIllegalActivity"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceFirstNeighbourAnyIllegalActivity"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Second Neighbour Name</label>
                  <Field
                    type="text"
                    name="residenceSecondNeighbourName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceSecondNeighbourName"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Second Neighbour Mobile No</label>
                  <Field
                    type="text"
                    name="residenceSecondNeighbourMobno"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceSecondNeighbourMobno"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Second Neighbour Address</label>
                  <Field
                    type="text"
                    name="residenceSecondNeighbourAddress"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceSecondNeighbourAddress"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>How Long Known</label>
                  <Field
                    type="text"
                    name="residenceSecondNeighbourHowlongknow"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceSecondNeighbourHowlongknow"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Behaviour</label>
                  <Field
                    type="text"
                    name="residenceSecondNeighbourBehaviour"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceSecondNeighbourBehaviour"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Negative Behaviour</label>
                  <Field
                    type="text"
                    name="residenceSecondNeighbourNegativeBehaviour"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceSecondNeighbourNegativeBehaviour"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Any Illegal Activity</label>
                  <Field
                    type="text"
                    name="residenceSecondNeighbourAnyIllegalActivity"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceSecondNeighbourAnyIllegalActivity"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Additional Check Name</label>
                  <Field
                    type="text"
                    name="residenceAditionalCheckName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceAditionalCheckName"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Additional Check Mobile No</label>
                  <Field
                    type="number"
                    name="residenceAditionalCheckMobno"
                    className="form-control"
                    min="0"
                    max="9999999999"
                    onInput={(e) => {
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                    }}
                  />
                  <ErrorMessage
                    name="residenceAditionalCheckMobno"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Additional Check Address</label>
                  <Field
                    type="text"
                    name="residenceAditionalCheckAddress"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceAditionalCheckAddress"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Feedback: How Long Known</label>
                  <Field
                    type="text"
                    name="residenceAditionalCheckFeedbackHowlongknow"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceAditionalCheckFeedbackHowlongknow"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Feedback: Behaviour</label>
                  <Field
                    type="text"
                    name="residenceAditionalCheckFeedbackBehaviour"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceAditionalCheck FeedbackBehaviour"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Negative Behaviour</label>
                  <Field
                    type="text"
                    name="residenceAditionalCheckNegativeBehaviour"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceAditionalCheckNegativeBehaviour"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Any Illegal Activity</label>
                  <Field
                    type="text"
                    name="residenceAditionalCheckAnyIllegalActivity"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceAditionalCheckAnyIllegalActivity"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Details Remark</label>
                  <Field
                    type="text"
                    name="residenceDetailsRemark"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceDetailsRemark"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Final Status</label>
                  <Field
                    as="select"
                    id="residenceFinalStatus"
                    name="residenceFinalStatus"
                    className="form-control"
                  >
                    <option value="">Select Final Status</option>
                    <option value="Positive">Positive</option>
                    <option value="Negative">Negative</option>
                    <option value="Refer to Credit">Refer to Credit</option>
                  </Field>

                  <ErrorMessage
                    name="residenceFinalStatus"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Verifier Name</label>
                  <Field
                    type="text"
                    name="residenceVerifierName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceVerifierName"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Manager Name</label>
                  <Field
                    type="text"
                    name="residenceManagerName"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceManagerName"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Verification Status</label>
                  <Field
                    type="number"
                    name="residenceVerificationStatus"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceVerificationStatus"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Landmark</label>
                  <Field
                    type="text"
                    name="residenceLandmark"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceLandmark"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="form-group">
                  <label>Any Match Found in Negative Database</label>
                  <Field
                    type="text"
                    name="residenceAnyMatchFoundInNegativeDatabase"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="residenceAnyMatchFoundInNegativeDatabase"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary"
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ApprovedUpdateResidencd;
