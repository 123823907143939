import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { CardContent, Dialog, DialogActions, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { APIURL } from "../../../Config";
import axios from "axios";
import { Form } from "react-bootstrap";
import { Token } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { Modal, Button } from "react-bootstrap";
import ApprovedDataUpdate from "./ApprovedDataUpdate";
const Approved = () => {
  const token = localStorage.getItem("token");
  const ids = localStorage.getItem("id");
  const tableRef = React.useRef(null);
  const [isLoader, setIsLoader] = useState(false);
  const role = localStorage.getItem("role"); //Engineer
  const [selectDate, setSelectDate] = useState(false);
  const [dateValue1, setDateValue1] = useState(
    moment(new Date()).subtract(20, "days").format("YYYY-MM-DD")
  );
  const [dateValue2, setDateValue2] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);
  const [verificationData, setVerificationData] = useState(null);
  const [data, setData] = useState([]);
  const [iD, setID] = useState(null);
  const [selectValue, setSelectValue] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [clientDetails, setClientDetails] = useState([]);
  const [engineerDetail, setEngineerDetail] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    applicantName: "",
    appClientId: "",
    clientName: "",
    caseId: "",
    mobileNo: "",
    address: "",
    maritalStatus: "",
    religion: "",
    category: "",
    ctlEmi: "",
    education: "",
    occupation: "",
    motherName: "",
    comothername: "",
    smartPhone: "",
    emailId: "",
    relation: "",
    remarks: "",
    householdRemarks: "",
    // Residence Verification fields
    locatingRsdncAddrs: "",
    locality: "",
    accommodationType: "",
    sizeOfResidence: "",
    assetsSeen: [],
    houseType: "",
    staysAtAadhaarAddress: "",
    met: "",
    addressVerified: "",
    metStatus: "",
    otherPerson: "",
    businessAddress: "",
    alternateMobileNo: "",
    yearsAtCurrentResidence: "",
    ownershipVerified: "",
    endUseOfLoan: "",
    neighbourStatus: "",
    clientStatus: "",
    neighbourFeedback1: "",
    clientFeedback1: "",
    borrowerPhoneNumberVerified: "",
    latitude: "",
    longitude: "",

    //  Business Verification
    businessAddress: "",
    udyamRegNo: "",
    businessLocationType: "",
    businessVisibility: "",
    levelOfActivity: "",
    stocks: "",
    kycVerified: "",
    peakBusinessHours: "",
    marketFeedback: "",
    businessVerified: "",
    yearsInBusiness: "",
    yearsAtCurrentAddress: "",
    businessPlaceStatus: "",
    anyPartnerInvolved: "",
    negativeProfile: "",
    initialIncome: 0,
    totalIncome: 0,
    noOfPeopleEmployed: "",
    noOfWomenEmployed: "",
    firstGenerationEntreprenure: "",
    newToCredit: "",
    income: 0,
    referenceName: "",
    referenceRelation: "",
    referenceMobNo: "",
    referenceFeedback: "",
    secondReferenceName: "",
    secondReferenceRelation: "",
    secondReferenceMobNo: "",
    secondReferenceFeedback: "",
    coBorrowerRelativeName: "",
    coBorrowerRelativeMobNo: "",
    coBorrowerRelativeAddress: "",
    coborrowerPhoneNumberVerified: "",
    natureOfBusiness: "",
    scaleOfBusiness: "", // Add this line
    businessMargin: "",
    selectedBusinessName: "",
    selectedFamilyMemberApproval: "",
    residenceStatus: "",
    selectedCustomerWilling: "",
    selectedFamilyMember: "",
    familyMembers: "",
    totalHouseholdIncome: "",
    totalHouseholdDisposableIncome: "",
    timesMonthlyInstallment: "",
    entryDate: "",

    milkingCows: "",
    litresPerDay: "",
    sellingPricePerLitre: "",
    expensesPerMonth: "",
    incomePerMonth: "",

    milkingBuffalo: "",
    litresPerDayBuffalo: "",
    sellingPricePerLitreBuffalo: "",
    expensesPerMonthBuffalo: "",
    incomePerMonthBuffalo: "",

    numberOfAcres: "",
    averageIncomePerAcre: "",
    incomePerMonthag: "",

    noofdaysworkinginmonth: "",
    incomeperdaymajdoor: "",
    incomepermonthmajdoor: "",

    noofsewingmachines: "",
    incomeperdaymachine: "",
    incomepermonthmachine: "",

    revenuePerMonth: "",
    expenses: "",
    incomePerMonthother: "",

    approvalOfFamilyMember: "",
    setSelectedFinalDecision: "",
    selectedClientWilling: "",
  });

  console.log("formData::: ", formData);
  const [personalInfoOpen, setPersonalInfoOpen] = useState(false);
  const [residenceVerificationOpen, setResidenceVerificationOpen] =
    useState(false);
  const [errors, setErrors] = useState({
    referenceMobNo: "",
    secondReferenceMobNo: "",
    coBorrowerRelativeMobNo: "",
  });
  const [emailError, setEmailError] = React.useState("");
  const [isBusinessDivOpen, setIsBusinessDivOpen] = useState(false);
  const [isAdditionalFormOpen, setIsAdditionalFormOpen] = useState(false);
  const [isHouseholdIncomeFormOpen, setIsHouseholdIncomeFormOpen] =
    useState(false);

  const [isExpensesFormOpen, setExpensesFormOpen] = useState(false);
  const [isEMIFormOpen, setEMIFormOpen] = useState(false);
  const [isCoBorrowerEMIFormOpen, setCoBorrowerEMIFormOpen] = useState(false);
  const [isDisposableIncomeFormOpen, setIsDisposableIncomeFormOpen] =
    useState(false);
  const [isMonthlyIncomeExpensesFormOpen, setIsMonthlyIncomeExpensesFormOpen] =
    useState(false);
  const [isImpactParametersFormOpen, setIsImpactParametersFormOpen] =
    useState(false);
  const [isReferenceFormOpen, setIsReferenceFormOpen] = useState(false);
  const [isFamilyApprovalDivOpen, setIsFamilyApprovalDivOpen] = useState(false);
  const [isRemarkDivOpen, setIsRemarkDivOpen] = useState(false);
  const [selectedRemark, setSelectedRemark] = useState("Select");
  const [remarkText, setRemarkText] = useState("");
  const [selectedFinalDecision, setSelectedFinalDecision] = useState("Select");
  const [selectedRejectReason, setSelectedRejectReason] = useState("Select");
  const [natureOfBusinessOptions, setNatureOfBusinessOptions] = useState([]);
  const [businessForms, setBusinessForms] = useState([]);

  const [emiData, setEmiData] = useState([
    {
      id: 1,
      lenderName: "",
      emi: "",
      entryDate: "",
      loanAmount: "",
      currentBalance: "",
    },
    // Add initial objects as needed
  ]);

  const [newappClientId, setNewAppClientId] = useState(null);

  const [fields, setFields] = useState([]);
  const [showFields, setShowFields] = useState(false);

  const handleEmiChange = (e, id, field) => {
    const { value } = e.target;
    setEmiData((prevEmiData) =>
      prevEmiData.map((emi) =>
        emi.id === id ? { ...emi, [field]: value } : emi
      )
    );
  };

  const handleFieldChange = (e, index, field) => {
    const newFields = [...fields];
    newFields[index][field] = e.target.value;
    setFields(newFields);
  };

  const addMoreLender = () => {
    setFields([...fields, { lenderName: "", emi: "" }]);
    setShowFields(true);
  };

  const removeLender = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
    if (newFields.length === 0) {
      setShowFields(false);
    }
  };

  const calculateTotalEmi = () => {
    const tableEmiTotal = emiData.reduce(
      (sum, emi) => sum + Number(emi.emi || emi.lenderEmi || 0),
      0
    );
    const additionalEmiTotal = fields.reduce(
      (sum, field) => sum + Number(field.emi || 0),
      0
    );
    return tableEmiTotal + additionalEmiTotal;
  };

  const totalEmi = calculateTotalEmi();

  // State for Co-Borrower
  const [coborrowerEmiData, setCoborrowerEmiData] = useState([
    {
      id: 1,
      lenderName: "",
      emi: 0,
      entryDate: "",
      loanAmount: "",
      currentBalance: "",
    },
    // Add initial objects as needed
  ]);
  const [coborrowerFields, setCoborrowerFields] = useState([]);
  const [showCoborrowerFields, setShowCoborrowerFields] = useState(false);

  const handleCoborrowerEmiChange = (e, id, field) => {
    const { value } = e.target;
    setCoborrowerEmiData((prevData) => {
      const updatedData = prevData.map((emi) =>
        emi.id === id ? { ...emi, [field]: value } : emi
      );
      console.log("Updated Data:", updatedData); // Confirm data update
      return updatedData;
    });
  };
  const handleCoborrowerFieldChange = (e, index, field) => {
    const newFields = [...coborrowerFields];
    newFields[index][field] = e.target.value;
    setCoborrowerFields(newFields);
  };

  const addMoreCoborrowerLender = () => {
    setCoborrowerFields([...coborrowerFields, { lenderName: "", emi: "" }]);
    setShowCoborrowerFields(true);
  };

  const removeCoborrowerLender = (index) => {
    const newFields = coborrowerFields.filter((_, i) => i !== index);
    setCoborrowerFields(newFields);
    if (newFields.length === 0) {
      setShowCoborrowerFields(false);
    }
  };

  const calculateTotalCoborrowerEmi = () => {
    const tableEmiTotal = coborrowerEmiData.reduce(
      (sum, emi) => sum + Number(emi.emi || emi.lenderEmi || 0), // Ensure emi is converted to a number
      0
    );
    const additionalEmiTotal = coborrowerFields.reduce(
      (sum, field) => sum + Number(field.emi || 0), // Ensure field.emi is converted to a number
      0
    );
    return tableEmiTotal + additionalEmiTotal;
  };

  const totalCoborrowerEmi = calculateTotalCoborrowerEmi();

  const [disposableIncome, setDisposableIncome] = useState(0);

  useEffect(() => {
    // Determine the total income based on whether totalIncome is present
    const totalIncome =
      formData.totalIncome !== undefined
        ? formData.totalIncome
        : formData.initialIncome || 0;

    // Calculate the disposable income
    const calculatedDisposableIncome =
      totalIncome -
      (totalEmi + totalCoborrowerEmi) -
      formData.familyMembers * 2000;

    setDisposableIncome(calculatedDisposableIncome);
  }, [
    formData.totalIncome,
    formData.initialIncome,
    totalEmi,
    totalCoborrowerEmi,
    formData.familyMembers,
  ]);

  useEffect(() => {
    fetchClientDetails();
    fetchAssignTo();
  }, []);
  const [natureOfBusinessList, setnatureOfBusinessList] = useState([]);

  const fetchNatureOfBusinessOptions = async () => {
    try {
      console.log("Fetching nature of business options...");
      const response = await axios.get(`${APIURL}/api/pv/purpose`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Response data:", response.data);

      // Extract and clean the loanPurposeList with purposeid
      const loanPurposeList = response.data.map((item) => ({
        purposeid: item.purposeid,
        loanpurpose: item.loanpurpose.trim(),
      }));
      console.log("Loan Purpose List:", loanPurposeList);

      // Filter out businesses that exist in the natureOfBusinessList
      const availableBusinessOptions = loanPurposeList.filter(
        (option) => !natureOfBusinessList.includes(option.loanpurpose)
      );
      console.log("Filtered Business Options:", availableBusinessOptions);

      // Set the filtered list to state
      setNatureOfBusinessOptions(availableBusinessOptions);
    } catch (error) {
      console.error(
        "Error fetching nature of business options:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    // Fetch nature of business options whenever natureOfBusinessList is updated
    fetchNatureOfBusinessOptions();
  }, [natureOfBusinessList]);

  const handleLinkClick = async (e, caseReferenceNumber) => {
    console.log(e); // Debug: Check if event object is logged
    if (!e || !e.preventDefault) {
      console.error("Event object or preventDefault is undefined");
      return;
    }
    e.preventDefault();

    try {
      // Fetch data using Axios
      const response = await axios.get(
        `${APIURL}/api/pv/cpvGenerateExcel/${caseReferenceNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data[0];
      const natureOfBusinessString = data.natureOfBusiness || "";

      // Clean the string and split by commas
      const cleanedString = natureOfBusinessString.replace(/\s*,\s*/g, ","); // Remove extra spaces around commas
      const natureOfBusinessArray = cleanedString
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item); // Remove empty items and trim whitespace

      console.log("Nature of Business List:", natureOfBusinessArray);
      setnatureOfBusinessList(natureOfBusinessArray);
      setNewAppClientId(data.appClientId);
      setFormData({
        applicantName: data.applicantName || "",
        appClientId: data.appClientId || "",
        clientName: data.clientName || "",
        caseId: data.caseRefNo || "",
        mobileNo: data.mobile || "",
        address: data.pvAddress || "",
        maritalStatus: (data.maritalStatus || "").trim(),
        religion: (data.religion || "").trim(),
        category: (data.category || "").trim(),
        education: (data.education || "").trim(),
        occupation: (data.occupation || "").trim(),
        motherName: data.motherName || "",
        comothername: data.comothername || "",
        smartPhone: data.smartPhoneStatus || "",
        emailId: data.emailId || "",
        relation: (data.customerRelation || "").trim(),
        entryDate: (data.entryDate || "").trim(),
        // Residence Verification fields
        locatingRsdncAddrs: data.locatingRsdncAddrs || "",
        locality: (data.locality || "").trim(),
        accommodationType: (data.accomodationType || "").trim(),
        sizeOfResidence: (data.sizeOfRsdnc || "").trim(),
        assetsSeen: data.assetsSeen || [],
        houseType: (data.houseType || "").trim(),
        staysAtAadhaarAddress: (data.borrCoborrStayAadharAddress || "").trim(),
        metStatus: (data.customerMet || "").trim(),
        kycVerified: (data.kycVerified || "").trim(),
        addressVerified: (data.addressVerified || "").trim(),
        //otherPerson: ("").trim(),
        alternateMobileNo: (data.custAltNo || "").trim(),
        yearsAtCurrentResidence: data.noOfYrsAtCrntResid || "",
        ownershipVerified: (data.standardOfLiving || "").trim(),
        endUseOfLoan: (data.endUseOfLoan || "").trim(),
        neighbourStatus: (data.neighbourStatus || "").trim(),
        clientStatus: (data.clientStatus || "").trim(),
        neighbourFeedback1: (data.neighbourType || "").trim(),
        clientFeedback1: (data.clientType || "").trim(),
        borrowerPhoneNumberVerified: (data.phoneNoVerified || "").trim(),
        coborrowerPhoneNumberVerified: (
          data.coBorrowerPhoneNumberVerifed || ""
        ).trim(),
        residenceStatus: (data.residenceStatus || "").trim(),
        latitude: data.latitude,
        longitude: data.longitude,
        // Business Verification
        custWillPayOnline: (data.custWillPayOnline || "").trim(),
        familyMembers: data.noOfFamilyMembers || "",
        businessAddress: data.businessAddress || "",
        udyamRegNo: (data.udhaymregno || "").trim(),
        businessLocationType: (data.businessLocType || "").trim(),
        businessVisibility: (data.busVisibility || "").trim(),
        levelOfActivity: (data.levelOfAct || "").trim(),
        stocks: (data.stocks || "").trim(),
        peakBusinessHours: (data.peakBusHours || "").trim(),
        marketFeedback: (data.markFeedback || "").trim(),
        businessVerified: (data.businessEmploymentVerified || "").trim(),
        yearsInBusiness: "".trim(),
        yearsAtCurrentAddress: (data.noOfYrsAtCurrentAddress || "").trim(),
        businessPlaceStatus: (data.businessPlaceStatus || "").trim(),
        anyPartnerInvolved: (data.anyPartnerInvolved || "").trim(),
        negativeProfile: (data.negativeProfile || "").trim(),
        selectedCustomerWilling: data.custWillingTakeLaon || "",
        initialIncome: (data.income || "").trim(),
        natureOfBusiness: (data.natureOfBusiness || "").trim(),
        selectedFamilyMemberApproval: (
          data.familyMemberApprovalStatus || ""
        ).trim(),
        noOfPeopleEmployed: (data.noOfPeopleEmployed || "").trim(),
        noOfWomenEmployed: (data.noOfWomenEmployed || "").trim(),
        firstGenerationEntreprenure: (
          data.firstGenerationEntreprenure || ""
        ).trim(),
        newToCredit: (data.newToCredit || "").trim(),
        selectedFamilyMember: data.approvalOfFamilyMember || null,
        referenceName: (data.firstRefName || "").trim(),
        referenceMobNo: (data.firstRefMobile || "").trim(),
        referenceFeedback: (data.referenceFeedbackFirst || "").trim(),
        secondReferenceName: (data.secRefName || "").trim(),
        secondReferenceRelation: (data.reln_refrences_2 || "").trim(),
        secondReferenceMobNo: (data.secRefMobile || "").trim(),
        secondReferenceFeedback: (data.referenceFeedbackSecond || "").trim(),
        coBorrowerRelativeName: (
          data.coborrowerFatherOrBrotherName || ""
        ).trim(),
        coBorrowerRelativeMobNo: (
          data.coborrowerFatherOrBrotherNo || ""
        ).trim(),
        coBorrowerRelativeAddress: (
          data.coborrowerFatherOrBrotherAddress || ""
        ).trim(),
        scaleOfBusiness: (data.scaleOfBusiness || "").trim(),
        businessMargin: (data.businessMargin || "").trim(),
        selectedBusinessName: (data.existingBusiness || "").trim(),
        totalHouseholdIncome: (data.totalHouseholdMonthlyIncomes || "").trim(),
        totalHouseholdDisposableIncome: (
          data.householdDisposalIncomes || ""
        ).trim(),
        timesMonthlyInstallment: 0,
        referenceRelation: (data.firstRefRelation || "").trim(),
        selectedFinalDecision: (data.finalDecision || "").trim(),
        remarks: (data.remarks || "").trim(),
        householdRemarks: (data.householdRemarks || "").trim(),
        selectedClientWilling: (data.custWillPayOnline || "").trim(),
        ctlEmi: (data.ctlEmi || "").trim(),
        // Cow Rearing
        milkingCows: (data.numberOfMilkingCows || "").trim(),
        litresPerDay: (data.averageLitresPerDay || "").trim(),
        sellingPricePerLitre: (data.averageSellingPricePerLitre || "").trim(),
        expensesPerMonth: (data.avergeExpensePerMonth || "").trim(),
        incomePerMonth: (data.incomePerMonth || "").trim(),
        // Buffalo Rearing
        milkingBuffalo: (data.numberOfMilkingBuffalos || "").trim(),
        litresPerDayBuffalo: (data.averageLitresPerDay || "").trim(),
        sellingPricePerLitreBuffalo: (
          data.averageSellingPricePerLitre || ""
        ).trim(),
        expensesPerMonthBuffalo: (data.businessExpenses || "").trim(),
        incomePerMonthBuffalo: (data.businessIncome || "").trim(),
        // Agriculture
        numberOfAcres: (data.numberOfAcres || "").trim(),
        averageIncomePerAcre: (data.averageIncomePerAcre || "").trim(),
        incomePerMonthag: (data.businessIncome || "").trim(),
        // Majdoor
        noofdaysworkinginmonth: (data.numberOfDaysWorking || "").trim(),
        incomeperdaymajdoor: (data.numberOfDaysWorking || "").trim(),
        incomepermonthmajdoor: (data.businessIncome || "").trim(),
        // Sewing Machine
        noofsewingmachines: (data.numberOfSewingMachines || "").trim(),
        incomeperdaymachine: (data.averageIncomePerDay || "").trim(),
        incomepermonthmachine: (data.averageIncomePerMachine || "").trim(),
        revenuePerMonth: (data.numberOfSewingMachines || "").trim(),
        expenses: (data.businessExpenses || "").trim(),
        incomePerMonthother: (data.numberOfSewingMachines || "").trim(),
      });

      console.log("Data fetched successfully, opening dialog...");
      setDialogOpen(true); // Open the dialog
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseDialog = () => {
    console.log("Closing dialog..."); // Debug log
    setDialogOpen(false); // Close the dialog
  };
  const togglePersonalInfo = () => {
    setPersonalInfoOpen(!personalInfoOpen);
  };

  console.log("Dialog open state:", dialogOpen); // Debug log

  useEffect(() => {
    const todayDate = new Date();
    setDateValue2(moment(todayDate).format("YYYY-MM-DD"));
    const sevenDaysBeforeDate = new Date();
    sevenDaysBeforeDate.setDate(todayDate.getDate() - 20);
    setDateValue1(moment(sevenDaysBeforeDate).format("YYYY-MM-DD"));
    handleSubmit();
  }, []);

  const fetchClientDetails = async () => {
    await axios
      .get(`${APIURL}/api/ClientDetail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("response::: ", response);
        setClientDetails(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchAssignTo = async () => {
    await axios
      .get(`${APIURL}/api/EngineerDetail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("response::: ", response);
        setEngineerDetail(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadExcel = () => {
    let url =
      role === "Engineer"
        ? `${APIURL}/api/pv/cpvGenerateExcel/1/${ids}/${dateValue1}/${dateValue2}`
        : `${APIURL}/api/pv/cpvGenerateExcel/1/${ids}/${dateValue1}/${dateValue2}`;

    if (selectValue !== "" && assignTo !== "") {
      url = `${APIURL}/api/pv/cpvGenerateExcel/1/${ids}/${dateValue1}/${dateValue2}/${selectValue}/${assignTo}`;
    } else if (selectValue !== "") {
      url = `${APIURL}/api/pv/cpvGenerateExcel/1/${ids}/${dateValue1}/${dateValue2}/${selectValue}`;
    } else if (assignTo !== "") {
      url = `${APIURL}/api/pv/vGenerateExcel/1/${ids}/${dateValue1}/${dateValue2}/${assignTo}`;
    }
    axios
      .request(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // important
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Approve.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async () => {
    console.log(":::::::", dateValue1, dateValue2);
    let url =
      role === "Engineer"
        ? `${APIURL}/api/clientStatusEngineerApprove/${ids}/1/${dateValue1}/${dateValue2}`
        : `${APIURL}/api/clientStatus/${ids}/1/${dateValue1}/${dateValue2}`;

    if (selectValue !== "" && assignTo !== "") {
      url = `${APIURL}/api/clientStatusEngineerAprAndRejectByClientsAndAssignToApprove/${ids}/1/${assignTo}/${selectValue}/${dateValue1}/${dateValue2}`;
    } else if (selectValue !== "") {
      url = `${APIURL}/api/clientStatusEngineerAprAndRejectByClientsApprove/${ids}/1/${selectValue}/${dateValue1}/${dateValue2}`;
    } else if (assignTo !== "") {
      url = `${APIURL}/api/clientStatusEngineerAprAndRejectByAssignToApprove/${ids}/1/${assignTo}/${dateValue1}/${dateValue2}`;
    }

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setData(response?.data);

      if (response?.data && response.data.length > 0) {
        const firstClientId = response.data[0]?.id;
        console.log("First Client ID:", firstClientId); // Log ID
        setID(firstClientId);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (newappClientId && iD) {
      console.log("Fetching lender and co-borrower lender details for iD:", iD);
      fetchLenderDetails(newappClientId);
      fetchCoBorrowerLenderDetails(newappClientId);
    }
  }, [newappClientId, iD]); // Trigger fetching when newappClientId or iD changes

  const fetchLenderDetails = async (appClientId) => {
    console.log("Fetching lender details for appClientId:", appClientId);
    try {
      const response = await axios.get(
        `${APIURL}/api/pv/digitalPvLenderDetailsData/${appClientId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const lenderDetails = response.data;
      console.log("Fetched lender details:", lenderDetails);

      // Filter lenderDetails to include only those with lenderType 1
      const filteredDetails = lenderDetails.filter(
        (detail) => detail.type === 1
      );
      setEmiData(filteredDetails);
    } catch (error) {
      console.error(
        "Error fetching lender details:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const fetchCoBorrowerLenderDetails = async (appClientId) => {
    console.log(
      "Fetching co-borrower lender details for appClientId:",
      appClientId
    );
    try {
      const response = await axios.get(
        `${APIURL}/api/pv/digitalPvLenderDetailsData/${appClientId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const lenderDetails = response.data;
      console.log("Fetched lender details:", lenderDetails);

      // Filter lenderDetails to include only those with lenderType 1
      const filteredDetails = lenderDetails.filter(
        (detail) => detail.type === 2
      );
      setCoborrowerEmiData(filteredDetails);
    } catch (error) {
      console.error(
        "Error fetching co-borrower lender details:",
        error.response ? error.response.data : error.message
      );
    }
  };

  //http://13.233.134.156:8090/v2/api/cpv/cpvVerificationPdf/922417
  const openLink = (crn) => {
    const url = `${APIURL}/v2/api/cpv/cpvVerificationPdf/${crn}`;
    axios
      .request(url, {
        method: "GET",
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response:::", response);
        if (response?.data) {
          const pdfData = response?.data;
          downloadPdf(pdfData);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const downloadPdf = (pdfData) => {
    const blob = new Blob([pdfData], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "pdsheet.pdf";
    link.click();
  };
  const downloadXls = (xlsData) => {
    // Ensure xlsData is a Uint8Array or ArrayBuffer
    const blob = new Blob([xlsData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Approved.xlsx"; // Note: changed to .xlsx
    link.click();
  };
  // const downloadXls = (xlsData) => {
  //   const blob = new Blob([xlsData], { type: "application/xlsx" });
  //   const link = document.createElement("a");
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = "Approved.xls";
  //   link.click();
  // };

  const validateMobileNumber = (number) => {
    const regex = /^[0-9]{10}$/; // 10 digit mobile number
    return regex.test(number);
  };

  const updateMotherNames = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const updateMaritalStatus = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const updateCustomerAddress = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    let newValue = value;

    // Specific validations or transformations
    if (name === "alternateMobileNo") {
      newValue = value.replace(/[^0-9]/g, "").slice(0, 10);
    }

    if (name === "emailId") {
      const isValidEmail = validateEmail(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: isValidEmail ? "" : "Invalid email address",
      }));
    }

    if (
      name === "mobileNo" ||
      name === "referenceMobNo" ||
      name === "secondReferenceMobNo"
    ) {
      const isValid = validateMobileNumber(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: isValid ? "" : "",
      }));
    }

    // Update the specific form's formData within businessFormList
    setBusinessFormList((prevFormList) =>
      prevFormList.map((form, idx) => {
        if (idx === index) {
          return {
            ...form,
            formData: {
              ...form.formData,
              [name]: newValue,
            },
          };
        }
        return form;
      })
    );

    // Optionally update formData state only if it's needed separately
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));

    calculateBusinessIncome(index);
  };

  const handleRadioButtonChange = (e) => {
    console.log(e); // Check if event object is being logged
    if (!e || !e.target) {
      console.error("Event or event.target is undefined");
      return;
    }
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const updateRelation = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => {
    console.log(e);
    if (!e || !e.target) {
      console.error("Event or event.target is undefined");
      return;
    }
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addBusinessFormother = () => {
    // Log the state before adding a new form
    console.log("Before Adding New Form:", businessFormList);

    setBusinessFormList((prevFormList) => {
      const newForm = {
        id: Date.now(),
        formData: {
          natureOfBusiness: "",
          businessRevenue: "",
          businessExpenses: "",
        },
      };
      const updatedFormList = [...prevFormList, newForm];

      // Log the state after adding the new form
      console.log("After Adding New Form:", updatedFormList);

      return updatedFormList;
    });
  };

  const removeBusinessFormother = (id) => {
    const updatedForms = businessFormList.filter((form) => form.id !== id);
    setBusinessFormList(updatedForms);
  };

  const toggleResidenceVerification = () => {
    setResidenceVerificationOpen(!residenceVerificationOpen);
  };

  const validateMobileNumberAlternate = (value) => {
    const mobilePattern = /^[0-9]{10}$/; // Regex for 10 digit mobile number
    return mobilePattern.test(value);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleToggleBusinessDiv = () => {
    setIsBusinessDivOpen((prevState) => !prevState);

    if (!isBusinessDivOpen) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        selectedBusinessName: "",
        isAdditionalFormOpen: false,
      }));
    }
  };

  const toggleHouseholdIncomeForm = () => {
    setIsHouseholdIncomeFormOpen((prevState) => !prevState);
  };

  const toggleExpensesForm = () => {
    setExpensesFormOpen(!isExpensesFormOpen);
  };

  const toggleEMIForm = () => {
    setEMIFormOpen(!isEMIFormOpen);
  };

  const toggleCoBorrowerEMIForm = () => {
    setCoBorrowerEMIFormOpen(!isCoBorrowerEMIFormOpen);
  };

  const toggleDisposableIncomeForm = () => {
    setIsDisposableIncomeFormOpen(!isDisposableIncomeFormOpen);
  };

  const toggleMonthlyIncomeExpensesForm = () => {
    setIsMonthlyIncomeExpensesFormOpen(!isMonthlyIncomeExpensesFormOpen);
  };
  const toggleImpactParametersForm = () => {
    setIsImpactParametersFormOpen(!isImpactParametersFormOpen);
  };

  const handleToggleFamilyApprovalDiv = () => {
    setIsFamilyApprovalDivOpen(!isFamilyApprovalDivOpen);
  };

  const handleToggleRemarkDiv = () => {
    setIsRemarkDivOpen(!isRemarkDivOpen);
  };

  const handleFinalDecisionChange = (e) => {
    setSelectedFinalDecision(e.target.value);
    if (e.target.value !== "Rejected") {
      setSelectedRejectReason("Select"); // Reset reject reason if not rejected
    }
  };

  const handleRejectReasonChange = (e) => {
    setSelectedRejectReason(e.target.value);
  };

  // Handler to update form data
  const handleBusinessInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedForms = [...businessForms];
    updatedForms[index] = { ...updatedForms[index], [name]: value };
    setBusinessForms(updatedForms);

    // Recalculate total income only if necessary
    if (
      updatedForms.every(
        (form) =>
          form.natureOfBusiness && form.scaleOfBusiness && form.businessMargin
      )
    ) {
      fetchIncome(updatedForms);
    }
  };

  // Function to fetch and recalculate total income
  const fetchIncome = async (forms) => {
    let totalIncome = Number(formData.initialIncome) || 0;
    console.log(`Initial income: ${totalIncome}`);

    // Clear previous incomes and fetch new ones
    for (const form of forms) {
      const natureId = form.natureOfBusiness;
      const scale = form.scaleOfBusiness.toLowerCase();
      const margin = form.businessMargin.toLowerCase();

      try {
        console.log(`Fetching income for ${natureId}/${scale}/${margin}`);
        const response = await fetch(
          `http://13.233.134.156:8090/api/pv/income/${natureId}/${scale}/${margin}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const income = Number(data.income);

        console.log(`Income from API: ${income}`);
        if (!isNaN(income)) {
          totalIncome += income;
          console.log(`Updated totalIncome: ${totalIncome}`);
        } else {
          console.error("Invalid income value from API:", data.income);
        }
      } catch (error) {
        console.error("Error fetching income:", error);
      }
    }

    setFormData((prevData) => {
      console.log("Previous Form Data:", prevData); // Debugging line
      const updatedData = { ...prevData, totalIncome };
      console.log("Updated Form Data:", updatedData); // Debugging line
      return updatedData;
    });
  };

  const updateResidenceAddress = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const addBusinessForm = () => {
    setBusinessForms((prevForms) => {
      console.log("Previous Forms:", prevForms); // Debugging line
      const updatedForms = [
        ...prevForms,
        {
          natureOfBusiness: "",
          scaleOfBusiness: "",
          businessMargin: "",
          income: 0,
        },
      ];
      console.log("Updated Forms:", updatedForms); // Debugging line
      setShowForms(true);
      return updatedForms;
    });
  };

  const removeBusinessForm = (index) => {
    const updatedForms = businessForms.filter((_, i) => i !== index);
    setBusinessForms(updatedForms);

    // Recalculate total income after form removal
    fetchIncome(updatedForms);
  };

  const [showForms, setShowForms] = useState(false);
  const [selectedIncomeSources, setSelectedIncomeSources] = useState([]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setSelectedIncomeSources((prev) =>
      checked ? [...prev, value] : prev.filter((source) => source !== value)
    );
  };

  const isCowRearingSelected = selectedIncomeSources.includes("cowRearing");
  const isAgricultureSelected = selectedIncomeSources.includes("agriculture");
  const isBuffaloRearingSelected =
    selectedIncomeSources.includes("buffaloRearing");
  const isSalaryorMajdoorSelected =
    selectedIncomeSources.includes("salaryWork");
  const isTailoringBusinessSelected =
    selectedIncomeSources.includes("tailoringBusiness");

  const [businessTypes, setBusinessTypes] = useState([]);

  const [selectedBusinessType, setSelectedBusinessType] = useState("");
  const [otherBusinessData, setOtherBusinessData] = useState({
    revenuePerMonth: "",
    expenses: "",
    incomePerMonth: "",
  });

  useEffect(() => {
    const fetchBusinessTypes = async () => {
      console.log("token::::::::::::::::::::::::::", token);
      try {
        console.log("Fetching nature of business options...");
        const response = await axios.get(`${APIURL}/api/pv/purpose`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Response data:", response.data);
        setBusinessTypes(response.data);
      } catch (error) {
        console.error(
          "Error fetching nature of business options:",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchBusinessTypes();
  }, []);

  const excludedPurposeIds = [14, 20, 55];

  const filteredBusinessTypes = businessTypes.filter(
    (type) => !excludedPurposeIds.includes(type.purposeid)
  );

  const handleBusinessTypeChange = (e) => {
    setSelectedBusinessType(e.target.value);
  };

  const handleOtherBusinessChange = (e) => {
    const { name, value } = e.target;
    setOtherBusinessData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle potential undefined properties
  const natureOfBusiness = formData.natureOfBusiness || "";
  const scaleOfBusiness = formData.scaleOfBusiness || "";
  const businessMargin = formData.businessMargin || "";

  // Process and format the data correctly
  const natureArray = natureOfBusiness
    .split(",")
    .map((item) => item.trim())
    .filter((item) => item);
  const scaleArray = scaleOfBusiness
    .split(",")
    .map((item) => item.trim())
    .filter((item) => item);
  const marginArray = businessMargin
    .split(",")
    .map((item) => item.trim())
    .filter((item) => item);
  const [isBusinessFormVisible, setIsBusinessFormVisible] = useState(false);
  const toggleBusinessForm = () => {
    setIsBusinessFormVisible((prev) => !prev);
  };

  const [businessFormList, setBusinessFormList] = useState([]);

  const handleBusinessFormChange = (id, event) => {
    const { name, value } = event.target;
    const updatedForms = businessFormList.map((form) =>
      form.id === id ? { ...form, [name]: value } : form
    );
    setBusinessFormList(updatedForms);
  };

  useEffect(() => {
    if (iD) {
      console.log("iD is available:", iD);
      fetchLenderDetails(iD);
    } else {
      console.log("iD is not set or is invalid.");
    }
  }, [iD]);

  const maritalStatusMapping = {
    Unmarried: 0,
    married: 1,
  };

  const occupationMapping = {
    salaried: 1,
    "self Employed": 2,
  };

  const religionMapping = {
    Hindu: 1,
    Sikh: 2,
    Muslim: 3,
    Christian: 4,
    Other: 5,
  };

  const categoryMapping = {
    General: 1,
    OBC: 2,
    SC: 3,
    ST: 4,
  };

  const educationMapping = {
    Highschool: 1,
    Undergraduate: 2,
    Graduate: 3,
    "Post-Graduate": 4,
  };

  const standardOfLivingMapping = {
    YES: 1,
    NO: 0,
  };

  const residenceStatusMapping = {
    NO: 0,
    YES: 1,
  };

  const businessEmploymentVerifiedMapping = {
    NO: 0,
    YES: 1,
  };

  const custWillingTakeLaonMapping = {
    NO: 0,
    YES: 1,
  };

  const custWillPayOnlineMapping = {
    NO: 0,
    YES: 1,
  };

  const finalDecisionMapping = {
    NO: 0,
    YES: 1,
  };

  const accommodationTypeMapping = {
    "Independent House": 1,
    Bunglow: 2,
    Flat: 3,
    Chawl: 4,
    "Any Other": 5,
  };

  const locatingRsdncAddrsMapping = {
    Easy: 1,
    Difficult: 2,
    Untrackable: 3,
  };
  const customerMetMapping = {
    NO: 0,
    YES: 1,
  };

  const sizeOfResidenceMapping = {
    Small: 1,
    Medium: 2,
    Large: 3,
  };

  const assetsSeenMapping = {
    TV: 1,
    Refrigerator: 2,
    "Washing Machine": 3,
    Bike: 4,
  };

  const localityMapping = {
    Residential: 1,
    Comercial: 2,
    "Resi- cum Commercial": 3,
  };

  const locatingBusinessAddressMapping = {
    Easy: 1,
    Difficult: 2,
    Untrackable: 3,
  };

  const businessLocationTypeMapping = {
    "Office Building": 1,
    "Converted Flat": 2,
    Shop: 3,
    Residence: 4,
    Factory: 5,
    Godown: 6,
  };

  const businessVisibilityMapping = {
    "Name Board": 1,
    "Through Business Activity": 2,
    "Verbal Neighbour Confirmation ": 3,
    "Visiting Card": 4,
  };

  const levelOfActivityMapping = {
    "Very Busy": 1,
    Busy: 2,
    Average: 3,
    Poor: 4,
  };

  const stocksMapping = {
    Heavy: 1,
    Average: 2,
    Low: 3,
    "No Stock": 4,
  };

  const peakBusinessHoursMapping = {
    Morning: 1,
    Afternoon: 2,
    Evening: 3,
    "Not Applicable": 4,
  };

  const residenceVerificationThroughMapping = {
    NO: 0,
    YES: 1,
  };

  const markFeedbackMapping = {
    veryGood: 1,
    Good: 2,
    Fair: 3,
    Poor: 4,
  };

  const existingBusinessMapping = {
    Yes: 1,
    No: 2,
  };

  const clientStatusMapping = {
    Negative: 0,
    Positive: 1,
  };

  const neighbourStatusMapping = {
    Negative: 0,
    Positive: 1,
  };

  const address_verifiedMapping = {
    NO: 0,
    YES: 1,
  };
  const kycVerifiedMapping = {
    NO: 0,
    YES: 1,
  };

  const staysAtAadhaarAddressMapping = {
    NO: 2,
    YES: 1,
  };

  const familyMemberApprovalStatusMapping = {
    NO: 0,
    YES: 1,
  };
  const newToCreditMapping = {
    No: 0,
    Yes: 1,
    "N/A": 2,
  };

  const firstGenerationEntreprenureMapping = {
    No: 0,
    Yes: 1,
  };
  const coBorrowerPhoneNumberVerifedMapping = {
    NO: 0,
    YES: 1,
  };

  const phoneNoVerifiedMapping = {
    NO: 0,
    YES: 1,
  };
  const smartPhoneMapping = {
    NO: 0,
    YES: 1,
  };

  //'http://13.233.134.156:8090/api/pv/updateCPVdetails

  const handleFinalSubmit = async (event) => {
    event.preventDefault();

    // Map lender details from fields state
    const lenderDetails = fields.map((field) => ({
      lenderName: field.lenderName || "",
      lenderEmi: field.emi || "0",
    }));

    // Log lender details for debugging
    console.log("Lender Details:", lenderDetails);

    // Format lender details for the API request
    const lenderNames = lenderDetails
      .map((detail) => detail.lenderName)
      .join(", ");
    const lenderEmis = lenderDetails
      .map((detail) => detail.lenderEmi)
      .join(", ");

    // Log formatted lender names and EMIs for debugging
    console.log("Lender Names:", lenderNames);
    console.log("Lender EMIs:", lenderEmis);

    // Map coborrower lender details from coborrowerFields state
    const coborrowerLenderDetails = coborrowerFields.map((field) => ({
      lenderName: field.lenderName || "",
      lenderEmi: field.emi || "0",
    }));

    // Format coborrower lender details for the API request
    const coborrowerLenderNames = coborrowerLenderDetails
      .map((detail) => detail.lenderName)
      .join(", ");
    const coborrowerLenderEmis = coborrowerLenderDetails
      .map((detail) => detail.lenderEmi)
      .join(", ");

    const existingData = {
      natureOfBusiness: formData.natureOfBusiness || "",
      scaleOfBusiness: formData.scaleOfBusiness || "",
      businessMargin: formData.businessMargin || "",
    };

    // New business forms data
    const newBusinessForms = businessForms.map((form) => ({
      natureOfBusiness: form.natureOfBusiness || "",
      scaleOfBusiness: form.scaleOfBusiness || "",
      businessMargin: form.businessMargin || "",
    }));
    const locatingRsdncAddrsValue =
      locatingRsdncAddrsMapping[formData.locatingRsdncAddrs] || null;
    const localityValue = localityMapping[formData.locality] || null;
    const accomodationTypeValue =
      accommodationTypeMapping[formData.accommodationType] || null;
    const sizeOfRsdncValue =
      sizeOfResidenceMapping[formData.sizeOfResidence] || null;
    const assetsSeenValue = assetsSeenMapping[formData.assetsSeen] || null;
    const locatingBsnsAddrsValue =
      locatingBusinessAddressMapping[formData.locatingBsnsAddrs] || null;
    const businessLocTypeValue =
      businessLocationTypeMapping[formData.businessLocationType] || null;
    const busVisibilityValue =
      businessVisibilityMapping[formData.businessVisibility] || null;
    const levelOfActValue =
      levelOfActivityMapping[formData.levelOfActivity] || null;
    const religionValue = religionMapping[formData.religion] || null;
    const categoryValue = categoryMapping[formData.category] || null;
    const educationValue = educationMapping[formData.education] || null;
    const stocksValue = stocksMapping[formData.stocks] || null;
    const peakBusHoursValue =
      peakBusinessHoursMapping[formData.peakBusinessHours] || null;
    const resiVefThroughValue =
      residenceVerificationThroughMapping[formData.businessVerified] || null;
    const markFeedbackValue =
      markFeedbackMapping[formData.marketFeedback] || null;
    const customerMetValue = customerMetMapping[formData.metStatus] || null;
    const kycVerifiedValue = kycVerifiedMapping[formData.kycVerified] || null;
    const addressVerifiedValue =
      address_verifiedMapping[formData.addressVerified] || null;
    const phoneNoVerifiedValue =
      phoneNoVerifiedMapping[formData.borrowerPhoneNumberVerified] || null;
    const residenceStatusValue =
      residenceStatusMapping[formData.residenceStatus] || null;
    const standardOfLivingValue =
      standardOfLivingMapping[formData.ownershipVerified] || null;
    const neighbourCheckValue =
      neighbourStatusMapping[formData.neighbourStatus] || null;
    const businessEmploymentVerifiedValue =
      businessEmploymentVerifiedMapping[formData.businessEmploymentVerified] ||
      null;
    const clientStatusValue =
      clientStatusMapping[formData.clientStatus] || null;
    const coBorrowerPhoneNumberVerifedValue =
      coBorrowerPhoneNumberVerifedMapping[
        formData.coborrowerPhoneNumberVerified
      ] || null;
    const finalDecisionValue =
      finalDecisionMapping[formData.finalDecision] || null;
    const firstGenerationEntreprenureValue =
      firstGenerationEntreprenureMapping[
        formData.firstGenerationEntreprenure
      ] || null;
    const newToCreditValue = newToCreditMapping[formData.newToCredit] || null;
    const staysAtAadhaarAddressValue =
      staysAtAadhaarAddressMapping[formData.staysAtAadhaarAddress] || null;
    const existingBusinessValue =
      existingBusinessMapping[formData.selectedBusinessName] || null;
    const custWillPayOnlineValue =
      custWillPayOnlineMapping[formData.selectedClientWilling] || null;
    const familyMemberApprovalStatusValue =
      familyMemberApprovalStatusMapping[
        formData.selectedFamilyMemberApproval
      ] || null;
    const smartPhoneStatusValue =
      smartPhoneMapping[formData.smartPhone] || null;
    const maritalStatusValue =
      maritalStatusMapping[formData.maritalStatus] || null;
    const occupationValue = occupationMapping[formData.occupation] || null;
    const custWillingTakeLaonValue =
      custWillingTakeLaonMapping[formData.selectedCustomerWilling] || null;

    // Construct the data object using the variables
    let data = JSON.stringify({
      id: null,
      doneOn: formData.entryDate,
      latitude: formData.latitude,
      longitude: formData.longitude,
      otherPersonName: formData.otherPerson,
      relnWithOtherPerson: formData.relation,
      locatingRsdncAddrs: locatingRsdncAddrsValue,
      locality: localityValue,
      accomodationType: accomodationTypeValue,
      sizeOfRsdnc: sizeOfRsdncValue,
      assetsSeen: assetsSeenValue,
      locatingBsnsAddrs: locatingBsnsAddrsValue,
      businessLocType: businessLocTypeValue,
      busVisibility: busVisibilityValue,
      levelOfAct: levelOfActValue,
      stocks: stocksValue,
      peakBusHours: peakBusHoursValue,
      resiVefThrough: resiVefThroughValue,
      markFeedback: markFeedbackValue,
      supplierName: null,
      supplierPhoneno: null,
      buyerName: null,
      buyerPhoneno: null,
      supplierRemark: null,
      buyerRemark: null,
      otherRemark: formData.remarks,
      businessRemark: formData.remarks,
      houseRemark: formData.householdRemarks,
      loanReapyamentRemark: formData.remarks,
      creditStatus: null,
      noOtherFamilyMmbrChckPvMel: null,
      rejectSlctdReason: null,
      customerMet: customerMetValue,
      kycVerified: kycVerifiedValue,
      addressVerified: addressVerifiedValue,
      phoneNoVerified: phoneNoVerifiedValue,
      noOfYrsAtCrntResid: formData.yearsAtCurrentResidence,
      residenceStatus: residenceStatusValue,
      ownershipProofChkd: 1,
      standardOfLiving: standardOfLivingValue,
      neighbourCheck: neighbourCheckValue,
      businessEmploymentVerified: businessEmploymentVerifiedValue,
      noOfYrsInBusinessEmployment: "7",
      noOfYrsAtCurrentAddress: formData.yearsAtCurrentAddress,
      businessPlaceStatus: residenceStatusValue,
      nameOfBusiness: null,

      natureOfBusiness: `${existingData.natureOfBusiness}, ${newBusinessForms
        .map((form) => form.natureOfBusiness)
        .filter((n) => n)
        .join(", ")}`,
      scaleOfBusiness: `${existingData.scaleOfBusiness}, ${newBusinessForms
        .map((form) => form.scaleOfBusiness)
        .filter((s) => s)
        .join(", ")}`,
      businessMargin: `${existingData.businessMargin}, ${newBusinessForms
        .map((form) => form.businessMargin)
        .filter((b) => b)
        .join(", ")}`,
      stockItemsSeen: null,
      stockValue: null,
      partnerInBusiness: 0,
      negativeProfile: 0,
      purposeOfLoan: null,
      nameRefrences_1: formData.referenceName,
      relnRefrences_1: formData.referenceRelation,
      mobRefrences_1: formData.referenceMobNo,
      nameRefrences_2: formData.secondReferenceName,
      relnRefrences_2: formData.secondReferenceRelation,
      mobRefrences_2: formData.secondReferenceMobNo,
      monthlySales: null,
      monthlyProfit: null,
      otherIncome: null,
      businessExp: null,
      houseExp: null,
      loanRepayment: null,
      disposableIncome: 29750,
      custWillingTakeLaon: custWillingTakeLaonValue,
      appFormDetailsChkd: 1,
      loanAmtRecommended: null,
      remarks: "DIGITAL_PV_VERIFIED",
      finalDecision: 1,
      appClientId: formData.appClientId,
      laonId: null,
      doneBy: 6,
      otherPurpose: "",
      neighbourName: null,
      neighbourMobileNumber: null,
      moveCaseMblToCml: 0,
      updatedBy: null,
      updatedOn: null,
      purposeId: "39,40,14",

      lenderName: lenderNames,
      lenderEmi: lenderEmis,
      noOfFamilyMembers: formData.familyMembers,
      noOfPeopleEmployed: formData.noOfPeopleEmployed,
      noOfWomenEmployed: formData.noOfWomenEmployed,
      firstGenerationEntreprenure: firstGenerationEntreprenureValue,
      newToCredit: newToCreditValue,
      netSurplus: 29750,
      income: formData.totalIncome,
      expenses: 4000,
      emi: 0,
      ctlEmi: 0,
      pvAddress: formData.address,
      borrCoborrStayAadharAddress: staysAtAadhaarAddressValue,
      houseType: formData.houseType,
      existingBusiness: existingBusinessValue,
      custWillPayOnline: custWillPayOnlineValue,
      custAltNo: formData.alternateMobileNo,
      udhaymregno: null,
      householdRemarks: formData.householdRemarks,
      referenceFeedbackFirst: formData.referenceFeedback,
      referenceFeedbackSecond: formData.secondReferenceFeedback,
      coborrowerFatherOrBrotherName: formData.coBorrowerRelativeName,
      coborrowerFatherOrBrotherNo: formData.coBorrowerRelativeMobNo,
      coborrowerFatherOrBrotherAddress: formData.coBorrowerRelativeAddress,
      approvalOfFamilyMember: formData.selectedFamilyMember,
      familyMemberApprovalStatus: familyMemberApprovalStatusValue,
      totalHouseholdMonthlyIncomes: formData.totalHouseholdIncome,
      householdDisposalIncomes: formData.totalHouseholdDisposableIncome,
      timesMonthlyInstallments: formData.timesMonthlyInstallment,
      clientStatus: clientStatusValue,
      clientType: formData.clientFeedback1,
      neighbourStatus: neighbourCheckValue,
      neighbourType: formData.neighbourFeedback1,
      endUseOfLoan: formData.endUseOfLoan,
      coBorrowerPhoneNumberVerifed: coBorrowerPhoneNumberVerifedValue,
      geoLocationUpdatedDate: null,
      geoLocationUpdatedBy: null,
      customerRelation: formData.relation,
      loanIdEx: null,
      maritalStatus: maritalStatusValue,
      clientCaste: religionValue,
      category: categoryValue,
      education: educationValue,
      smartPhoneStatus: smartPhoneStatusValue,
      motherName: formData.motherName,
      comothername: formData.comothername,
      emailId: formData.emailId,
      occupation: occupationValue,
      businessAddress: formData.businessAddress,
      lenderId: null,
      lenderType: 1,
      lenderCoborrowerEmi: coborrowerLenderEmis,
      lenderCoborrowerName: coborrowerLenderNames,
      lenderCoborrowerId: null,
      lenderCoborrowerType: 2,
      pvDoneByName: null,
      pvDoneByNumber: null,
      digitalPvHouseholdIncome: null,
    });

    console.log("Success:", data);
    //http://13.233.134.156:8090/api/pv/updateCPVdetails
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            ...response.data,
          }));
          alert("CPV Form Successfully Updated!");
          console.log("Form submitted successfully!");
          handleCloseDialog();
          handleLinkClick();
        } else {
          console.error(
            "Failed to submit form:",
            response.status,
            response.data
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to calculate incomePerMonth
  const calculateIncomePerMonthCow = () => {
    const noOfCow = parseFloat(formData.milkingCows) || 0;
    const averagelitresperday = parseFloat(formData.litresPerDay) || 0;
    const averagesellingpriceperlitre =
      parseFloat(formData.sellingPricePerLitre) || 0;
    const avergeExpensePerMont = parseFloat(formData.expensesPerMonth) || 0;

    // Calculate cowtotalexpen based on the new formula
    const cowtotalexpen = noOfCow * avergeExpensePerMont;
    const calculatedIncomePerMonth =
      noOfCow * averagelitresperday * averagesellingpriceperlitre * 30 -
      cowtotalexpen;
    // Format to 1 decimal place
    const formattedIncomePerMonthcow = calculatedIncomePerMonth.toFixed(1);
    setFormData((prevData) => ({
      ...prevData,
      incomePerMonth: formattedIncomePerMonthcow,
    }));
  };

  // Use useEffect to recalculate incomePerMonth when relevant inputs change
  useEffect(() => {
    calculateIncomePerMonthCow();
  }, [
    formData.milkingCows,
    formData.litresPerDay,
    formData.sellingPricePerLitre,
    formData.expensesPerMonth,
  ]);

  // Function to calculate incomePerMonthag based on the formula
  const calculateIncomePerMonthAcres = () => {
    const numberOfAcres = parseFloat(formData.numberOfAcres) || 0;
    const averageIncomePerAcre = parseFloat(formData.averageIncomePerAcre) || 0;

    // Calculate incomePerMonthag
    const calculatedIncomePerMonthag = numberOfAcres * averageIncomePerAcre;
    const formattedIncomePerMonthacre = calculatedIncomePerMonthag.toFixed(1);

    setFormData((prevData) => ({
      ...prevData,
      incomePerMonthag: formattedIncomePerMonthacre,
    }));
  };

  // Use useEffect to recalculate incomePerMonthag when relevant inputs change
  useEffect(() => {
    calculateIncomePerMonthAcres();
  }, [formData.numberOfAcres, formData.averageIncomePerAcre]);

  const calculateIncomePerMonthBuffalo = () => {
    const milkingBuffalo = parseFloat(formData.milkingBuffalo) || 0;
    const litresPerDayBuffalo = parseFloat(formData.litresPerDayBuffalo) || 0;
    const sellingPricePerLitreBuffalo =
      parseFloat(formData.sellingPricePerLitreBuffalo) || 0;
    const expensesPerMonthBuffalo =
      parseFloat(formData.expensesPerMonthBuffalo) || 0;

    const buffalototalexpen = milkingBuffalo * expensesPerMonthBuffalo;
    // Calculate incomePerMonthBuffalo
    const totalIncome =
      milkingBuffalo * litresPerDayBuffalo * sellingPricePerLitreBuffalo * 30;
    const incomePerMonthBuffalo = totalIncome - buffalototalexpen;

    // Format to 1 decimal place
    const formattedIncomePerMonthBuffalo = incomePerMonthBuffalo.toFixed(1);

    setFormData((prevData) => ({
      ...prevData,
      incomePerMonthBuffalo: formattedIncomePerMonthBuffalo,
    }));
  };

  // Use useEffect to recalculate incomePerMonthBuffalo when relevant inputs change
  useEffect(() => {
    calculateIncomePerMonthBuffalo();
  }, [
    formData.milkingBuffalo,
    formData.litresPerDayBuffalo,
    formData.sellingPricePerLitreBuffalo,
    formData.expensesPerMonthBuffalo,
  ]);

  // Function to calculate incomePerMonthMajdoor based on the formula
  const calculateIncomePerMonthMajdoor = () => {
    const noofdaysworkinginmonth =
      parseFloat(formData.noofdaysworkinginmonth) || 0;
    const incomeperdaymajdoor = parseFloat(formData.incomeperdaymajdoor) || 0;

    // Calculate incomePerMonthMajdoor
    const incomePerMonthMajdoor = noofdaysworkinginmonth * incomeperdaymajdoor;

    // Format to 1 decimal place
    const formattedIncomePerMonthMajdoor = incomePerMonthMajdoor.toFixed(1);

    setFormData((prevData) => ({
      ...prevData,
      incomepermonthmajdoor: formattedIncomePerMonthMajdoor,
    }));
  };

  // Use useEffect to recalculate incomePerMonthMajdoor when relevant inputs change
  useEffect(() => {
    calculateIncomePerMonthMajdoor();
  }, [formData.noofdaysworkinginmonth, formData.incomeperdaymajdoor]);

  // Function to calculate incomePerMonthMachine based on the formula
  const calculateIncomePerMonthMachine = () => {
    const noofsewingmachines = parseFloat(formData.noofsewingmachines) || 0;
    const incomeperdaymachine = parseFloat(formData.incomeperdaymachine) || 0;

    // Calculate incomePerMonthMachine
    const incomePerMonthMachine = noofsewingmachines * incomeperdaymachine;

    // Format to 1 decimal place
    const formattedIncomePerMonthMachine = incomePerMonthMachine.toFixed(1);

    setFormData((prevData) => ({
      ...prevData,
      incomepermonthmachine: formattedIncomePerMonthMachine,
    }));
  };

  // Use useEffect to recalculate incomePerMonthMachine when relevant inputs change
  useEffect(() => {
    calculateIncomePerMonthMachine();
  }, [formData.noofsewingmachines, formData.incomeperdaymachine]);

  const calculateBusinessIncome = (index) => {
    setBusinessFormList((prevFormList) =>
      prevFormList.map((form, idx) => {
        if (idx === index) {
          const businessIncome =
            (parseFloat(form.formData.businessRevenue) || 0) -
            (parseFloat(form.formData.businessExpenses) || 0);

          return {
            ...form,
            formData: {
              ...form.formData,
              businessIncome,
            },
          };
        }
        return form;
      })
    );
  };

  const totalBusinessIncome = businessFormList.reduce((total, form) => {
    return total + Number(form.formData.businessIncome || 0);
  }, 0);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div class="pt-4">
      {updateForm ? (
        <div>
          <button
            className="btn btn-secondary"
            style={{ width: "auto" }}
            onClick={() => setUpdateForm(false)}
          >
            Back
          </button>
          <ApprovedDataUpdate
            collateralVerificationStatus={
              verificationData.collateralVerificationStatus
            }
            businessVerificationStatus={
              verificationData.businessVerificationStatus
            }
            residenceVerificationStatus={
              verificationData.residenceVerificationStatus
            }
          />
        </div>
      ) : (
        // {verificationData && (
        //   <Modal show={isModalOpen} onHide={closeModal}>
        //     <Modal.Header closeButton>
        //       <Modal.Title>Update Details</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>
        //       {/* Conditional Rendering based on statuses */}
        //       {verificationData.collateralVerificationStatus === 1 &&
        //       verificationData.businessVerificationStatus === 1 ? (
        //         <>
        //           <div className="form-check">
        //             <input
        //               className="form-check-input"
        //               type="radio"
        //               name="verification"
        //               value="approved"
        //             />
        //             <label className="form-check-label">
        //               Recidence Verification
        //             </label>
        //           </div>
        //           <div className="form-check">
        //             <input
        //               className="form-check-input"
        //               type="radio"
        //               name="verification"
        //               value="rejected"
        //             />
        //             <label className="form-check-label">
        //               Collateral Verification
        //             </label>
        //           </div>
        //         </>
        //       ) : (
        //         // If both statuses are not 1, display the other details
        //         <div>
        //           <p>
        //             <strong>Business Verification Status:</strong>{" "}
        //             {verificationData.businessVerificationStatus}
        //           </p>
        //           <p>
        //             <strong>Collateral Verification Status:</strong>{" "}
        //             {verificationData.collateralVerificationStatus}
        //           </p>
        //           <p>
        //             <strong>Final Decision:</strong>{" "}
        //             {verificationData.finalDecision}
        //           </p>
        //           <p>
        //             <strong>Remarks:</strong> {verificationData.remarks}
        //           </p>
        //           <p>
        //             <strong>Done By:</strong> {verificationData.doneBy}
        //           </p>
        //           <p>
        //             <strong>Done On:</strong>{" "}
        //             {new Date(verificationData.doneOn).toLocaleDateString()}
        //           </p>
        //         </div>
        //       )}
        //     </Modal.Body>
        //     <Modal.Footer>
        //       <Button variant="secondary" onClick={closeModal}>
        //         Close
        //       </Button>
        //     </Modal.Footer>
        //   </Modal>
        // )}
        <>
          <h4 className="text-center my-3">Approved</h4>

          <div className="pt-3">
            <div className="card">
              <div className="p-3 pt-4">
                <div className="p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
                  {/* <div>
                <button
                  className="btn btn-success mb-3 mb-md-0"
                  style={{ width: "120px", background: "blue" }}
                  onClick={() => downloadExcel()}
                >
                  Download
                </button>
              </div> */}
                  <div className="d-flex flex-column flex-md-row align-items-center">
                    {!selectDate ? (
                      <button
                        className="btn btn-info"
                        style={{ width: "150px" }}
                        onClick={() => setSelectDate(true)}
                      >
                        Filters
                      </button>
                    ) : (
                      <>
                        <div className="d-flex" style={{ width: "100%" }}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="mt-2 mx-1">
                              <Form.Select
                                options={clientDetails}
                                value={selectValue}
                                type="number"
                                className="form-control ml-2"
                                onChange={(e) => setSelectValue(e.target.value)}
                              >
                                <option value="">Select Client</option>
                                {clientDetails.map((ids) => (
                                  <option value={ids.id} key={ids.id}>
                                    {ids.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className="mt-2 mx-2">
                              <Form.Select
                                options={engineerDetail}
                                value={assignTo}
                                type="text"
                                className="form-control ml-2"
                                onChange={(e) => setAssignTo(e.target.value)}
                              >
                                <option value="">Select Approved By</option>

                                {engineerDetail.map((ids) => (
                                  <option value={ids.id} key={ids.id}>
                                    {ids.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div>
                              <DatePicker
                                className="ml-2"
                                label="To Date"
                                value={dateValue1}
                                inputFormat="dd-MM-yyyy"
                                onChange={(e) => {
                                  setDateValue1(moment(e).format("YYYY-MM-DD"));
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </div>
                            <div>
                              <DatePicker
                                className="ml-2"
                                label="From Date"
                                value={dateValue2}
                                inputFormat="dd-MM-yyyy"
                                onChange={(e) =>
                                  setDateValue2(moment(e).format("YYYY-MM-DD"))
                                }
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </div>
                          </LocalizationProvider>
                          <div className="mt-2 mx-2">
                            <button
                              className="btn btn-success"
                              style={{
                                background: "#66a725",
                                width: "100px",
                              }}
                              onClick={() => handleSubmit()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="table-responsive mt-3">
                  <table className="table table-boarded" ref={tableRef}>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Case No</th>
                        <th>Applicant Name</th>
                        <th>Mobile Number</th>
                        <th>Address</th>

                        <th>client name</th>

                        <th>Approved By</th>
                        <th>Remark</th>
                        <th>Download </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.caseReferenceNumber}</td>
                          <td>{item?.applicantName}</td>
                          <td>{item?.mobile}</td>
                          <td>{item?.address}</td>

                          <td>{item?.clientName}</td>

                          <td>{item?.approvedBy}</td>
                          <td>{item?.remarks}</td>
                          <td>
                            <button
                              className="cursor-pointer btn btn-primary"
                              onClick={() => {
                                openLink(item?.caseReferenceNumber);
                              }}
                            >
                              Download
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Dialog
                  title="CPV Form"
                  modal={false}
                  fullWidth
                  maxWidth="md"
                  open={dialogOpen}
                  onClose={handleCloseDialog}
                >
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      backgroundColor: "#f9f9f9",
                      width: "100%",
                    }}
                  >
                    {/* Form Title */}
                    <h3 style={{ marginBottom: "20px", textAlign: "center" }}>
                      CPV Form
                    </h3>

                    <form
                      className="pt-4"
                      // onSubmit={handleFinalSubmit}
                      style={{ width: "100%" }}
                    >
                      {/* Applicant Name */}
                      <div
                        className="form-group mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="applicantName"
                          style={{
                            marginRight: "75px",
                            marginBottom: "0",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Applicant Name:
                        </label>
                        <input
                          type="text"
                          id="applicantName"
                          name="applicantName"
                          placeholder="Enter applicant name"
                          value={formData.applicantName}
                          // onChange={handleInputChange}
                          style={{ flex: "1" }}
                          readOnly={false}
                          disabled={false}
                        />
                      </div>

                      {/* Client Name */}
                      <div
                        className="form-group mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="clientName"
                          style={{ width: "100px", marginRight: "100px" }}
                        >
                          Client Name:
                        </label>
                        <input
                          type="text"
                          id="clientName"
                          name="clientName"
                          placeholder="Enter client name"
                          value={formData.clientName}
                          // onChange={handleInputChange}
                          style={{ flex: "1" }}
                          readOnly={false}
                          disabled={false}
                        />
                      </div>

                      {/* Case ID */}
                      <div
                        className="form-group mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="caseId"
                          style={{ width: "100px", marginRight: "100px" }}
                        >
                          Case ID:
                        </label>
                        <input
                          type="number"
                          id="caseId"
                          name="caseId"
                          placeholder="Enter case ID"
                          value={formData.caseId}
                          // onChange={handleInputChange}
                          style={{ flex: "1" }}
                          readOnly={false}
                          disabled={false}
                        />
                      </div>

                      {/* Mobile No. */}
                      <div
                        className="form-group mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="mobileNo"
                          style={{ width: "100px", marginRight: "100px" }}
                        >
                          Mobile No:
                        </label>
                        <input
                          type="tel"
                          id="mobileNo"
                          name="mobileNo"
                          placeholder="Enter mobile number"
                          value={formData.mobileNo}
                          onChange={handleInputChange}
                          style={{ flex: "1" }}
                          maxLength="10"
                          readOnly={false}
                          disabled={true}
                        />
                      </div>

                      {/* Address */}
                      <div
                        className="form-group mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="address"
                          style={{ width: "100px", marginRight: "100px" }}
                        >
                          Address:
                        </label>
                        <textarea
                          id="address"
                          name="address"
                          placeholder="Enter address"
                          value={formData.address}
                          onChange={handleInputChange}
                          style={{ flex: "1" }}
                        />
                      </div>

                      {/* Personal Information Section */}
                      <div
                        className="form-group mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <h5
                          style={{
                            margin: 0,
                            flex: "1",
                            backgroundColor: "#02CCFE",
                          }}
                        >
                          Personal Information
                        </h5>
                        <button
                          type="button"
                          onClick={togglePersonalInfo}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          {personalInfoOpen ? "-" : "+"}
                        </button>
                      </div>

                      {personalInfoOpen && (
                        <div
                          className="personal-info-section"
                          style={{
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          {/* Marital Status */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="maritalStatus"
                              style={{
                                width: "100px",
                                marginRight: "100px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Marital Status:
                            </label>
                            <select
                              id="maritalStatus"
                              name="maritalStatus"
                              value={formData.maritalStatus}
                              onChange={updateMaritalStatus}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="Unmarried">Unmarried</option>
                              <option value="married">Married</option>
                            </select>
                          </div>

                          {/* Religion */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="religion"
                              style={{
                                width: "100px",
                                marginRight: "100px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Religion:
                            </label>
                            <select
                              id="religion"
                              name="religion"
                              value={formData.religion}
                              onChange={handleSelectChange}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="Hindu">Hindu</option>
                              <option value="Sikh">Sikh</option>
                              <option value="Muslim">Muslim</option>
                              <option value="Christian">Christian</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>

                          {/* Category */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="category"
                              style={{ width: "100px", marginRight: "100px" }}
                            >
                              Category:
                            </label>
                            <select
                              id="category"
                              name="category"
                              value={formData.category}
                              onChange={handleSelectChange}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="General">General</option>
                              <option value="OBC">OBC</option>
                              <option value="SC">SC</option>
                              <option value="ST">ST</option>
                            </select>
                          </div>

                          {/* Education */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="education"
                              style={{ width: "100px", marginRight: "100px" }}
                            >
                              Education:
                            </label>
                            <select
                              id="education"
                              name="education"
                              value={formData.education}
                              onChange={handleSelectChange}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="Highschool">High School</option>
                              <option value="Undergraduate">
                                Undergraduate
                              </option>
                              <option value="Graduate">Graduate</option>
                              <option value="Post-Graduate">
                                Postgraduate
                              </option>
                            </select>
                          </div>

                          {/* Occupation */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="occupation"
                              style={{ width: "100px", marginRight: "100px" }}
                            >
                              Occupation:
                            </label>
                            <select
                              id="occupation"
                              name="occupation"
                              value={formData.occupation}
                              onChange={handleSelectChange}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="salaried">Salaried</option>
                              <option value="self Employed">
                                Self Employed
                              </option>
                            </select>
                          </div>

                          {/* Borrower Mother Name */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="motherName"
                              style={{
                                marginRight: "15px",
                                marginBottom: "0",
                                whiteSpace: "nowrap", // Prevent text from wrapping
                              }}
                            >
                              Borrower Mother Name:
                            </label>
                            <input
                              type="text"
                              id="motherName"
                              name="motherName"
                              placeholder="Enter borrower mother name"
                              value={formData.motherName}
                              onChange={updateMotherNames}
                              style={{ flex: "1" }}
                            />
                          </div>

                          {/* Co-Borrower Mother Name */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="comothername"
                              style={{
                                marginRight: "0px",
                                marginBottom: "0",
                                whiteSpace: "nowrap", // Prevent text from wrapping
                              }}
                            >
                              Co-Borrower Mother Name:
                            </label>
                            <input
                              type="text"
                              id="comothername"
                              name="comothername"
                              placeholder="Enter co-borrower mother name"
                              value={formData.comothername}
                              onChange={updateMotherNames}
                              style={{ flex: "1" }}
                            />
                          </div>

                          {/* Smart Phone */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="smartPhone"
                              style={{
                                width: "100px",
                                whiteSpace: "nowrap",
                                marginRight: "100px",
                              }}
                            >
                              Smart Phone:
                            </label>
                            <select
                              id="smartPhone"
                              name="smartPhone"
                              value={formData.smartPhone}
                              onChange={handleSelectChange}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="YES">Yes</option>
                              <option value="NO">No</option>
                            </select>
                          </div>

                          {/* Email ID */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="emailId"
                              style={{ width: "100px", marginRight: "100px" }}
                            >
                              Email ID:
                            </label>
                            <input
                              type="email"
                              id="emailId"
                              name="emailId"
                              placeholder="Enter email ID"
                              value={formData.emailId}
                              onChange={updateMotherNames}
                              style={{ flex: "1" }}
                              required // Makes this field mandatory
                              aria-invalid={emailError ? "true" : "false"} // For accessibility
                            />
                            {emailError && (
                              <span
                                style={{ color: "red", marginLeft: "10px" }}
                              >
                                {emailError}
                              </span> // Error message
                            )}
                          </div>

                          {/* Relation */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="relation"
                              style={{ width: "100px", marginRight: "100px" }}
                            >
                              Relation:
                            </label>
                            <select
                              id="relation"
                              name="relation"
                              value={formData.relation}
                              onChange={updateRelation}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="Mother">Mother</option>
                              <option value="Wife">Wife</option>
                            </select>
                          </div>
                        </div>
                      )}
                      {/* Residence Verification Section */}
                      <div
                        className="form-group mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <h5
                          style={{
                            margin: 0,
                            flex: "1",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#02CCFE",
                          }}
                        >
                          Residence Verification
                        </h5>
                        <button
                          type="button"
                          onClick={toggleResidenceVerification}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          {residenceVerificationOpen ? "-" : "+"}
                        </button>
                      </div>

                      {residenceVerificationOpen && (
                        <div
                          className="residence-verification-section"
                          style={{
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          {/* Locating Residence Address */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="locatingRsdncAddrs"
                              style={{
                                marginRight: "15px",
                                marginBottom: "0",
                                whiteSpace: "nowrap", // Prevent text from wrapping
                              }}
                            >
                              Locating Residence Address:
                            </label>
                            <select
                              id="locatingRsdncAddrs"
                              name="locatingRsdncAddrs"
                              value={formData.locatingRsdncAddrs}
                              onChange={updateResidenceAddress}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="Easy">Easy</option>
                              <option value="Difficult">Difficult</option>
                              <option value="Untrackable">Untrackable</option>
                            </select>
                          </div>

                          {/* Locality */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="locality"
                              style={{ width: "200px" }}
                            >
                              Locality:
                            </label>
                            <select
                              id="locality"
                              name="locality"
                              value={formData.locality}
                              onChange={handleSelectChange}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="Residential">Residential</option>
                              <option value="Comercial">Commercial</option>
                              <option value="Resi- cum Commercial">
                                Resi-Cum Commercial
                              </option>
                            </select>
                          </div>

                          {/* Accommodation Type */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="accommodationType"
                              style={{ width: "200px" }}
                            >
                              Accommodation Type:
                            </label>
                            <select
                              id="accommodationType"
                              name="accommodationType"
                              value={formData.accommodationType}
                              onChange={handleSelectChange}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="Independent House">
                                Independent House
                              </option>
                              <option value="Bunglow">Bunglow</option>
                              <option value="Flat">Flat</option>
                              <option value="Chawl">Chawl</option>
                              <option value="Any Other">Any Other</option>
                            </select>
                          </div>

                          {/* Size of Residence */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="sizeOfResidence"
                              style={{ width: "200px" }}
                            >
                              Size of Residence:
                            </label>
                            <select
                              id="sizeOfResidence"
                              name="sizeOfResidence"
                              value={formData.sizeOfResidence}
                              onChange={handleSelectChange}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="Small">Small</option>
                              <option value="Medium">Medium</option>
                              <option value="Large">Large</option>
                            </select>
                          </div>

                          {/* Assets Seen */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="assetsSeen"
                              style={{ width: "200px" }}
                            >
                              Assets Seen:
                            </label>
                            <select
                              id="assetsSeen"
                              name="assetsSeen"
                              value={formData.assetsSeen}
                              onChange={handleSelectChange}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="TV">TV</option>
                              <option value="Refrigerator">Refrigerator</option>
                              <option value="Washing Machine">
                                Washing Machine
                              </option>
                              <option value="Bike">Bike</option>
                            </select>
                          </div>

                          {/* House Type */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="houseType"
                              style={{ width: "200px" }}
                            >
                              House Type:
                            </label>
                            <select
                              id="houseType"
                              name="houseType"
                              value={formData.houseType}
                              onChange={handleSelectChange}
                              style={{ flex: "1" }}
                            >
                              <option value="">Select</option>
                              <option value="Mud">Mud</option>
                              <option value="Brick">Brick</option>
                            </select>
                          </div>

                          {/* Borrower and Co-Borrower Stays at Aadhaar Address */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-15px",
                            }}
                          >
                            <label
                            // style={headingStylestaysAtAadhaarAddress}
                            >
                              Borrower and Co-Borrower Stays at Aadhaar Address:
                            </label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "50px",
                                marginLeft: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="staysYes"
                                  name="staysAtAadhaarAddress"
                                  value="YES"
                                  checked={
                                    formData.staysAtAadhaarAddress === "YES"
                                  }
                                  onChange={handleRadioButtonChange}
                                  style={{ marginRight: "5px" }}
                                />
                                <label
                                  htmlFor="staysYes"
                                  style={{ margin: "0" }}
                                >
                                  Yes
                                </label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="staysNo"
                                  name="staysAtAadhaarAddress"
                                  value="NO"
                                  checked={
                                    formData.staysAtAadhaarAddress === "NO"
                                  }
                                  onChange={handleRadioButtonChange}
                                  style={{ marginRight: "5px" }}
                                />
                                <label
                                  htmlFor="staysNo"
                                  style={{ margin: "0" }}
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* Borrower and Co-Borrower Met */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-20px",
                            }}
                          >
                            <label>Borrower and Co-Borrower Met:</label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "50px",
                                marginLeft: "10px",
                              }}
                            >
                              <label
                                style={{
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  name="metStatus" // Add name attribute to group the radio buttons
                                  value="YES"
                                  checked={formData.metStatus === "YES"}
                                  onChange={handleRadioButtonChange}
                                />
                                <span style={{ marginLeft: "5px" }}>Yes</span>
                              </label>
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  name="metStatus" // Add name attribute to group the radio buttons
                                  value="NO"
                                  checked={formData.metStatus === "NO"}
                                  onChange={handleRadioButtonChange}
                                />
                                <span style={{ marginLeft: "5px" }}>No</span>
                              </label>
                            </div>
                          </div>

                          {formData.metStatus === "NO" && (
                            <div style={{ marginTop: "10px" }}>
                              <div
                                className="form-group mt-2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "5px",
                                }}
                              >
                                <label
                                  htmlFor="otherPerson"
                                  style={{ width: "150px" }}
                                >
                                  Other Person Met
                                </label>
                                <input
                                  type="text"
                                  id="otherPerson"
                                  name="otherPerson"
                                  placeholder="Enter name of other person"
                                  value={formData.otherPerson}
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "5px",
                                    flex: "1",
                                  }}
                                />
                              </div>
                              <div
                                className="form-group mt-2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "5px",
                                }}
                              >
                                <label
                                  htmlFor="relation"
                                  style={{ width: "150px" }}
                                >
                                  Relation of Other Person
                                </label>
                                <input
                                  type="text"
                                  id="relation"
                                  name="relation"
                                  placeholder="Enter relation of other person"
                                  value={formData.relation}
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "5px",
                                    flex: "1",
                                  }}
                                />
                              </div>
                            </div>
                          )}

                          {/* Borrower and Co-Borrower KYC Verified */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-15px",
                            }}
                          >
                            <label>
                              Borrower and Co-Borrower KYC Verified:
                            </label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "50px",
                                marginLeft: "10px",
                              }}
                            >
                              <label
                                style={{
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  name="kycVerified" // Add name attribute here to group the radio buttons
                                  value="YES"
                                  checked={formData.kycVerified === "YES"}
                                  onChange={handleRadioButtonChange}
                                />
                                <span style={{ marginLeft: "5px" }}>Yes</span>
                              </label>
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  name="kycVerified" // Add name attribute here to group the radio buttons
                                  value="NO"
                                  checked={formData.kycVerified === "NO"}
                                  onChange={handleRadioButtonChange}
                                />
                                <span style={{ marginLeft: "5px" }}>No</span>
                              </label>
                            </div>
                          </div>

                          {/* Address Verified */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-15px",
                            }}
                          >
                            <label>Address Verified:</label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "50px",
                                marginLeft: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="addressYes"
                                  name="addressVerified"
                                  value="YES" // Set the value to the specific choice
                                  checked={formData.addressVerified === "YES"}
                                  onChange={handleRadioButtonChange}
                                  style={{ marginRight: "5px" }}
                                />
                                <label
                                  htmlFor="addressYes"
                                  style={{ margin: "0" }}
                                >
                                  Yes
                                </label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="addressNo"
                                  name="addressVerified"
                                  value="NO" // Set the value to the specific choice
                                  checked={formData.addressVerified === "NO"}
                                  onChange={handleRadioButtonChange}
                                  style={{ marginRight: "5px" }}
                                />
                                <label
                                  htmlFor="addressNo"
                                  style={{ margin: "0" }}
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* Customer Address */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-10px",
                            }}
                          >
                            <label
                              htmlFor="businessAddress"
                              style={{ width: "150px", marginRight: "10px" }}
                            >
                              Customer Address:
                            </label>
                            <textarea
                              id="businessAddress"
                              name="businessAddress"
                              placeholder="Enter customer address"
                              value={formData.businessAddress}
                              onChange={updateCustomerAddress}
                              style={{ flex: "1" }}
                            />
                          </div>

                          {/* Borrower Phone Number Verified */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-15px",
                            }}
                          >
                            <label>Borrower Phone Number Verified:</label>
                            <div
                              style={{
                                marginLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                                gap: "50px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="borrowerPhoneYes"
                                  name="borrowerPhoneNumberVerified"
                                  value="YES"
                                  checked={
                                    formData.borrowerPhoneNumberVerified ===
                                    "YES"
                                  }
                                  onChange={handleRadioButtonChange}
                                  style={{ marginRight: "5px" }}
                                />
                                <label
                                  htmlFor="borrowerPhoneYes"
                                  style={{ margin: "0" }}
                                >
                                  Yes
                                </label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="borrowerPhoneNo"
                                  name="borrowerPhoneNumberVerified"
                                  value="NO"
                                  checked={
                                    formData.borrowerPhoneNumberVerified ===
                                    "NO"
                                  }
                                  onChange={handleRadioButtonChange}
                                  style={{ marginRight: "5px" }}
                                />
                                <label
                                  htmlFor="borrowerPhoneNo"
                                  style={{ margin: "0" }}
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* Co-Borrower Phone Number Verified */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-15px",
                            }}
                          >
                            <label>Co-Borrower Phone Number Verified:</label>
                            <div
                              style={{
                                marginLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                                gap: "50px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="coBorrowerPhoneYes"
                                  name="coborrowerPhoneNumberVerified" // Match this with the state key
                                  value="YES"
                                  checked={
                                    formData.coborrowerPhoneNumberVerified ===
                                    "YES"
                                  } // Ensure this matches the state key
                                  onChange={handleRadioButtonChange}
                                  style={{ marginRight: "5px" }}
                                />
                                <label
                                  htmlFor="coBorrowerPhoneYes"
                                  style={{ margin: "0" }}
                                >
                                  Yes
                                </label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="coBorrowerPhoneNo"
                                  name="coborrowerPhoneNumberVerified" // Match this with the state key
                                  value="NO"
                                  checked={
                                    formData.coborrowerPhoneNumberVerified ===
                                    "NO"
                                  } // Ensure this matches the state key
                                  onChange={handleRadioButtonChange}
                                  style={{ marginRight: "5px" }}
                                />
                                <label
                                  htmlFor="coBorrowerPhoneNo"
                                  style={{ margin: "0" }}
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* Alternate Mobile Number Field */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="alternateMobileNo"
                              style={{
                                marginRight: "15px",
                                marginBottom: "0",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Alternate Mobile No:
                            </label>
                            <input
                              type="number"
                              id="alternateMobileNo"
                              name="alternateMobileNo"
                              placeholder="Enter alternate mobile number"
                              value={formData.alternateMobileNo}
                              onChange={handleInputChange}
                              style={{ flex: "1", padding: "5px" }} // Added padding for better user experience
                              maxLength="10" // Limit input length to 10 characters
                            />
                            {!validateMobileNumberAlternate(
                              formData.alternateMobileNo
                            ) &&
                              formData.alternateMobileNo.length > 0 && (
                                <div
                                  style={{ color: "red", marginLeft: "10px" }}
                                ></div>
                              )}
                          </div>

                          {/* Number of Years at Current Residence Field */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="yearsAtCurrentResidence"
                              style={{
                                marginRight: "15px",
                                marginBottom: "0",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Number of Years at Current Residence:
                            </label>
                            <select
                              id="yearsAtCurrentResidence"
                              name="yearsAtCurrentResidence"
                              value={formData.yearsAtCurrentResidence}
                              onChange={handleSelectChange}
                              style={{ flex: "1", padding: "5px" }} // Added padding for better user experience
                            >
                              <option value="">Select</option>
                              {[...Array(11).keys()].map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                              <option value="10+">10+</option>
                            </select>
                          </div>

                          {/* Residence Status */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-15px",
                            }}
                          >
                            <label>Residence Status:</label>
                            <div
                              style={{
                                marginLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                                gap: "50px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="residenceOwn"
                                  name="residenceStatus"
                                  value="YES"
                                  checked={formData.residenceStatus === "YES"}
                                  onChange={handleRadioButtonChange}
                                  style={{ marginRight: "5px" }}
                                />
                                <label
                                  htmlFor="residenceOwn"
                                  style={{ margin: "0" }}
                                >
                                  Yes
                                </label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="residenceRent"
                                  name="residenceStatus"
                                  value="NO"
                                  checked={formData.residenceStatus === "NO"}
                                  onChange={handleRadioButtonChange}
                                  style={{ marginRight: "5px" }}
                                />
                                <label
                                  htmlFor="residenceRent"
                                  style={{ margin: "0" }}
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* Ownership Verified Through Neighbour Field */}
                          <div
                            className="form-group mt-2"
                            style={{ marginBottom: "-5px" }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <label
                                htmlFor="ownershipVerified"
                                style={{
                                  marginRight: "15px",
                                  marginBottom: "0",
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                }}
                              >
                                Ownership Verified Through Neighbour:
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "50px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="YES"
                                    name="ownershipVerified" // This should match the state key
                                    value="YES" // Correct value here
                                    checked={
                                      formData.ownershipVerified === "YES"
                                    } // Correct checked logic
                                    onChange={handleRadioButtonChange}
                                    style={{
                                      marginRight: "5px",
                                      transform: "scale(1.0)", // Further reduce the size
                                    }}
                                  />
                                  <label htmlFor="YES" style={{ margin: "0" }}>
                                    Unsatisfactory
                                  </label>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="NO"
                                    name="ownershipVerified" // This should match the state key
                                    value="NO" // Correct value here
                                    checked={
                                      formData.ownershipVerified === "NO"
                                    } // Correct checked logic
                                    onChange={handleRadioButtonChange}
                                    style={{
                                      marginRight: "5px",
                                      transform: "scale(1.0)", // Further reduce the size
                                    }}
                                  />
                                  <label htmlFor="NO" style={{ margin: "0" }}>
                                    Satisfactory
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* End Use of Loan */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="endUseOfLoan"
                              style={{
                                marginRight: "15px",
                                marginBottom: "0",
                                whiteSpace: "nowrap",
                              }}
                            >
                              End Use of Loan:
                            </label>
                            <select
                              id="endUseOfLoan"
                              name="endUseOfLoan"
                              value={formData.endUseOfLoan}
                              onChange={handleSelectChange}
                              style={{ width: "50%", padding: "5px" }}
                            >
                              <option value="">Select an option</option>
                              <option value="Cow Purchase">Cow Purchase</option>
                              <option value="Buffalo Purchase">
                                Buffalo Purchase
                              </option>
                              <option value="Different Livestock Purchase">
                                Different Livestock Purchase
                              </option>
                              <option value="Fodder For Livestock Purchase">
                                Fodder for Livestock Purchase
                              </option>
                              <option value="Sewing Machine Purchase">
                                Sewing Machine Purchase
                              </option>
                              <option value="Crop Purchase">
                                Crop Purchase
                              </option>
                              <option value="Additional Stock Purchase">
                                Additional Stock Purchase
                              </option>
                              <option value="Additional Equipment Inventory Purchase">
                                Additional Equipment / Inventory Purchase
                              </option>
                              <option value="ShopSize Enhancement">
                                Shop Size Enhancement
                              </option>
                              <option value="SettingUp Additional BusinessLine">
                                Setting up Additional Business Line
                              </option>
                              <option value="Raw Material Purchase">
                                Raw Material Purchase
                              </option>
                              <option value="Daily Working Capital Use">
                                Daily Working Capital Use
                              </option>
                              <option value="Other">Other</option>
                            </select>
                          </div>

                          {/* Neighbour Feedback Field */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <label
                              htmlFor="neighbourStatus"
                              style={{
                                marginRight: "15px",
                                marginBottom: "0",
                                whiteSpace: "nowrap", // Ensure label doesn't wrap to the next line
                              }}
                            >
                              Neighbour Feedback:
                            </label>
                            <select
                              id="neighbourStatus"
                              name="neighbourStatus"
                              value={formData.neighbourStatus}
                              onChange={handleSelectChange}
                              style={{ width: "50%", padding: "5px" }}
                            >
                              <option value="">Select</option>
                              <option value="Positive">Positive</option>
                              <option value="Negative">Negative</option>
                            </select>
                          </div>

                          {/* Neighbour Feedback */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="ownershipVerified"
                              style={{
                                marginRight: "15px",
                                marginBottom: "0",
                                whiteSpace: "nowrap", // Prevent text from wrapping
                              }}
                            >
                              Neighbour Feedback:
                            </label>
                            <input
                              type="text"
                              id="neighbourFeedback1"
                              name="neighbourFeedback1"
                              placeholder="Please Enter Neighbour Feedback"
                              value={formData.neighbourFeedback1}
                              onChange={handleInputChange}
                              style={{ flex: "1" }}
                            />
                          </div>

                          {/* Client Feedback Field */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <label
                              htmlFor="clientStatus"
                              style={{
                                marginRight: "15px",
                                marginBottom: "0",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Client Feedback:
                            </label>
                            <select
                              id="clientStatus"
                              name="clientStatus"
                              value={formData.clientStatus}
                              onChange={handleSelectChange}
                              style={{
                                width: "100%",
                                padding: "5px",
                                marginLeft: "",
                              }}
                            >
                              <option value="">Select</option>
                              <option value="Positive">Positive</option>
                              <option value="Negative">Negative</option>
                            </select>
                          </div>

                          {/* Client Feedback */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="clientFeedback1"
                              style={{ width: "150px" }}
                            >
                              Client Feedback:
                            </label>
                            <input
                              type="text"
                              id="clientFeedback1"
                              name="clientFeedback1"
                              placeholder="Please Enter Client Feedback"
                              value={formData.clientFeedback1}
                              onChange={handleInputChange}
                              style={{ flex: "1" }}
                            />
                          </div>
                        </div>
                      )}

                      {/* Business Verification  */}

                      <div
                        className="form-group mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <h5
                          style={{
                            margin: 0,
                            flex: "1",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#02CCFE",
                          }}
                        >
                          Business Verification
                        </h5>
                        <button
                          type="button"
                          onClick={handleToggleBusinessDiv}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          {isBusinessDivOpen ? "-" : "+"}
                        </button>
                      </div>

                      {/* Existing Business Name Select */}
                      {isBusinessDivOpen && (
                        <div
                          className="emi-form"
                          style={{
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="businessName"
                              style={{
                                width: "150px",
                              }}
                            >
                              Existing Business:
                            </label>
                            <select
                              id="businessName"
                              name="selectedBusinessName"
                              value={formData.selectedBusinessName}
                              onChange={handleInputChange}
                              style={{ flex: "1", padding: "5px" }}
                            >
                              <option value="Select">Select</option>
                              <option value="Yes">Yes</option>
                              {/* <option value="No">No</option> */}
                            </select>
                          </div>

                          {formData.selectedBusinessName === "Yes" && (
                            <>
                              <div
                                className="form-group mt-2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <label
                                  htmlFor="businessLocationType"
                                  style={{
                                    marginRight: "15px",
                                    marginBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Business Location Type:
                                </label>
                                <select
                                  id="businessLocationType"
                                  name="businessLocationType"
                                  value={formData.businessLocationType}
                                  onChange={handleSelectChange}
                                  style={{ flex: "1", padding: "5px" }}
                                >
                                  <option value="">Select</option>
                                  <option value="Office Building">
                                    Office Building
                                  </option>
                                  <option value="Converted Flat">
                                    Converted Flat
                                  </option>
                                  <option value="Shop">Shop</option>
                                  <option value="Residence">Residence</option>
                                  <option value="Factory">Factory</option>
                                  <option value="Godown">Godown</option>
                                </select>
                              </div>

                              <div
                                className="form-group mt-2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <label
                                  htmlFor="businessVisibility"
                                  style={{
                                    marginRight: "15px",
                                    marginBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Business Visibility:
                                </label>
                                <select
                                  id="businessVisibility"
                                  name="businessVisibility"
                                  value={formData.businessVisibility}
                                  onChange={handleSelectChange}
                                  style={{ flex: "1", padding: "5px" }}
                                >
                                  <option value="">Select</option>
                                  <option value="Name Board">Name Board</option>
                                  <option value="Through Business Activity">
                                    Through Business Activity
                                  </option>
                                  <option value="Verbal neighbour Confirmation">
                                    Verbal Neighbour Confirmation
                                  </option>
                                  <option value="Visiting Card">
                                    Visiting Card
                                  </option>
                                </select>
                              </div>

                              <div
                                className="form-group mt-2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <label
                                  htmlFor="levelOfActivity"
                                  style={{
                                    marginRight: "15px",
                                    marginBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Level of Activity (Relevant to Business and
                                  Based on CPV Timing):
                                </label>
                                <select
                                  id="levelOfActivity"
                                  name="levelOfActivity"
                                  value={formData.levelOfActivity}
                                  onChange={handleSelectChange}
                                  style={{ flex: "1", padding: "5px" }}
                                >
                                  <option value="">Select</option>
                                  <option value="Very Busy">Very Busy</option>
                                  <option value="Busy">Busy</option>
                                  <option value="Average">Average</option>
                                  <option value="Poor">Poor</option>
                                </select>
                              </div>

                              <div
                                className="form-group mt-2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <label
                                  htmlFor="stocks"
                                  style={{
                                    marginRight: "15px",
                                    marginBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Stocks (Relevant to Business Only):
                                </label>
                                <select
                                  id="stocks"
                                  name="stocks"
                                  value={formData.stocks}
                                  onChange={handleSelectChange}
                                  style={{ flex: "1", padding: "5px" }}
                                >
                                  <option value="">Select</option>
                                  <option value="Heavy">Heavy</option>
                                  <option value="Average">Average</option>
                                  <option value="Low">Low</option>
                                  <option value="No Stock">No Stock</option>
                                </select>
                              </div>

                              <div
                                className="form-group mt-2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <label
                                  htmlFor="peakBusinessHours"
                                  style={{
                                    marginRight: "15px",
                                    marginBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Peak Business Hours:
                                </label>
                                <select
                                  id="peakBusinessHours"
                                  name="peakBusinessHours"
                                  value={formData.peakBusinessHours}
                                  onChange={handleSelectChange}
                                  style={{ flex: "1", padding: "5px" }}
                                >
                                  <option value="">Select</option>
                                  <option value="Morning">Morning</option>
                                  <option value="Afternoon">Afternoon</option>
                                  <option value="Evening">Evening</option>
                                  <option value="Not Applicable">
                                    Not Applicable
                                  </option>
                                </select>
                              </div>

                              <div
                                className="form-group mt-2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <label
                                  htmlFor="marketFeedback"
                                  style={{
                                    marginRight: "15px",
                                    marginBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Market Feedback:
                                </label>
                                <select
                                  id="marketFeedback"
                                  name="marketFeedback"
                                  value={formData.marketFeedback}
                                  onChange={handleSelectChange}
                                  style={{ flex: "1", padding: "5px" }}
                                >
                                  <option value="">Select</option>
                                  <option value="veryGood">Very Good</option>
                                  <option value="Good">Good</option>
                                  <option value="Fair">Fair</option>
                                  <option value="Poor">Poor</option>
                                </select>
                              </div>
                              <div
                                className="form-group mt-2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <label
                                  htmlFor="businessVerified"
                                  style={{
                                    marginRight: "15px",
                                    marginBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Business/Employment Verified:
                                </label>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "50px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      id="yesBusinessVerified"
                                      name="businessVerified"
                                      value="YES"
                                      checked={
                                        formData.businessVerified === "YES"
                                      }
                                      onChange={handleRadioButtonChange}
                                      style={{
                                        marginRight: "5px",
                                        transform: "scale(1.0)",
                                      }}
                                    />
                                    <label
                                      htmlFor="yesBusinessVerified"
                                      style={{ margin: "0" }}
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      id="noBusinessVerified"
                                      name="businessVerified"
                                      value="NO"
                                      checked={
                                        formData.businessVerified === "NO"
                                      }
                                      onChange={handleRadioButtonChange}
                                      style={{
                                        marginRight: "5px",
                                        transform: "scale(1.0)",
                                      }}
                                    />
                                    <label
                                      htmlFor="noBusinessVerified"
                                      style={{ margin: "0" }}
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="form-group mt-2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <label
                                  htmlFor="yearsInBusiness"
                                  style={{
                                    marginRight: "15px",
                                    marginBottom: "0",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  No. of Years in Business/Employment:
                                </label>
                                <input
                                  type="text"
                                  id="yearsInBusiness"
                                  name="yearsInBusiness"
                                  value={formData.yearsInBusiness}
                                  onChange={handleInputChange}
                                  style={{ flex: "1", padding: "5px" }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {/* household-income-section */}
                      <div className="household-income-section">
                        <div
                          className="form-group mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              flex: "1",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#02CCFE",
                            }}
                          >
                            Household Monthly Income
                          </h5>
                          <button
                            type="button"
                            onClick={toggleHouseholdIncomeForm}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              //fontSize: '20px',
                            }}
                          >
                            {isHouseholdIncomeFormOpen ? "-" : "+"}
                          </button>
                        </div>
                        {isHouseholdIncomeFormOpen && (
                          <div
                            className="emi-form"
                            style={{
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              backgroundColor: "#f9f9f9",
                              marginBottom: "20px",
                            }}
                          >
                            <div>
                              <table
                                style={{
                                  width: "100%",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                        textAlign: "center",
                                        backgroundColor: "#02CCFE",
                                      }}
                                    >
                                      Nature of Business
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                        textAlign: "center",
                                        backgroundColor: "#02CCFE",
                                      }}
                                    >
                                      Scale of Business
                                    </th>
                                    <th
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                        textAlign: "center",
                                        backgroundColor: "#02CCFE",
                                      }}
                                    >
                                      Business Margin
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {natureArray.map((nature, index) => (
                                    <tr key={index}>
                                      <td
                                        style={{
                                          border: "1px solid #ccc",
                                          padding: "8px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {nature}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid #ccc",
                                          padding: "8px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {scaleArray[index] || "Not Selected"}
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid #ccc",
                                          padding: "8px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {marginArray[index] || "Not Selected"}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            {/* Income Display */}
                            <div
                              className="form-group mt-2"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span style={{ flexGrow: 1 }}></span>
                              <span style={{ textAlign: "right" }}>
                                Income: ₹{formData.initialIncome}
                              </span>
                            </div>

                            {/* Display Form Fields */}
                            {showForms && (
                              <>
                                {businessForms.map((form, index) => (
                                  <div
                                    key={index}
                                    className="business-form-entry"
                                    style={{ marginBottom: "20px" }}
                                  >
                                    {/* Remove Button */}
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        onClick={() =>
                                          removeBusinessForm(index)
                                        }
                                        style={{
                                          background: "none",
                                          border: "none",
                                          color: "#FF0000",
                                          fontSize: "16px",
                                          cursor: "pointer",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        X
                                      </button>
                                    </div>
                                    <div
                                      className="business-form-fields"
                                      style={{
                                        border: "1px solid #ddd",
                                        borderRadius: "4px",
                                        padding: "10px",
                                        backgroundColor: "#fff",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      {/* Form Fields */}
                                      <div
                                        className="form-group mt-2"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <label
                                          htmlFor={`natureOfBusiness_${index}`}
                                          style={{
                                            marginRight: "15px",
                                            marginBottom: "0",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          Nature of Business:
                                        </label>
                                        <select
                                          id={`natureOfBusiness_${index}`}
                                          name="natureOfBusiness"
                                          value={form.natureOfBusiness || ""}
                                          onChange={(e) =>
                                            handleBusinessInputChange(e, index)
                                          }
                                          style={{ flex: "1" }}
                                        >
                                          <option value="">Select</option>
                                          {natureOfBusinessOptions.map(
                                            (option) => (
                                              <option
                                                key={option.purposeid}
                                                value={option.purposeid}
                                              >
                                                {option.loanpurpose}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                      {/* Additional form fields for scale and margin */}
                                      <div
                                        className="form-group mt-2"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <label
                                          htmlFor={`scaleOfBusiness_${index}`}
                                          style={{
                                            marginRight: "15px",
                                            marginBottom: "0",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          Scale of Business:
                                        </label>
                                        <select
                                          id={`scaleOfBusiness_${index}`}
                                          name="scaleOfBusiness"
                                          value={form.scaleOfBusiness || ""}
                                          onChange={(e) =>
                                            handleBusinessInputChange(e, index)
                                          }
                                          style={{ flex: "1" }}
                                        >
                                          <option value="">Select</option>
                                          <option value="High">High</option>
                                          <option value="Medium">Medium</option>
                                          <option value="Low">Low</option>
                                        </select>
                                      </div>
                                      <div
                                        className="form-group mt-2"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <label
                                          htmlFor={`businessMargin_${index}`}
                                          style={{
                                            marginRight: "15px",
                                            marginBottom: "0",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          Business Margin:
                                        </label>
                                        <select
                                          id={`businessMargin_${index}`}
                                          name="businessMargin"
                                          value={form.businessMargin || ""}
                                          onChange={(e) =>
                                            handleBusinessInputChange(e, index)
                                          }
                                          style={{ flex: "1" }}
                                        >
                                          <option value="">Select</option>
                                          <option value="High">High</option>
                                          <option value="Medium">Medium</option>
                                          <option value="Low">Low</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div
                                  className="form-group mt-2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span style={{ flexGrow: 1 }}></span>
                                  <span style={{ textAlign: "right" }}>
                                    Income: ₹{formData.totalIncome}
                                  </span>
                                </div>
                              </>
                            )}

                            {/* Add More Business Button */}
                            <button
                              type="button"
                              onClick={addBusinessForm}
                              style={{
                                padding: "10px 20px",
                                backgroundColor: "#02CCFE",
                                color: "#fff",
                                border: "none",
                                borderRadius: "4px",
                                cursor: "pointer",
                              }}
                            >
                              + Add More Business
                            </button>
                          </div>
                        )}
                      </div>

                      {/* Monthly Expenses */}
                      <div className="monthly-expenses-section">
                        <div
                          className="form-group mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              flex: "1",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#02CCFE",
                            }}
                          >
                            Monthly Expenses
                          </h5>
                          <button
                            type="button"
                            onClick={toggleExpensesForm}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            {isExpensesFormOpen ? "-" : "+"}
                          </button>
                        </div>
                      </div>

                      {isExpensesFormOpen && (
                        <div
                          className="emi-form"
                          style={{
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="familyMembers"
                              style={{
                                marginRight: "20px",
                                whiteSpace: "nowrap",
                                width: "200px",
                              }}
                            >
                              Number of Family Member:
                            </label>
                            <input
                              type="number"
                              id="familyMembers"
                              name="familyMembers"
                              placeholder="Enter number of family member"
                              value={formData.familyMembers}
                              onChange={handleInputChange}
                              style={{ flex: "1" }}
                            />
                          </div>
                        </div>
                      )}

                      {/* borrower-emi-section */}
                      <div className="borrower-emi-section">
                        <div
                          className="form-group mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              flex: "1",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#02CCFE",
                            }}
                          >
                            Borrower EMI
                          </h5>
                          <button
                            type="button"
                            onClick={toggleEMIForm}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            {isEMIFormOpen ? "-" : "+"}
                          </button>
                        </div>

                        {isEMIFormOpen && (
                          <div
                            className="emi-form"
                            style={{
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            <table
                              style={{
                                width: "100%",
                                borderCollapse: "collapse",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#02CCFE",
                                    }}
                                  >
                                    Lender Name
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#02CCFE",
                                    }}
                                  >
                                    Open Date
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#02CCFE",
                                    }}
                                  >
                                    Loan Amt
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#02CCFE",
                                    }}
                                  >
                                    Current Bal
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#02CCFE",
                                    }}
                                  >
                                    EMI
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {emiData.map((emi) => (
                                  <tr key={emi.id}>
                                    <td
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        placeholder="Enter Lender Name"
                                        value={emi.lenderName || ""}
                                        onChange={
                                          (e) =>
                                            handleEmiChange(
                                              e,
                                              emi.id,
                                              "lenderName"
                                            ) // Use emi.id as identifier
                                        }
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          boxSizing: "border-box",
                                          padding: "4px",
                                        }}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {emi.entryDate || "-"}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {emi.loanAmount || "-"}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {emi.currentBalance || "-"}{" "}
                                      {/* Display "-" if currentBalance is not available */}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                      }}
                                    >
                                      <input
                                        type="number"
                                        placeholder="Enter EMI"
                                        value={emi.emi || emi.lenderEmi || ""}
                                        onChange={
                                          (e) =>
                                            handleEmiChange(e, emi.id, "emi") // Use emi.id as identifier
                                        }
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          boxSizing: "border-box",
                                          padding: "4px",
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                            {/* Additional Fields */}
                            {showFields && (
                              <div
                                className="emi-form"
                                style={{
                                  padding: "10px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  backgroundColor: "#f9f9f9",
                                }}
                              >
                                {fields.map((field, index) => (
                                  <div
                                    style={{
                                      marginTop: "20px",
                                      position: "relative",
                                    }}
                                    key={index}
                                  >
                                    {/* Remove Button Above Lender Name */}
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        onClick={() => removeLender(index)}
                                        style={{
                                          background: "none",
                                          border: "none",
                                          color: "#FF0000",
                                          fontSize: "16px",
                                          cursor: "pointer",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        X
                                      </button>
                                    </div>

                                    {/* Lender Name Input Field */}
                                    <div style={{ marginBottom: "10px" }}>
                                      <input
                                        type="text"
                                        placeholder="Enter Lender Name"
                                        value={field.lenderName}
                                        onChange={(e) =>
                                          handleFieldChange(
                                            e,
                                            index,
                                            "lenderName"
                                          )
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "8px",
                                          marginBottom: "10px",
                                          borderRadius: "4px",
                                          border: "1px solid #ccc",
                                          boxSizing: "border-box",
                                        }}
                                      />
                                    </div>

                                    {/* EMI Input Field */}
                                    <div style={{ marginBottom: "10px" }}>
                                      <input
                                        type="number"
                                        placeholder="Enter EMI"
                                        value={field.emi}
                                        onChange={(e) =>
                                          handleFieldChange(e, index, "emi")
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "8px",
                                          borderRadius: "4px",
                                          border: "1px solid #ccc",
                                          boxSizing: "border-box",
                                        }}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}

                            {/* Add More Lender Button */}
                            <div
                              style={{ textAlign: "right", marginTop: "10px" }}
                            >
                              <button
                                type="button"
                                onClick={addMoreLender}
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "#0288D1",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                + Add More Lender
                              </button>
                            </div>

                            {/* Total EMI Section */}
                            <div
                              style={{
                                textAlign: "right",
                                marginTop: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              <span>Total EMI: ₹{totalEmi.toFixed(2)}</span>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Coborrower-emi-section */}
                      <div className="co-borrower-emi-section">
                        <div
                          className="form-group mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              flex: "1",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#02CCFE",
                            }}
                          >
                            Co-Borrower EMI
                          </h5>
                          <button
                            type="button"
                            onClick={toggleCoBorrowerEMIForm}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            {isCoBorrowerEMIFormOpen ? "-" : "+"}
                          </button>
                        </div>

                        {isCoBorrowerEMIFormOpen && (
                          <div
                            className="emi-form"
                            style={{
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            <table
                              style={{
                                width: "100%",
                                borderCollapse: "collapse",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#02CCFE",
                                    }}
                                  >
                                    Lender Name
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#02CCFE",
                                    }}
                                  >
                                    Open Date
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#02CCFE",
                                    }}
                                  >
                                    Loan Amt
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#02CCFE",
                                    }}
                                  >
                                    Current Bal
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid #ccc",
                                      padding: "8px",
                                      textAlign: "center",
                                      backgroundColor: "#02CCFE",
                                    }}
                                  >
                                    EMI
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {coborrowerEmiData.map((emi) => (
                                  <tr key={emi.id}>
                                    <td
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        placeholder="Enter Lender Name"
                                        value={emi.lenderName || ""}
                                        onChange={(e) =>
                                          handleCoborrowerEmiChange(
                                            e,
                                            emi.id,
                                            "lenderName"
                                          )
                                        }
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          boxSizing: "border-box",
                                          padding: "4px",
                                        }}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {emi.entryDate || "-"}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {emi.loanAmount || "-"}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {emi.currentBalance || "-"}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid #ccc",
                                        padding: "8px",
                                      }}
                                    >
                                      <input
                                        type="number"
                                        placeholder="Enter EMI"
                                        value={emi.emi || emi.lenderEmi || ""}
                                        onChange={(e) =>
                                          handleCoborrowerEmiChange(
                                            e,
                                            emi.id,
                                            "emi"
                                          )
                                        }
                                        style={{
                                          width: "100%",
                                          border: "none",
                                          boxSizing: "border-box",
                                          padding: "4px",
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                            {showCoborrowerFields && (
                              <div
                                className="emi-form"
                                style={{
                                  padding: "10px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  backgroundColor: "#f9f9f9",
                                }}
                              >
                                {coborrowerFields.map((field, index) => (
                                  <div
                                    style={{
                                      marginTop: "20px",
                                      position: "relative",
                                    }}
                                    key={index}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        onClick={() =>
                                          removeCoborrowerLender(index)
                                        }
                                        style={{
                                          background: "none",
                                          border: "none",
                                          color: "#FF0000",
                                          fontSize: "16px",
                                          cursor: "pointer",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        X
                                      </button>
                                    </div>

                                    <div style={{ marginBottom: "10px" }}>
                                      <input
                                        type="text"
                                        placeholder="Enter Lender Name"
                                        value={field.lenderName}
                                        onChange={(e) =>
                                          handleCoborrowerFieldChange(
                                            e,
                                            index,
                                            "lenderName"
                                          )
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "8px",
                                          marginBottom: "10px",
                                          borderRadius: "4px",
                                          border: "1px solid #ccc",
                                          boxSizing: "border-box",
                                        }}
                                      />
                                    </div>

                                    <div style={{ marginBottom: "10px" }}>
                                      <input
                                        type="number"
                                        placeholder="Enter EMI"
                                        value={field.emi}
                                        onChange={(e) =>
                                          handleCoborrowerFieldChange(
                                            e,
                                            index,
                                            "emi"
                                          )
                                        }
                                        style={{
                                          width: "100%",
                                          padding: "8px",
                                          borderRadius: "4px",
                                          border: "1px solid #ccc",
                                          boxSizing: "border-box",
                                        }}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}

                            <div
                              style={{ textAlign: "right", marginTop: "10px" }}
                            >
                              <button
                                type="button"
                                onClick={addMoreCoborrowerLender}
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "#0288D1",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                + Add More Lender
                              </button>
                            </div>

                            <div
                              style={{
                                textAlign: "right",
                                marginTop: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              <span>
                                Total EMI: ₹{totalCoborrowerEmi.toFixed(2)}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Disposable Income Section */}
                      <div className="disposable-income-section">
                        <div
                          className="form-group mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              flex: "1",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#02CCFE",
                            }}
                          >
                            Disposable Income
                          </h5>
                          <button
                            type="button"
                            onClick={toggleDisposableIncomeForm}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            {isDisposableIncomeFormOpen ? "-" : "+"}
                          </button>
                        </div>

                        {isDisposableIncomeFormOpen && (
                          <div
                            className="disposable-income-form"
                            style={{
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              {/* View Income Label */}
                              <span
                                style={{
                                  color: "#1533CF", // Blue color
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                }}
                              >
                                View Income
                              </span>

                              {/* Total EMI (Borrower + Co-Borrower) with '-' Sign */}
                              <span
                                style={{
                                  color: "#000", // Default text color
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  marginRight: "30px",
                                }}
                              >
                                {disposableIncome.toFixed(2)} ₹
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* Monthly Income & Expenses Section */}
                      <div className="monthly-income-section">
                        <div
                          className="form-group mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              flex: "1",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#02CCFE",
                            }}
                          >
                            Monthly Income & Expenses
                          </h5>
                          <button
                            type="button"
                            onClick={toggleMonthlyIncomeExpensesForm}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            {isMonthlyIncomeExpensesFormOpen ? "-" : "+"}
                          </button>
                        </div>

                        {isMonthlyIncomeExpensesFormOpen && (
                          <div
                            className="monthly-income-form"
                            style={{
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                                marginBottom: "10px",
                              }}
                            >
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name="incomeSource"
                                  value="cowRearing"
                                  style={{ marginRight: "5px" }}
                                  onChange={handleCheckboxChange}
                                />
                                Cow Rearing
                              </label>
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name="incomeSource"
                                  value="agriculture"
                                  style={{ marginRight: "5px" }}
                                  onChange={handleCheckboxChange}
                                />
                                Agriculture
                              </label>
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name="incomeSource"
                                  value="buffaloRearing"
                                  style={{ marginRight: "5px" }}
                                  onChange={handleCheckboxChange}
                                />
                                Buffalo Rearing
                              </label>
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name="incomeSource"
                                  value="salaryWork"
                                  style={{ marginRight: "5px" }}
                                  onChange={handleCheckboxChange}
                                />
                                Salary or Majdoor Work
                              </label>
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  name="incomeSource"
                                  value="tailoringBusiness"
                                  style={{ marginRight: "5px" }}
                                  onChange={handleCheckboxChange}
                                />
                                Tailoring Business
                              </label>
                            </div>

                            {isCowRearingSelected && (
                              <div
                                className="cow-rearing-form"
                                style={{
                                  padding: "20px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  backgroundColor: "#ffffff",
                                  marginTop: "20px",
                                  textAlign: "center",
                                }}
                              >
                                <h6
                                  style={{
                                    marginBottom: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Cow Rearing
                                </h6>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Number of Milking Cows:
                                  </label>
                                  <input
                                    type="number"
                                    name="milkingCows"
                                    value={formData.milkingCows}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Average Litres per Day:
                                  </label>
                                  <input
                                    type="number"
                                    name="litresPerDay"
                                    value={formData.litresPerDay}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Average Selling Price per Litre:
                                  </label>
                                  <input
                                    type="number"
                                    name="sellingPricePerLitre"
                                    value={formData.sellingPricePerLitre}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Average Expenses per Month:
                                  </label>
                                  <input
                                    type="number"
                                    name="expensesPerMonth"
                                    value={formData.expensesPerMonth}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Income per Month:
                                  </label>
                                  <input
                                    type="number"
                                    name="incomePerMonth"
                                    value={formData.incomePerMonth}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {isAgricultureSelected && (
                              <div
                                className="agriculture-form"
                                style={{
                                  padding: "20px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  backgroundColor: "#ffffff",
                                  marginTop: "20px",
                                  textAlign: "center",
                                }}
                              >
                                <h6
                                  style={{
                                    marginBottom: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Agriculture
                                </h6>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Number of Acres:
                                  </label>
                                  <input
                                    type="number"
                                    name="numberOfAcres"
                                    value={formData.numberOfAcres}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Average Income per Acre:
                                  </label>
                                  <input
                                    type="number"
                                    name="averageIncomePerAcre"
                                    value={formData.averageIncomePerAcre}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Income per Month:
                                  </label>
                                  <input
                                    type="number"
                                    name="incomePerMonth"
                                    value={formData.incomePerMonthag}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {isBuffaloRearingSelected && (
                              <div
                                className="Buffalo-rearing-form"
                                style={{
                                  padding: "20px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  backgroundColor: "#ffffff",
                                  marginTop: "20px",
                                  textAlign: "center",
                                }}
                              >
                                <h6
                                  style={{
                                    marginBottom: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Buffalo Rearing
                                </h6>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Number of Milking Buffalo:
                                  </label>
                                  <input
                                    type="number"
                                    name="milkingBuffalo"
                                    value={formData.milkingBuffalo}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Average Litres per Day:
                                  </label>
                                  <input
                                    type="number"
                                    name="litresPerDayBuffalo"
                                    value={formData.litresPerDayBuffalo}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Average Selling Price per Litre:
                                  </label>
                                  <input
                                    type="number"
                                    name="sellingPricePerLitreBuffalo"
                                    value={formData.sellingPricePerLitreBuffalo}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Average Expenses per Month:
                                  </label>
                                  <input
                                    type="number"
                                    name="expensesPerMonthBuffalo"
                                    value={formData.expensesPerMonthBuffalo}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Income per Month:
                                  </label>
                                  <input
                                    type="number"
                                    name="incomePerMonthBuffalo"
                                    value={formData.incomePerMonthBuffalo}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {isSalaryorMajdoorSelected && (
                              <div
                                className="Salary-Majdoor-form"
                                style={{
                                  padding: "20px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  backgroundColor: "#ffffff",
                                  marginTop: "20px",
                                  textAlign: "center",
                                }}
                              >
                                <h6
                                  style={{
                                    marginBottom: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Salary or Majdoor Work
                                </h6>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Number of days working in month:
                                  </label>
                                  <input
                                    type="number"
                                    name="noofdaysworkinginmonth"
                                    value={formData.noofdaysworkinginmonth}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Average Income per day:
                                  </label>
                                  <input
                                    type="number"
                                    name="incomeperdaymajdoor"
                                    value={formData.incomeperdaymajdoor}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Income per Month:
                                  </label>
                                  <input
                                    type="number"
                                    name="incomepermonthmajdoor"
                                    value={formData.incomepermonthmajdoor}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {isTailoringBusinessSelected && (
                              <div
                                className="Tailoring-Business-form"
                                style={{
                                  padding: "20px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  backgroundColor: "#ffffff",
                                  marginTop: "20px",
                                  textAlign: "center",
                                }}
                              >
                                <h6
                                  style={{
                                    marginBottom: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Tailoring Business
                                </h6>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Number of days working in month:
                                  </label>
                                  <input
                                    type="number"
                                    name="noofsewingmachines"
                                    value={formData.noofsewingmachines}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Average Income per day:
                                  </label>
                                  <input
                                    type="number"
                                    name="incomeperdaymachine"
                                    value={formData.incomeperdaymachine}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <label
                                    style={{
                                      marginRight: "30px",
                                      textAlign: "left",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Income per Month:
                                  </label>
                                  <input
                                    type="number"
                                    name="incomepermonthmachine"
                                    value={formData.incomepermonthmachine}
                                    onChange={handleInputChange}
                                    style={{
                                      padding: "4px",
                                      width: "100%",
                                      height: "30px",
                                    }}
                                  />
                                </div>
                              </div>
                            )}

                            {businessFormList.map((form, index) => (
                              <div
                                key={form.id}
                                className="other-business"
                                style={{
                                  padding: "10px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  backgroundColor: "#f9f9f9",
                                  marginBottom: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <button
                                    type="button"
                                    onClick={() =>
                                      removeBusinessFormother(form.id)
                                    }
                                    style={{
                                      background: "none",
                                      border: "none",
                                      color: "#FF0000",
                                      fontSize: "16px",
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    X
                                  </button>
                                </div>

                                <div
                                  className="business-summary"
                                  style={{
                                    padding: "20px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    marginTop: "20px",
                                    backgroundColor: "#f9f9f9",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        width: "200px",
                                      }}
                                    >
                                      Nature of Business:
                                    </span>
                                    <select
                                      name="natureOfBusiness"
                                      value={form.formData.natureOfBusiness}
                                      onChange={(e) =>
                                        handleInputChange(e, index)
                                      }
                                      style={{
                                        flex: 1,
                                        padding: "8px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      <option value="">
                                        Select Business Type
                                      </option>
                                      {filteredBusinessTypes.map((type) => (
                                        <option
                                          key={type.purposeid}
                                          value={type.loanpurpose}
                                        >
                                          {type.loanpurpose}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        width: "200px",
                                      }}
                                    >
                                      Business Revenue in Month:
                                    </span>
                                    <input
                                      name="businessRevenue"
                                      type="number"
                                      placeholder="Enter Revenue"
                                      value={form.formData.businessRevenue}
                                      onChange={(e) =>
                                        handleInputChange(e, index)
                                      }
                                      style={{
                                        flex: 1,
                                        padding: "8px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                      }}
                                    />
                                  </div>

                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        width: "200px",
                                      }}
                                    >
                                      Business Expenses:
                                    </span>
                                    <input
                                      name="businessExpenses"
                                      type="number"
                                      placeholder="Enter Expenses"
                                      value={form.formData.businessExpenses}
                                      onChange={(e) =>
                                        handleInputChange(e, index)
                                      }
                                      style={{
                                        flex: 1,
                                        padding: "8px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                      }}
                                    />
                                  </div>

                                  <div
                                    style={{
                                      marginBottom: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        width: "200px",
                                      }}
                                    >
                                      Business Income in Month:
                                    </span>
                                    <span
                                      style={{
                                        flex: 1,
                                        padding: "8px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      ₹ {form.formData.businessIncome || 0}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div
                              style={{ textAlign: "right", marginTop: "20px" }}
                            >
                              <button
                                type="button"
                                onClick={addBusinessFormother}
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "#007bff",
                                  color: "#fff",
                                  border: "none",
                                  borderRadius: "4px",
                                }}
                              >
                                + Add More Business
                              </button>
                            </div>

                            <div
                              className="financial-summary"
                              style={{
                                padding: "20px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                marginTop: "20px",
                                backgroundColor: "#f9f9f9",
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Total Household Monthly Income:
                                </span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "55px",
                                  }}
                                >
                                  ₹{" "}
                                  {Number(formData.incomePerMonth) +
                                    Number(formData.incomePerMonthag) +
                                    Number(formData.incomePerMonthBuffalo) +
                                    Number(formData.incomepermonthmajdoor) +
                                    Number(formData.incomepermonthmachine) +
                                    totalBusinessIncome}
                                </span>
                              </div>
                              <div
                                style={{
                                  marginBottom: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Total Household Disposable Income:
                                </span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "28px",
                                  }}
                                >
                                  ₹{" "}
                                  {Number(formData.incomePerMonth) +
                                    Number(formData.incomePerMonthag) +
                                    Number(formData.incomePerMonthBuffalo) +
                                    Number(formData.incomepermonthmajdoor) +
                                    Number(formData.incomepermonthmachine) +
                                    totalBusinessIncome -
                                    (totalEmi + totalCoborrowerEmi) -
                                    formData.familyMembers * 2000}
                                </span>
                              </div>
                              <div
                                style={{
                                  marginBottom: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Times Monthly Installment:
                                </span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "105px",
                                  }}
                                >
                                  {formData.timesMonthlyInstallment}
                                </span>
                              </div>
                              <div
                                style={{
                                  marginBottom: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  Remark:
                                </span>
                                <input
                                  id="remark"
                                  name="householdRemarks"
                                  type="text"
                                  placeholder="Please Enter Remark"
                                  value={formData.householdRemarks}
                                  onChange={handleInputChange}
                                  style={{
                                    flex: 1,
                                    padding: "8px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    minWidth: "0",
                                    marginLeft: "28px",
                                  }}
                                />
                              </div>
                            </div>

                            {/* <div
  className="financial-summary"
  style={{
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginTop: '20px',
    backgroundColor: '#f9f9f9',
  }}
>
  <div style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '10px' }}>
    HV Business Income Details
  </div>

  <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
    <span style={{ fontWeight: 'bold' }}>Income Details:</span>
    <span style={{ fontWeight: 'bold', marginLeft: '85px' }}>₹ 0</span>
  </div>

  <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
    <span style={{ fontWeight: 'bold' }}>Selfie With Client:</span>
    <a
      href="#"
      style={{ 
        marginLeft: 'auto', 
        color: '#007bff', 
       // textDecoration: 'underline',
        fontWeight: 'bold',marginLeft: '65px' 
      }}
    >
      View Photo
    </a>
  </div>
</div> */}
                          </div>
                        )}
                      </div>

                      {/* Impact Parameters Section */}
                      <div className="impact-parameters-section">
                        <div
                          className="form-group mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              flex: "1",
                              backgroundColor: "#02CCFE",
                            }}
                          >
                            Impact Parameters
                          </h5>
                          <button
                            type="button"
                            onClick={toggleImpactParametersForm}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            {isImpactParametersFormOpen ? "-" : "+"}
                          </button>
                        </div>
                        {isImpactParametersFormOpen && (
                          <div
                            className="impact-parameters-form"
                            style={{
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ marginBottom: "15px" }}
                            >
                              {/* <label htmlFor="noOfPeopleEmployed" style={{ marginBottom: '5px', display: 'block' }}>
        No of People Employed:
      </label> */}
                              <input
                                type="number"
                                id="noOfPeopleEmployed"
                                name="noOfPeopleEmployed"
                                placeholder="Enter number of people employed"
                                value={formData.noOfPeopleEmployed}
                                onChange={handleInputChange}
                                style={{
                                  width: "100%",
                                  padding: "8px",
                                  boxSizing: "border-box",
                                }}
                              />
                            </div>

                            <div
                              className="form-group"
                              style={{ marginBottom: "15px" }}
                            >
                              {/* <label htmlFor="noOfWomenEmployed" style={{ marginBottom: '5px', display: 'block' }}>
        No of Women Employed:
      </label> */}
                              <input
                                type="number"
                                id="noOfWomenEmployed"
                                name="noOfWomenEmployed"
                                placeholder="Enter number of women employed"
                                value={formData.noOfWomenEmployed}
                                onChange={handleInputChange}
                                style={{
                                  width: "100%",
                                  padding: "8px",
                                  boxSizing: "border-box",
                                }}
                              />
                            </div>
                            {/* First Generation Entrepreneur */}
                            <div
                              className="form-group mt-2"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <label
                                htmlFor="firstGenerationEntreprenure"
                                style={{
                                  marginRight: "15px",
                                  marginBottom: "0",
                                  whiteSpace: "nowrap", // Ensure label doesn't wrap to the next line
                                }}
                              >
                                Are you a first generation entrepreneur?
                              </label>
                              <select
                                id="firstGenerationEntreprenure"
                                name="firstGenerationEntreprenure"
                                value={formData.firstGenerationEntreprenure}
                                onChange={handleSelectChange}
                                style={{
                                  width: "100%",
                                  padding: "8px",
                                  boxSizing: "border-box",
                                }}
                              >
                                <option value="">Select an option</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>

                            {/* New To Credit (From Cibil Report) */}
                            <div
                              className="form-group mt-2"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <label
                                htmlFor="newToCredit"
                                style={{
                                  marginRight: "15px",
                                  marginBottom: "0",
                                  whiteSpace: "nowrap", // Ensure label doesn't wrap to the next line
                                }}
                              >
                                New To Credit (From Cibil Report):
                              </label>
                              <select
                                id="newToCredit"
                                name="newToCredit"
                                value={formData.newToCredit}
                                onChange={handleSelectChange}
                                style={{
                                  width: "100%",
                                  padding: "8px",
                                  boxSizing: "border-box",
                                }}
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="N/A">N/A</option>
                              </select>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Reference Section */}
                      <div className="reference-section">
                        <div
                          className="form-group mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              flex: "1",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#02CCFE",
                            }}
                          >
                            Reference
                          </h5>
                          <button
                            type="button"
                            onClick={() =>
                              setIsReferenceFormOpen(!isReferenceFormOpen)
                            }
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            {isReferenceFormOpen ? "-" : "+"}
                          </button>
                        </div>
                        {isReferenceFormOpen && (
                          <div
                            className="reference-form"
                            style={{
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            {/* First Reference Section */}
                            <div
                              className="first-reference-section"
                              style={{ textAlign: "center" }}
                            >
                              <h6
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "black",
                                  margin: "20px 0",
                                }}
                              >
                                First Reference
                              </h6>

                              {/* 1st Reference Name */}
                              <div
                                className="form-group"
                                style={{ marginBottom: "15px" }}
                              >
                                <input
                                  type="text"
                                  id="referenceName"
                                  name="referenceName"
                                  placeholder="Enter 1st reference name"
                                  value={formData.referenceName}
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                  }}
                                />
                              </div>

                              {/* Relationship */}
                              <div
                                className="form-group"
                                style={{ marginBottom: "15px" }}
                              >
                                <select
                                  id="referenceRelation"
                                  name="referenceRelation"
                                  value={formData.referenceRelation}
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  <option value="">Select Relationship</option>
                                  <option value="Father">Father</option>
                                  <option value="Father-in-law">
                                    Father-in-law
                                  </option>
                                  <option value="Brother-in-law">
                                    Brother-in-law
                                  </option>
                                  <option value="Jija">Jija</option>
                                  <option value="Saala">Saala</option>
                                  <option value="Taya">Taya</option>
                                  <option value="Dada">Dada</option>
                                  <option value="Brother">Brother</option>
                                  <option value="Sister">Sister</option>
                                  <option value="Chacha">Chacha</option>
                                  <option value="Mama">Mama</option>
                                  <option value="Wife">Wife</option>
                                  <option value="Husband">Husband</option>
                                  <option value="Mother">Mother</option>
                                  <option value="Neighbour">Neighbour</option>
                                </select>
                              </div>

                              {/* 1st Reference Mob No */}
                              <div
                                className="form-group"
                                style={{ marginBottom: "15px" }}
                              >
                                <input
                                  type="text"
                                  id="referenceMobNo"
                                  name="referenceMobNo"
                                  placeholder="Enter 1st reference mobile number"
                                  value={formData.referenceMobNo}
                                  maxLength="10"
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                  }}
                                />
                                {errors.referenceMobNo && (
                                  <span style={{ color: "red" }}>
                                    {errors.referenceMobNo}
                                  </span>
                                )}
                              </div>

                              {/* Reference Feedback */}
                              <div
                                className="form-group"
                                style={{ marginBottom: "15px" }}
                              >
                                <textarea
                                  id="referenceFeedback"
                                  name="referenceFeedback"
                                  placeholder="Enter reference feedback"
                                  value={formData.referenceFeedback}
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                    minHeight: "60px",
                                  }}
                                />
                              </div>
                            </div>

                            {/* Second Reference Section */}
                            <div
                              className="second-reference-section"
                              style={{ textAlign: "center" }}
                            >
                              <h6
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "black",
                                  margin: "20px 0",
                                }}
                              >
                                Second Reference
                              </h6>

                              {/* 2nd Reference Name */}
                              <div
                                className="form-group"
                                style={{ marginBottom: "15px" }}
                              >
                                <input
                                  type="text"
                                  id="secondReferenceName"
                                  name="secondReferenceName"
                                  placeholder="Enter 2nd reference name"
                                  value={formData.secondReferenceName}
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                  }}
                                />
                              </div>

                              {/* Relationship */}
                              <div
                                className="form-group"
                                style={{ marginBottom: "15px" }}
                              >
                                <select
                                  id="secondReferenceRelation"
                                  name="secondReferenceRelation"
                                  value={formData.secondReferenceRelation}
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  <option value="">Select Relationship</option>
                                  <option value="Father">Father</option>
                                  <option value="Father-in-law">
                                    Father-in-law
                                  </option>
                                  <option value="Brother-in-law">
                                    Brother-in-law
                                  </option>
                                  <option value="Jija">Jija</option>
                                  <option value="Saala">Saala</option>
                                  <option value="Taya">Taya</option>
                                  <option value="Dada">Dada</option>
                                  <option value="Brother">Brother</option>
                                  <option value="Sister">Sister</option>
                                  <option value="Chacha">Chacha</option>
                                  <option value="Mama">Mama</option>
                                  <option value="Wife">Wife</option>
                                  <option value="Husband">Husband</option>
                                  <option value="Mother">Mother</option>
                                  <option value="Neighbour">Neighbour</option>
                                  {/* Add other options as needed */}
                                </select>
                              </div>

                              {/* 2nd Reference Mob No */}
                              <div
                                className="form-group"
                                style={{ marginBottom: "15px" }}
                              >
                                <input
                                  type="text"
                                  id="secondReferenceMobNo"
                                  name="secondReferenceMobNo"
                                  placeholder="Enter 2nd reference mobile number"
                                  value={formData.secondReferenceMobNo}
                                  maxLength="10"
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                  }}
                                />
                                {errors.secondReferenceMobNo && (
                                  <span style={{ color: "red" }}>
                                    {errors.secondReferenceMobNo}
                                  </span>
                                )}
                              </div>

                              {/* Reference Feedback */}
                              <div
                                className="form-group"
                                style={{ marginBottom: "15px" }}
                              >
                                <textarea
                                  id="secondReferenceFeedback"
                                  name="secondReferenceFeedback"
                                  placeholder="Enter 2nd reference feedback"
                                  value={formData.secondReferenceFeedback}
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                    minHeight: "60px",
                                  }}
                                />
                              </div>
                            </div>

                            {/* Co-Borrower Relative Details Section */}
                            <div
                              className="co-borrower-relative-section"
                              style={{ textAlign: "center" }}
                            >
                              <h6
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "black",
                                  margin: "20px 0",
                                }}
                              >
                                Co-Borrower Relative Details
                              </h6>

                              {/* Co-Borrower Father/Brother Name */}
                              <div
                                className="form-group"
                                style={{ marginBottom: "15px" }}
                              >
                                <input
                                  type="text"
                                  id="coBorrowerRelativeName"
                                  name="coBorrowerRelativeName"
                                  placeholder="Enter Co-Borrower Father/Brother Name"
                                  value={formData.coBorrowerRelativeName}
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                  }}
                                />
                              </div>

                              {/* Co-Borrower Father/Brother Mobile Number */}
                              <div
                                className="form-group"
                                style={{ marginBottom: "15px" }}
                              >
                                <input
                                  type="text"
                                  id="coBorrowerRelativeMobNo"
                                  name="coBorrowerRelativeMobNo"
                                  placeholder="Enter Co-Borrower Father/Brother Mobile Number"
                                  value={formData.coBorrowerRelativeMobNo}
                                  maxLength="10"
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                  }}
                                />
                                {errors.coBorrowerRelativeMobNo && (
                                  <span style={{ color: "red" }}>
                                    {errors.coBorrowerRelativeMobNo}
                                  </span>
                                )}
                              </div>

                              {/* Co-Borrower Father/Brother Address */}
                              <div
                                className="form-group"
                                style={{ marginBottom: "15px" }}
                              >
                                <textarea
                                  id="coBorrowerRelativeAddress"
                                  name="coBorrowerRelativeAddress"
                                  placeholder="Enter Co-Borrower Father/Brother Address"
                                  value={formData.coBorrowerRelativeAddress}
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                    boxSizing: "border-box",
                                    minHeight: "60px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Family Members Approval To Take Loan Section */}
                      <div
                        className="form-group mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <h5
                          style={{
                            margin: 0,
                            flex: "1",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#02CCFE",
                          }}
                        >
                          Family Members Approval To Take Loan
                        </h5>
                        <button
                          type="button"
                          onClick={handleToggleFamilyApprovalDiv}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          {isFamilyApprovalDivOpen ? "-" : "+"}
                        </button>
                      </div>

                      {/* Family Members Approval Form */}
                      {isFamilyApprovalDivOpen && (
                        <div
                          className="family-approval-form"
                          style={{
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          {/* Spoken to */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="familyMemberApproval"
                              style={{
                                width: "150px",
                                // color:
                                //   formData.familyMemberApproval === "NO"
                                //     ? "red"
                                //     : "black", // Red when "No" is selected
                              }}
                            >
                              Spoken to:
                            </label>
                            <select
                              id="familyMemberApproval"
                              name="selectedFamilyMemberApproval"
                              value={formData.selectedFamilyMemberApproval}
                              onChange={handleInputChange}
                              style={{ flex: "1", padding: "5px" }}
                            >
                              <option value="">Select</option>
                              <option value="YES">Yes</option>
                              <option value="NO">No</option>
                            </select>
                          </div>

                          {/* Family Members Dropdown */}
                          {formData.selectedFamilyMemberApproval === "YES" && (
                            <div
                              className="form-group mt-2"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <select
                                id="familyMember"
                                name="selectedFamilyMember"
                                value={formData.selectedFamilyMember}
                                onChange={handleInputChange}
                                style={{ flex: "1", padding: "5px" }}
                              >
                                <option value="">
                                  Select Family Members Spoken To
                                </option>
                                <option value="Father">Father</option>
                                <option value="Mother">Mother</option>
                                <option value="Brother">Brother</option>
                                <option value="Son">Son</option>
                                <option value="Father-Brother">
                                  Father-Brother
                                </option>
                                <option value="Father-Mother">
                                  Father-Mother
                                </option>
                                <option value="Father-Mother-Brother">
                                  Father-Mother-Brother
                                </option>
                                <option value="Mother-Brother">
                                  Mother-Brother
                                </option>
                                <option value="Brother-Son">Brother-Son</option>
                              </select>
                            </div>
                          )}
                        </div>
                      )}

                      {/* Remark Section */}
                      <div
                        className="form-group mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <h5
                          style={{
                            margin: 0,
                            flex: "1",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#02CCFE",
                          }}
                        >
                          Remark
                        </h5>
                        <button
                          type="button"
                          onClick={handleToggleRemarkDiv}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          {isRemarkDivOpen ? "-" : "+"}
                        </button>
                      </div>

                      {/* Remark Dropdown and Textarea */}
                      {isRemarkDivOpen && (
                        <div
                          className="remark-form"
                          style={{
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          {/* Customer Willing To Take Loan */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-20px",
                            }}
                          >
                            <label
                              htmlFor="customerWilling"
                              style={{
                                width: "200px",
                                whiteSpace: "nowrap",
                                marginRight: "50px",
                              }}
                            >
                              Customer Willing To Take Loan:
                            </label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "50px",
                                marginLeft: "10px",
                              }}
                            >
                              <label
                                style={{
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="customerWillingYes"
                                  name="selectedCustomerWilling"
                                  value="YES"
                                  checked={
                                    formData.selectedCustomerWilling === "YES"
                                  }
                                  onChange={handleRadioButtonChange}
                                />
                                <span style={{ marginLeft: "5px" }}>Yes</span>
                              </label>
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="customerWillingNo"
                                  name="selectedCustomerWilling"
                                  value="NO"
                                  checked={
                                    formData.selectedCustomerWilling === "NO"
                                  }
                                  onChange={handleRadioButtonChange}
                                />
                                <span style={{ marginLeft: "5px" }}>No</span>
                              </label>
                            </div>
                          </div>
                          {/* Client Willing To Pay Online */}
                          <div
                            className="form-group mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "-20px",
                            }}
                          >
                            <label
                              htmlFor="clientWilling"
                              style={{
                                width: "200px",
                                whiteSpace: "nowrap",
                                marginRight: "50px",
                              }}
                            >
                              Client Willing To Pay Online:
                            </label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "50px",
                                marginLeft: "10px",
                              }}
                            >
                              <label
                                style={{
                                  marginRight: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="clientWillingYes"
                                  name="selectedClientWilling"
                                  value="YES"
                                  checked={
                                    formData.selectedClientWilling === "YES"
                                  }
                                  onChange={handleRadioButtonChange}
                                />
                                <span style={{ marginLeft: "5px" }}>Yes</span>
                              </label>
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="radio"
                                  id="clientWillingNo"
                                  name="selectedClientWilling"
                                  value="NO"
                                  checked={
                                    formData.selectedClientWilling === "NO"
                                  }
                                  onChange={handleRadioButtonChange}
                                />
                                <span style={{ marginLeft: "5px" }}>No</span>
                              </label>
                            </div>
                          </div>
                          {/* Remarks */}
                          {/* <div
                        className="form-group mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="remarkText"
                          style={{ width: "200px", marginRight: "50px" }}
                        >
                          Remarks:
                        </label>
                        <textarea
                          id="remarkText"
                          name="remarkText"
                          value={formData.remarks}
                          onChange={handleInputChange}
                          
                          style={{
                            flex: "1",
                            padding: "5px",
                            boxSizing: "border-box",
                            minHeight: "60px",
                          }}
                          placeholder="Enter your remark here"
                        />
                      </div> */}

                          {/* Final Decision */}
                          {/* <div
                        className="form-group mt-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "-20px",
                        }}
                      >
                        <label
                          htmlFor="finalDecision"
                          style={{
                            width: "200px",
                            whiteSpace: "nowrap",
                            marginRight: "50px",
                            color:
                              selectedFinalDecision === "Rejected"
                                ? "red"
                                : "black", // Conditional styling
                          }}
                        >
                          Final Decision:
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "50px",
                            marginLeft: "10px",
                          }}
                        >
                          <label
                            style={{
                              marginRight: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="radio"
                              id="finalDecisionApproved"
                              name="finalDecision"
                              value="Approved"
                              checked={selectedFinalDecision === "Approved"}
                              onChange={handleFinalDecisionChange}
                            />
                            <span style={{ marginLeft: "5px" }}>Approved</span>
                          </label>
                          <label
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="radio"
                              id="finalDecisionRejected"
                              name="finalDecision"
                              value="Rejected"
                              checked={selectedFinalDecision === "Rejected"}
                              onChange={handleFinalDecisionChange}
                            />
                            <span style={{ marginLeft: "5px" }}>Rejected</span>
                          </label>
                        </div>
                      </div>

                      {selectedFinalDecision === "Rejected" && (
                        <div
                          className="form-group mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="rejectReason"
                            style={{
                              width: "200px",
                              marginRight: "50px",
                              // color: selectedRejectReason === "Select" ? "red" : "black"
                            }}
                          >
                            Select Reject Reason:
                          </label>
                          <select
                            id="rejectReason"
                            name="rejectReason"
                            value={selectedRejectReason}
                            onChange={handleRejectReasonChange}
                            style={{ flex: "1", padding: "5px" }}
                          >
                            <option value="Select">Select</option>
                            <option value="No Business">No Business</option>
                            <option value="Fake business">Fake business</option>
                            <option value="Rented client/fake House">
                              Rented client/fake House
                            </option>
                            <option value="Less Than 2 Livestock">
                              Less Than 2 Livestock (In Case of LiveStock cases)
                            </option>
                            <option value="Client not Met During PV">
                              Client not Met During PV
                            </option>
                            <option value="Phone Number do not belong to client">
                              Phone Number do not belong to client
                            </option>
                            <option value="Residence Address is different to Aadhaar address">
                              Residence Address is different to Aadhaar address
                            </option>
                            <option value="Residence Stability less than 1 year">
                              Residence Stability less than 1 year
                            </option>
                            <option value="Household/disposable Income Norms not met">
                              Household/disposable Income Norms not met
                            </option>
                            <option value="Pipeline/commission/Agent Issue">
                              Pipeline/commission/Agent Issue
                            </option>
                            <option value="Negative Feedback from Reference /Family">
                              Negative Feedback from Reference /Family
                            </option>
                            <option value="Business Stocks less than norms">
                              Business Stocks less than norms (Only In 50k)
                            </option>
                            <option value="Negative Profile">
                              Negative Profile
                            </option>
                            <option value="Negative Area">Negative Area</option>
                            <option value="Age norms not met">
                              Age norms not met
                            </option>
                            <option value="Borrower/Co-Borrower does not stay at Aadhaar Address">
                              Borrower/Co-Borrower does not stay at Aadhaar
                              Address
                            </option>
                            <option value="Already a loan running in the same house">
                              Already a loan running in the same house
                            </option>
                            <option value="Different mobile no not available for co-borrower">
                              Different mobile no not available for co-borrower
                            </option>
                            <option value="Fake KYC">Fake KYC</option>
                            <option value="Client not interested informed BH">
                              Client not interested informed BH
                            </option>
                            <option value="Home ownership proof is not available">
                              Home ownership proof is not available
                            </option>
                          </select>
                        </div>
                      )} */}
                        </div>
                      )}

                      <button
                        type="submit"
                        className="btn mt-3"
                        style={{ backgroundColor: "#02CCFE" }}
                        onClick={handleFinalSubmit}
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Approved;
