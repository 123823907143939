import React, { useEffect, useRef, useState } from "react";
import logo from "../../image/Salarytap_social_profile.png";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";

import { APIURL } from "../../Config";
import axios from "axios";

import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAppContext } from "../utils/Appcontex";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Approved from "./Page/Approved";
import Rejected from "./Page/Rejected";
import All from "./Page/All";
import PendingPage from "./Page/PendingPage";
import ApprovedPending from "./Page/ApprovedPending";

export default function SideBar() {
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role"); //Engineer
  const id = localStorage.getItem("id");
  console.log("role::: ", role);

  // const [activeIndex, setActiveIndex] = useState(5);
  // const [key, setKey] = useState("ManageEmployees");
  const [activeTab, setActiveTab] = useState("profile");
  const [workSpace, SetWorkSpace] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [selectValue1, setSelectValue1] = useState("");

  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [openList, setOpenList] = useState(true);
  const { setOpen, key, setKey, activeIndex, setActiveIndex } = useAppContext();
  const [listItem, setListItem] = useState("Upload");
  const [showUpload, SetShowUpload] = useState(false);

  //  const [isLoader, setIsLoader] = useState(false);
  //  const fileInputRef = useRef(null);
  useEffect(() => {
    const tokenStatus = localStorage.getItem("token");
    if (
      tokenStatus === "undefined" ||
      tokenStatus === "" ||
      !tokenStatus ||
      tokenStatus === "null"
    ) {
      history("/");
    } else {
      history("/greenfinch/dashboard");
    }
  }, []);
  const [clientDetails, setClientDetails] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [productValue, setProductValue] = useState("");

  const [brachDetails, setBrachDetails] = useState([]);
  const [brachValue, setBrachValue] = useState("");
  const [brachValue1, setBrachValue1] = useState("");

  useEffect(() => {
    fetchClientDetails();
    getProductType();
    getBranch();
  }, []);
  const fetchClientDetails = async () => {
    await axios
      .get(`${APIURL}/api/ClientDetail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response::: ", response);
        setClientDetails(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductType = async () => {
    await axios
      .get(`${APIURL}/pv/api/getProductType`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("getProductType::: ", response);
        setProductDetails(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getBranch = async () => {
    try {
      await axios
        .get(`${APIURL}/pv/api/getBranch/26`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("getBranch::: ", response);
          setBrachDetails(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("error::: ", error);
    }
  };

  const getListItem = (item) => {
    setListItem(item);
  };

  const LoginSchema = Yup.object().shape({
    file: Yup.mixed().required("A file is required"),
    clientId: role === "Engineer" ? Yup.number().required("Required") : "",
    branchId: Yup.number().required("Required"),
  });

  const UploadSchema = Yup.object().shape({
    clientId: Yup.string().required("Client Name is required"),
    branchId: Yup.string().required("Branch is required"),
    productType: Yup.string().required("Product Type is required"),
    caseReferenceNumber: Yup.string().required("Case ID is required"),
    name: Yup.string().required("Customer Name is required"),
    mobile: Yup.number().required("Customer Mobile is required"),

    checkboxes: Yup.object()
      .shape({
        residenceVerificationStatus: Yup.number().nullable(),
        collateralVerificationStatus: Yup.number().nullable(),
        businessVerificationStatus: Yup.number().nullable(),
        employementVerificationStatus: Yup.number().nullable(),
      })
      .test(
        "at-least-one-checked",
        "At least one verification must be checked",
        (values) => {
          return (
            values?.residenceVerificationStatus === 0 ||
            values?.collateralVerificationStatus === 0 ||
            values?.businessVerificationStatus === 0 ||
            values?.employementVerificationStatus === 0
          );
        }
      ),
  });

  const handleClick = (index, tabKey, tabId) => {
    setActiveIndex(index);
    setKey(tabKey);
    setActiveTab(tabId);
    if (window.innerWidth <= 768) {
      setSidebarVisible(false);
    }
  };

  // React.useEffect(() => {
  //   if (!token == "") {
  //     history("/salarytap/dashboard");
  //     WorkspaceDetails();
  //   } else {
  //     history("/salarytap/login");
  //   }
  // }, []);

  // function WorkspaceDetails() {
  //   const data = { emailid, workspaceId };
  //   setIsLoader(true);
  //   axios
  //     .request(`${APIURL}/corporate/getprofiledetails`, {
  //       method: "POST",
  //       data: data,
  //       headers: {
  //         Authentication: token,
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       SetWorkSpace(response?.data);
  //       setIsLoader(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsLoader(false);
  //     });
  // }
  console.log("workSpace", workSpace);
  const [selectedImage, setSelectedImage] = useState("");
  const fileInputRef = useRef(null);
  const [previewError, setPreviewError] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setSelectedImage(file);
      setPreviewError(false);
    } else {
      setSelectedImage(null);
      setPreviewError(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedImage) {
      setPreviewError(true);
      return;
    }
    // const formData = new FormData();
    // formData.append("file", selectedImage);
    // formData.append("emailid", emailid);
    // console.log("formData", formData);
    // for (let pair of formData.entries()) {
    //   console.log(pair[0], pair[1]);
    // }
    // setIsLoader(true);
    // axios
    //   .request(`${APIURL}/corporate/uploadlogo`, {
    //     method: "POST",
    //     data: formData,
    //     headers: {
    //       Authentication: token,
    //     },
    //   })
    //   .then((response) => {
    //     setIsLoader(false);
    //     console.log("response", response);
    //     setShowModal(false);
    //     WorkspaceDetails();
    //     toast.success("Uploaded..");
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //     setIsLoader(false);
    //     setShowModal(false);
    //     toast.warning("User not exist...");
    //   });
  };

  const handleModalClose = () => {
    setSelectedImage("");
    setShowModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth > 768) {
        setSidebarVisible(true);
      } else {
        setSidebarVisible(false);
      }
    };
    setIsMobile(window.innerWidth < 768);
    if (window.innerWidth < 768) {
      setSidebarVisible(false);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleOpenList = () => {
    setOpenList(!openList);
  };
  const handleXls = () => {
    axios
      .request(`${APIURL}/api/addCaseDetailsByExcelFormate`, {
        method: "GET",
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("response::", response);
        if (response?.data) {
          const pdfData = response?.data;
          downloadXls(pdfData);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const downloadXls = (xlsData) => {
    const blob = new Blob([xlsData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "uploadFile.xls";
    link.click();
  };

  return (
    <>
      <div className="d-flex bg-light">
        {sidebarVisible && (
          <div
            className="flex-column flex-shrink-0 p-3 border sidebar-toggle"
            style={{
              width: 280,
              height: "93.4vh",
              display: "flex",
              background: "#fff",
              position: "sticky",
              top: "0",
            }}
          >
            <div
              className="d-flex"
              style={{
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
              }}
            ></div>

            <ul className="nav nav-pills flex-column mb-auto">
              <li
                className={"nav-link nav-itm mb-1 active"}
                onClick={() => {
                  handleClick(0);
                  setKey("Profile");
                  setOpen(false);
                  toggleOpenList();
                }}
                style={{
                  backgroundColor: "#02CCFE",
                  color: "#000",
                  cursor: "pointer",
                }}
              >
                CPV
              </li>
              {openList && (
                <div class="row">
                  <div>
                    <div class="list-group" id="list-tab" role="tablist">
                      <a
                        class="list-group-item list-group-item-action active"
                        id="list-home-list"
                        data-bs-toggle="list"
                        href="#list-home"
                        role="tab"
                        aria-controls="list-home"
                        onClick={() => {
                          getListItem("Upload");
                        }}
                      >
                        Upload
                      </a>
                      <a
                        class="list-group-item list-group-item-action"
                        id="list-profile-list"
                        data-bs-toggle="list"
                        href="#list-profile"
                        role="tab"
                        aria-controls="list-profile"
                        onClick={() => {
                          getListItem("Pending");
                        }}
                      >
                        Pending
                      </a>
                      {role === "Engineer" && (
                        <a
                          class="list-group-item list-group-item-action"
                          id="list-profile-list"
                          data-bs-toggle="list"
                          href="#list-profile"
                          role="tab"
                          aria-controls="list-profile"
                          onClick={() => {
                            getListItem("ApprovedPending");
                          }}
                        >
                          Approve Pending
                        </a>
                      )}
                      <a
                        class="list-group-item list-group-item-action"
                        id="list-profile-list"
                        data-bs-toggle="list"
                        href="#list-profile"
                        role="tab"
                        aria-controls="list-profile"
                        onClick={() => {
                          getListItem("Approved");
                        }}
                      >
                        Approved
                      </a>
                      <a
                        class="list-group-item list-group-item-action"
                        id="list-messages-list"
                        data-bs-toggle="list"
                        href="#list-messages"
                        role="tab"
                        aria-controls="list-messages"
                        onClick={() => {
                          getListItem("Rejected");
                        }}
                      >
                        Rejected
                      </a>
                      {/* <a
                        class="list-group-item list-group-item-action"
                        id="list-settings-list"
                        data-bs-toggle="list"
                        href="#list-settings"
                        role="tab"
                        aria-controls="list-settings"
                        onClick={() => {
                          getListItem("All");
                        }}
                      >
                        All
                      </a> */}
                    </div>
                  </div>
                </div>
              )}
            </ul>
          </div>
        )}
        <div className="container">
          {/* <h1 className="text-center my-3">Dashboard</h1> */}
          {listItem === "Upload" && (
            <div>
              {/* <h1 className="text-center my-3">Upload Data</h1> */}

              <div className="card p-4">
                <div className="d-flex justify-content-end p-2">
                  {!showUpload ? (
                    <button
                      className="mr-3 mt-2 btn"
                      style={{ width: "120px", background: "#02CCFE" }}
                      onClick={() => SetShowUpload(true)}
                    >
                      Add
                    </button>
                  ) : (
                    <button
                      className="mr-3 mt-2 btn btn-secondary"
                      style={{ width: "120px" }}
                      onClick={() => SetShowUpload(false)}
                    >
                      Back
                    </button>
                  )}
                </div>
                {!showUpload ? (
                  <Formik
                    initialValues={{
                      file: null,
                      clientId: "",
                      branchId: "",
                    }}
                    validationSchema={LoginSchema}
                    onSubmit={(values, { resetForm }) => {
                      console.log("values::: ", values);
                      const formData = new FormData();
                      formData.append("file", values.file);
                      formData.append("branchid", values.branchId);
                      if (role === "Engineer") {
                        formData.append("bankerId", values.clientId);
                      }
                      setIsLoader(true);
                      axios
                        .request(`${APIURL}/api/addCaseDetailsByExcel`, {
                          method: "POST",
                          data: formData,
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        })
                        .then((response) => {
                          console.log("response::", response);
                          setIsLoader(false);
                          resetForm({
                            values: {
                              clientId: "",
                              file: null,
                            },
                          });
                          setSelectValue("");
                          setBrachValue("");
                          toast.success(response?.data);
                          fileInputRef.current.value = null;
                        })
                        .catch((error) => {
                          console.log("error", error);
                          setIsLoader(false);
                          toast.warning("Invalid...");
                          fileInputRef.current.value = null;
                        });
                    }}
                  >
                    {({
                      touched,
                      errors,
                      setFieldValue,
                      handleSubmit,
                      values,
                    }) => (
                      <>
                        <h1 className="text-center my-3">Upload Data</h1>
                        <Form className="pt-4" onSubmit={handleSubmit}>
                          <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-lg-9 col-md-9 col-sm-12">
                              <div className="form-group">
                                {role === "Engineer" && (
                                  <div className="mt-2">
                                    <label htmlFor="clientId">
                                      Select Client*
                                    </label>
                                    <Form.Select
                                      id="clientId"
                                      name="clientId"
                                      options={clientDetails}
                                      value={selectValue}
                                      type="number"
                                      className={`form-control ${
                                        touched.clientId && errors.clientId
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      onChange={(e) => {
                                        setSelectValue(e.target.value);
                                        setFieldValue(
                                          "clientId",
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option value="">Select Client</option>
                                      {clientDetails.map((ids) => (
                                        <option value={ids.id}>
                                          {ids.name}
                                        </option>
                                      ))}
                                    </Form.Select>

                                    <ErrorMessage
                                      component="div"
                                      name="clientId"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                )}
                                <div className="mt-2">
                                  <label htmlFor="branchId">
                                    Select Client Branch*
                                  </label>
                                  <Form.Select
                                    id="branchId"
                                    name="branchId"
                                    value={brachValue}
                                    type="number"
                                    className={`form-control ${
                                      touched.branchId && errors.branchId
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={(e) => {
                                      setBrachValue(e.target.value);
                                      setFieldValue("branchId", e.target.value);
                                    }}
                                  >
                                    <option value="">
                                      Select Client Branch
                                    </option>
                                    {brachDetails.map((ids) => (
                                      <option value={ids.id}>
                                        {ids.branchName}
                                      </option>
                                    ))}
                                  </Form.Select>

                                  <ErrorMessage
                                    component="div"
                                    name="branchId"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-9 col-md-9 col-sm-12">
                                <div>
                                  <label htmlFor="file">Upload File</label>
                                  <input
                                    id="file"
                                    name="file"
                                    type="file"
                                    accept=".xlsx, .xls"
                                    ref={fileInputRef}
                                    className={`form-control ${
                                      touched.file && errors.file
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "file",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    name="file"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-12  d-flex justify-content-center">
                                <div
                                  style={{
                                    width: "200px",
                                    backgroundColor: "#02CCFE",
                                    color: "#000",
                                    marginTop: "2rem",
                                    padding: "8px",
                                    borderRadius: "6px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleXls()}
                                >
                                  Download Demo File
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="form-check">
                            <input
                              className={`form-check-input ${
                                touched.checkboxes
                                  ?.residenceVerificationStatus &&
                                errors.checkboxes?.residenceVerificationStatus
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="checkbox"
                              name="checkboxes.residenceVerificationStatus"
                              checked={
                                values.checkboxes
                                  ?.residenceVerificationStatus === 0
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "checkboxes.residenceVerificationStatus",
                                  e.target.checked ? 0 : 2
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkboxes.residenceVerificationStatus"
                            >
                              RESIDENCE VERIFICATION
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className={`form-check-input ${
                                touched.checkboxes
                                  ?.collateralVerificationStatus &&
                                errors.checkboxes?.collateralVerificationStatus
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="checkbox"
                              name="checkboxes.collateralVerificationStatus"
                              checked={
                                values.checkboxes
                                  ?.collateralVerificationStatus === 0
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "checkboxes.collateralVerificationStatus",
                                  e.target.checked ? 0 : 2
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkboxes.collateralVerificationStatus"
                            >
                              COLLATERAL VERIFICATION
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className={`form-check-input ${
                                touched.checkboxes
                                  ?.businessVerificationStatus &&
                                errors.checkboxes?.businessVerificationStatus
                                  ? "is-invalid"
                                  : ""
                              }`}
                              type="checkbox"
                              name="checkboxes.businessVerificationStatus"
                              checked={
                                values.checkboxes
                                  ?.businessVerificationStatus === 0
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "checkboxes.businessVerificationStatus",
                                  e.target.checked ? 0 : 2
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkboxes.businessVerificationStatus"
                            >
                              BUSINESS VERIFICATION
                            </label>
                          </div>
                          <div className="" style={{ color: "#DC3545" }}>
                            {" "}
                            {errors.checkboxes}
                          </div> */}

                          <button
                            type="submit"
                            className="btn btn-info my-3"
                            style={{
                              backgroundColor: "#02CCFE",
                              width: "auto",
                              color: "#000",
                            }}
                          >
                            {isLoader ? (
                              <div
                                className="spinner-border text-success"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </Form>
                      </>
                    )}
                  </Formik>
                ) : (
                  <div>
                    <Formik
                      initialValues={{
                        clientId: "",
                        branchId: "",
                        productType: "",
                        caseReferenceNumber: "",
                        name: "",
                        mobile: "",
                        coborrowerNameJson: [""],
                        guarantorsJson: [
                          {
                            guarantorName: "",
                            guarantorMobileNo: "",
                            guarantorAddress: "",
                          },
                        ],
                        checkboxes: {
                          residenceVerificationStatus: 2,
                          collateralVerificationStatus: 2,
                          businessVerificationStatus: 2,
                          employementVerificationStatus: 2,
                        },
                        residenceVerificationAddress: "",
                        collateralVerificationAddress: "",
                        businessVerificationAddress: "",
                        employementVerificationAddress: "",
                      }}
                      validationSchema={UploadSchema}
                      onSubmit={(values, { resetForm }) => {
                        console.log("values::: ", values);
                        if (
                          values.checkboxes?.residenceVerificationStatus ===
                            0 &&
                          !values?.residenceVerificationAddress
                        ) {
                          toast.error("RV Address required");
                          return;
                        }
                        if (
                          values.checkboxes?.collateralVerificationStatus ===
                            0 &&
                          !values?.collateralVerificationAddress
                        ) {
                          toast.error("CV Address required");
                          return;
                        }
                        if (
                          values.checkboxes?.businessVerificationStatus === 0 &&
                          !values?.businessVerificationAddress
                        ) {
                          toast.error("BV Address required");
                          return;
                        }
                        if (
                          values.checkboxes?.employementVerificationStatus ===
                            0 &&
                          !values?.employementVerificationAddress
                        ) {
                          toast.error("EV Address required");
                          return;
                        }
                        const formData = {
                          residenceVerificationStatus:
                            values.checkboxes.residenceVerificationStatus,
                          collateralVerificationStatus:
                            values.checkboxes.collateralVerificationStatus,
                          businessVerificationStatus:
                            values.checkboxes.businessVerificationStatus,
                          employementVerificationStatus:
                            values.checkboxes.employementVerificationStatus,
                          ...(role === "Engineer" && {
                            bankerId: values.clientId,
                          }),
                          branchId: values.branchId,
                          productType: values.productType,
                          caseReferenceNumber: values.caseReferenceNumber,
                          name: values.name,
                          mobile: values.mobile,
                          coborrowerNameJson: values.coborrowerNameJson,
                          guarantorsJson: values.guarantorsJson,
                          residenceVerificationAddress:
                            values.residenceVerificationAddress,
                          collateralVerificationAddress:
                            values.collateralVerificationAddress,
                          businessVerificationAddress:
                            values.businessVerificationAddress,
                          employementVerificationAddress:
                            values.employementVerificationAddress,
                        };
                        console.log("formData::: ", formData);

                        setIsLoader(true);
                        axios
                          .request(`${APIURL}/api/addCaseDetailsByForm`, {
                            method: "POST",
                            data: formData,
                            headers: {
                              Authorization: `Bearer ${token}`,
                              "Content-Type": "application/json",
                            },
                          })
                          .then(async (response) => {
                            console.log("response::: ", response);
                            SetWorkSpace(response?.data);
                            toast.success(response?.data);
                            setIsLoader(false);
                            setBrachValue1("");
                            setSelectValue1("");
                            SetShowUpload(false);
                            resetForm({
                              values: {
                                clientId: "",
                                branchId: "",
                                productType: "",
                                caseReferenceNumber: "",
                                name: "",
                                mobile: "",
                                coborrowerNameJson: [""],
                                guarantorsJson: [
                                  {
                                    guarantorName: "",
                                    guarantorMobileNo: "",
                                    guarantorAddress: "",
                                  },
                                ],
                                checkboxes: {
                                  residenceVerificationStatus: 2,
                                  collateralVerificationStatus: 2,
                                  businessVerificationStatus: 2,
                                  employementVerificationStatus: 2,
                                },
                                residenceVerificationAddress: "",
                                collateralVerificationAddress: "",
                                businessVerificationAddress: "",
                                employementVerificationAddress: "",
                              },
                            });
                            setSelectValue("");
                          })
                          .catch((error) => {
                            console.log(error);
                            setIsLoader(false);
                          });
                      }}
                    >
                      {({
                        touched,
                        errors,
                        handleSubmit,
                        setFieldValue,
                        values,
                      }) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                          if (values.residenceVerificationAddress) {
                            const timer = setTimeout(() => {
                              if (!values.collateralVerificationAddress) {
                                setFieldValue(
                                  "collateralVerificationAddress",
                                  values.residenceVerificationAddress
                                );
                              }
                              if (!values.businessVerificationAddress) {
                                setFieldValue(
                                  "businessVerificationAddress",
                                  values.residenceVerificationAddress
                                );
                              }
                            }, 1000);

                            return () => clearTimeout(timer);
                          }
                        }, [
                          values.residenceVerificationAddress,
                          values.collateralVerificationAddress,
                          values.businessVerificationAddress,
                          setFieldValue,
                        ]);

                        return (
                          <Form className="pt-4" onSubmit={handleSubmit}>
                            {role === "Engineer" && (
                              <div className="mt-2">
                                <label htmlFor="clientId">Client Name*</label>
                                <Field
                                  as="select"
                                  id="clientId"
                                  name="clientId"
                                  className={`form-control ${
                                    touched.clientId && errors.clientId
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  <option value="">Select Client Name</option>
                                  {clientDetails?.map((ids) => (
                                    <option value={ids.id} key={ids.id}>
                                      {ids.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name="clientId"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            )}
                            <div className="mt-2">
                              <label htmlFor="branchId">
                                Select Client Branch*
                              </label>
                              <Field
                                as="select"
                                id="branchId"
                                name="branchId"
                                className={`form-control ${
                                  touched.branchId && errors.branchId
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <option value="">Select Client Branch</option>
                                {brachDetails?.map((ids) => (
                                  <option value={ids.id} key={ids.id}>
                                    {ids.branchName}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="branchId"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="productType">
                                Select Product Type*
                              </label>
                              <Field
                                as="select"
                                id="productType"
                                name="productType"
                                className={`form-control ${
                                  touched.productType && errors.productType
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <option value="">Select Product Type</option>
                                {productDetails?.map((ids) => (
                                  <option
                                    value={ids.shortName}
                                    key={ids.shortName}
                                  >
                                    {ids.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="productType"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div className="mt-2">
                              <label>Case ID*</label>
                              <Field
                                type="text"
                                name="caseReferenceNumber"
                                placeholder="Enter caseId"
                                className={`form-control ${
                                  touched.caseReferenceNumber &&
                                  errors.caseReferenceNumber
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                component="div"
                                name="caseReferenceNumber"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="d-flex justify-content-between w-100">
                              <div className="mt-2 w-100">
                                <label>Customer Name*</label>
                                <Field
                                  type="text"
                                  name="name"
                                  placeholder="Enter customer name"
                                  className={`form-control ${
                                    touched.name && errors.name
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="name"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mt-2 w-100 ms-2">
                                <label>Customer Mobile*</label>
                                <Field
                                  type="number"
                                  name="mobile"
                                  placeholder="Enter customer mobile"
                                  className={`form-control ${
                                    touched.mobile && errors.mobile
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  min="0"
                                  max="9999999999"
                                  onInput={(e) => {
                                    if (e.target.value.length > 10) {
                                      e.target.value = e.target.value.slice(
                                        0,
                                        10
                                      );
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="mobile"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                            <div className="mt-2">
                              <FieldArray
                                name="coborrowerNameJson"
                                render={(arrayHelpers) => (
                                  <>
                                    <div
                                      className="w-100"
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                      }}
                                    >
                                      <button
                                        className="btn btn-primary m-1"
                                        style={{ width: "60px" }}
                                        type="button"
                                        onClick={() => arrayHelpers.push("")}
                                      >
                                        Add
                                      </button>
                                    </div>
                                    <div>
                                      <div className="card p-3">
                                        {values?.coborrowerNameJson?.map(
                                          (coBorrower, index) => (
                                            <div key={index}>
                                              <div className="form-group">
                                                <label
                                                  htmlFor={`coborrowerNameJson.${index}`}
                                                  className="mt-3 mb-0"
                                                >
                                                  Co Borrowerss
                                                </label>

                                                <div className="d-flex">
                                                  <Field
                                                    type="text"
                                                    name={`coborrowerNameJson[${index}]`}
                                                    placeholder="Enter co-borrower"
                                                    className={`form-control ${
                                                      touched
                                                        .coborrowerNameJson?.[
                                                        index
                                                      ] &&
                                                      errors
                                                        .coborrowerNameJson?.[
                                                        index
                                                      ]
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                    aria-label={`Co-borrower ${
                                                      index + 1
                                                    }`} // Accessibility
                                                  />
                                                  <button
                                                    className="btn btn-danger mx-1"
                                                    type="button"
                                                    disabled={
                                                      values.coborrowerNameJson
                                                        .length === 1
                                                    }
                                                    style={{ width: "100px" }}
                                                    onClick={() => {
                                                      arrayHelpers.remove(
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    Remove
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              />
                            </div>
                            <div className="d-flex justify-content-between w-100 mt-3">
                              <FieldArray
                                name="guarantorsJson"
                                render={(arrayHelpers) => (
                                  <div className="w-100">
                                    <div className="d-flex align-items-end mb-1">
                                      <div className="d-flex justify-content-between w-100">
                                        <div className="w-100"></div>
                                        <div className="w-100 ms-2"></div>
                                        <div className="w-100 ms-2"></div>
                                      </div>
                                      <div className="d-flex justify-content-end">
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.push({
                                              guarantorName: "",
                                              guarantorMobileNo: "",
                                              guarantorAddress: "",
                                            })
                                          }
                                          style={{ width: "60px" }}
                                        >
                                          Add
                                        </button>
                                      </div>
                                    </div>
                                    <div className="card p-3">
                                      {values.guarantorsJson.map(
                                        (guarantor, index) => (
                                          <div
                                            className="d-flex justify-content-between mt-2 w-100"
                                            key={index}
                                          >
                                            <div className="w-100">
                                              <div className="d-flex justify-content-between w-100">
                                                <div className="w-100">
                                                  <label className="m-0">
                                                    Guarantor Name
                                                  </label>
                                                  <Field
                                                    type="text"
                                                    name={`guarantorsJson[${index}].guarantorName`}
                                                    placeholder="Enter guarantor guarantorName"
                                                    className={`form-control ${
                                                      touched.guarantorsJson?.[
                                                        index
                                                      ]?.guarantorName &&
                                                      errors.guarantorsJson?.[
                                                        index
                                                      ]?.guarantorName
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                  />
                                                </div>
                                                <div className="w-100 ms-2">
                                                  <label className="m-0">
                                                    Guarantor Mobile No
                                                  </label>
                                                  <Field
                                                    type="number"
                                                    name={`guarantorsJson[${index}].guarantorMobileNo`}
                                                    placeholder="Enter guarantor mobile no"
                                                    className={`form-control ${
                                                      touched.guarantorsJson?.[
                                                        index
                                                      ]?.guarantorMobileNo &&
                                                      errors.guarantorsJson?.[
                                                        index
                                                      ]?.guarantorMobileNo
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                    min="0"
                                                    max="9999999999"
                                                    onInput={(e) => {
                                                      if (
                                                        e.target.value.length >
                                                        10
                                                      ) {
                                                        e.target.value =
                                                          e.target.value.slice(
                                                            0,
                                                            10
                                                          );
                                                      }
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              <div className="w-100 mt-1">
                                                <label className="m-0">
                                                  Residence Address
                                                </label>
                                                <Field
                                                  as="textarea"
                                                  name={`guarantorsJson[${index}].guarantorAddress`}
                                                  placeholder="Enter residence address"
                                                  className={`form-control ${
                                                    touched.guarantorsJson?.[
                                                      index
                                                    ]?.guarantorAddress &&
                                                    errors.guarantorsJson?.[
                                                      index
                                                    ]?.guarantorAddress
                                                      ? "is-invalid"
                                                      : ""
                                                  }`}
                                                />
                                              </div>
                                            </div>
                                            <div>
                                              <button
                                                className="btn btn-danger ms-1"
                                                type="button"
                                                disabled={
                                                  values.guarantorsJson
                                                    .length === 1
                                                }
                                                style={{ width: "100  px" }}
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                }
                                              >
                                                Remove
                                              </button>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="" className="m-0">
                                Verficiation Type*
                              </label>
                            </div>{" "}
                            <div className="d-flex w-100">
                              {/* Residence Verification Checkbox */}
                              <div className="form-check mt-2">
                                <input
                                  className={`form-check-input ${
                                    touched.checkboxes
                                      ?.residenceVerificationStatus &&
                                    errors.checkboxes
                                      ?.residenceVerificationStatus
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  type="checkbox"
                                  name="checkboxes.residenceVerificationStatus"
                                  checked={
                                    values.checkboxes
                                      ?.residenceVerificationStatus === 0
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      "checkboxes.residenceVerificationStatus",
                                      e.target.checked ? 0 : 2
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="checkboxes.residenceVerificationStatus"
                                >
                                  RV
                                </label>
                              </div>
                              {/* Collateral Verification Checkbox */}
                              <div className="form-check mt-2 ms-3">
                                <input
                                  className={`form-check-input ${
                                    touched.checkboxes
                                      ?.collateralVerificationStatus &&
                                    errors.checkboxes
                                      ?.collateralVerificationStatus
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  type="checkbox"
                                  name="checkboxes.collateralVerificationStatus"
                                  checked={
                                    values.checkboxes
                                      ?.collateralVerificationStatus === 0
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      "checkboxes.collateralVerificationStatus",
                                      e.target.checked ? 0 : 2
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="checkboxes.collateralVerificationStatus"
                                >
                                  CV
                                </label>
                              </div>
                              {/* Business Verification Checkbox */}
                              <div className="form-check mt-2 ms-3">
                                <input
                                  className={`form-check-input ${
                                    touched.checkboxes
                                      ?.businessVerificationStatus &&
                                    errors.checkboxes
                                      ?.businessVerificationStatus
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  type="checkbox"
                                  name="checkboxes.businessVerificationStatus"
                                  checked={
                                    values.checkboxes
                                      ?.businessVerificationStatus === 0
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      "checkboxes.businessVerificationStatus",
                                      e.target.checked ? 0 : 2
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="checkboxes.businessVerificationStatus"
                                >
                                  BV
                                </label>
                              </div>
                              {/* Ev Checkbox */}
                              <div className="form-check mt-2 ms-3">
                                <input
                                  className={`form-check-input ${
                                    touched.checkboxes
                                      ?.employementVerificationStatus &&
                                    errors.checkboxes
                                      ?.employementVerificationStatus
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  type="checkbox"
                                  name="checkboxes.employementVerificationStatus"
                                  checked={
                                    values.checkboxes
                                      ?.employementVerificationStatus === 0
                                  }
                                  onChange={(e) => {
                                    setFieldValue(
                                      "checkboxes.employementVerificationStatus",
                                      e.target.checked ? 0 : 2
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="checkboxes.employementVerificationStatus"
                                >
                                  EV
                                </label>
                              </div>
                            </div>
                            <div className="mt-1" style={{ color: "#DC3545" }}>
                              {" "}
                              {errors.checkboxes}
                            </div>
                            {values.checkboxes?.residenceVerificationStatus ===
                              0 && (
                              <>
                                <div className="mt-2">
                                  <label htmlFor="residenceVerificationAddress">
                                    RV Address
                                  </label>
                                  <input
                                    type="text"
                                    name="residenceVerificationAddress"
                                    className={`form-control ${
                                      touched?.residenceVerificationAddress &&
                                      errors?.residenceVerificationAddress
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter RV address"
                                    value={values.residenceVerificationAddress}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "residenceVerificationAddress",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                {values.checkboxes
                                  ?.residenceVerificationStatus === 0 &&
                                  !values?.residenceVerificationAddress && (
                                    <div
                                      className="mt-1"
                                      style={{ color: "#DC3545" }}
                                    >
                                      RV Address required
                                    </div>
                                  )}
                              </>
                            )}
                            {values.checkboxes?.collateralVerificationStatus ===
                              0 && (
                              <>
                                <div className="mt-2">
                                  <label htmlFor="collateralVerificationAddress">
                                    CV Address
                                  </label>
                                  <input
                                    type="text"
                                    name="collateralVerificationAddress"
                                    className="form-control"
                                    placeholder="Enter  CV Address"
                                    value={values.collateralVerificationAddress}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "collateralVerificationAddress",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                {values.checkboxes
                                  ?.collateralVerificationStatus === 0 &&
                                  !values?.collateralVerificationAddress && (
                                    <div
                                      className="mt-1"
                                      style={{ color: "#DC3545" }}
                                    >
                                      CV Address required
                                    </div>
                                  )}
                              </>
                            )}
                            {values.checkboxes?.businessVerificationStatus ===
                              0 && (
                              <>
                                <div className="mt-2">
                                  <label htmlFor="businessVerificationAddress">
                                    BV Address
                                  </label>
                                  <input
                                    type="text"
                                    name="businessVerificationAddress"
                                    className="form-control"
                                    placeholder="Enter BV Address"
                                    value={values.businessVerificationAddress}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "businessVerificationAddress",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                {values.checkboxes
                                  ?.businessVerificationStatus === 0 &&
                                  !values?.businessVerificationAddress && (
                                    <div
                                      className="mt-1"
                                      style={{ color: "#DC3545" }}
                                    >
                                      BV Address required
                                    </div>
                                  )}
                              </>
                            )}
                            {values.checkboxes
                              ?.employementVerificationStatus === 0 && (
                              <>
                                <div className="mt-2">
                                  <label htmlFor="employementVerificationAddress">
                                    EV Address
                                  </label>
                                  <input
                                    type="text"
                                    name="employementVerificationAddress"
                                    className="form-control"
                                    placeholder="Enter EV Address"
                                    value={
                                      values.employementVerificationAddress
                                    }
                                    onChange={(e) =>
                                      setFieldValue(
                                        "employementVerificationAddress",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                {values.checkboxes
                                  ?.employementVerificationStatus === 0 &&
                                  !values?.employementVerificationAddress && (
                                    <div
                                      className="mt-1"
                                      style={{ color: "#DC3545" }}
                                    >
                                      EV Address required
                                    </div>
                                  )}
                              </>
                            )}
                            <button
                              type="submit"
                              className="btn mt-3"
                              style={{ backgroundColor: "#02CCFE" }}
                            >
                              {isLoader ? (
                                <div
                                  className="spinner-border text-success"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          )}
          {listItem === "Pending" && <PendingPage />}
          {listItem === "ApprovedPending" && <ApprovedPending />}
          {listItem === "Approved" && <Approved />}
          {listItem === "Rejected" && <Rejected />}
          {listItem === "All" && <All />}
        </div>
      </div>
    </>
  );
}
