import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ApprovedDataCollateral from "./ApprovedUpdateCollateral";
import ApprovedUpdateCollateral from "./ApprovedUpdateCollateral";
import ApprovedUpdateResidencd from "./ApprovedUpdateResidencd";
import ApprovedUpdateBusiness from "./ApprovedUpdateBusiness";
import { APIURL } from "../../../Config";
import { toast } from "react-toastify";

function ApprovedDataUpdate({
  collateralVerificationStatus,
  businessVerificationStatus,
  residenceVerificationStatus,
  updateId,
  personalInformationData,
}) {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");

  const [formStates, setFormStates] = useState({
    collateral: false,
    business: false,
    residence: false,
  });

  const [residenceVerificationData, setResidenceVerificationData] =
    useState("");
  const [businessVerificationData, setBusinessVerificationData] = useState("");
  const [collateralVerificationData, setCollateralVerificationData] =
    useState("");

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    console.log("checked::: ", checked);
    console.log("value::: ", value);

    if (checked && value === "collateral") {
      try {
        axios
          .request(
            `${APIURL}/v2/api/cpv/getCpvCollateralVerificationDetail/${updateId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log("getCpvCollateralVerificationDetail::", response);
            if (response?.data) {
              setCollateralVerificationData(response?.data);
              setFormStates((prevState) => ({
                ...prevState,
                [value]: checked,
              }));
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch (error) {
        console.log("error::: ", error);
      }
    } else {
      setFormStates((prevState) => ({
        ...prevState,
        [value]: checked,
      }));
    }

    if (checked && value === "business") {
      try {
        axios
          .request(
            `${APIURL}/v2/api/cpv/getCpvBusinessVerificationDetail/${updateId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log("getCpvBusinessVerificationDetail::", response);
            if (response?.data) {
              setBusinessVerificationData(response?.data);
              setFormStates((prevState) => ({
                ...prevState,
                [value]: checked,
              }));
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch (error) {
        console.log("error::: ", error);
      }
    } else {
      setFormStates((prevState) => ({
        ...prevState,
        [value]: checked,
      }));
    }
    if (checked && value === "residence") {
      try {
        axios
          .request(
            `${APIURL}/v2/api/cpv/getCpvResidenceVerificationDetail/${updateId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log("getCpvResidenceVerificationDetail::", response);
            if (response?.data) {
              setResidenceVerificationData(response?.data);
              setFormStates((prevState) => ({
                ...prevState,
                [value]: checked,
              }));
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch (error) {
        console.log("error::: ", error);
      }
    } else {
      setFormStates((prevState) => ({
        ...prevState,
        [value]: checked,
      }));
    }
  };
  const validationSchema = Yup.object().shape({
    aadhaarCard: Yup.string()
      .matches(/^\d{12}$/, "Aadhaar Card must be exactly 12 digits")
      .required("Aadhaar Card is required"),
    businessAddress: Yup.string().required("Business Address is required"),
    caseId: Yup.string().required("Case ID is required"),
    client: Yup.string().required("Client is required"),
    clientBranch: Yup.string().required("Client Branch is required"),
    clientId: Yup.number().required("Client ID is required"),
    currentResidenceAddress: Yup.string().required(
      "Current Residence Address is required"
    ),
    dobOrDoi: Yup.date().required("Date of Birth/Issue is required"),
    fatherOrSpouseName: Yup.string().required("Father/Spouse Name is required"),
    greenfinchBranch: Yup.string().required("Greenfinch Branch is required"),
    greenfinchClientId: Yup.string().required(
      "Greenfinch Client ID is required"
    ),
    maritalStatus: Yup.string().required("Marital Status is required"),
    mobileNo: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Mobile number must be 10 digits")
      .required("Mobile number is required"),
    name: Yup.string().required("Name is required"),
    pan: Yup.string().required("PAN is required"),
    permanentAddress: Yup.string().required("Permanent Address is required"),
    productType: Yup.string().required("Product Type is required"),
    qualification: Yup.string().required("Qualification is required"),
  });

  const [clientDetails, setClientDetails] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [branchDetails, setBrachDetails] = useState([]);

  useEffect(() => {
    fetchClientDetails();
    getProductType();
    getBranch();
  }, []);
  const fetchClientDetails = async () => {
    await axios
      .get(`${APIURL}/api/ClientDetail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setClientDetails(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductType = async () => {
    await axios
      .get(`${APIURL}/pv/api/getProductType`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setProductDetails(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getBranch = async () => {
    try {
      await axios
        .get(`${APIURL}/pv/api/getBranch/26`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setBrachDetails(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("error::: ", error);
    }
  };
  return (
    <div>
      <div className="pt-3">
        <div className="">
          <div className="p-3 pt-4">
            <Formik
              initialValues={{
                aadhaarCard: personalInformationData?.aadhaarCard || "",
                businessAddress: personalInformationData?.businessAddress || "",
                caseId: personalInformationData?.caseId || "",
                client: personalInformationData?.client || "",
                clientId: personalInformationData?.clientId || "",
                clientBranch: personalInformationData?.clientBranch || "",
                currentResidenceAddress:
                  personalInformationData?.currentResidenceAddress || "",

                dobOrDoi: personalInformationData?.dobOrDoi
                  ? new Date(personalInformationData.dobOrDoi)
                      .toISOString()
                      .split("T")[0]
                  : "",

                fatherOrSpouseName:
                  personalInformationData?.fatherOrSpouseName || "",
                greenfinchBranch:
                  personalInformationData?.greenfinchBranch || "",
                greenfinchClientId:
                  personalInformationData?.greenfinchClientId || "",
                maritalStatus: personalInformationData?.maritalStatus || "",
                name: personalInformationData?.name || "",

                mobileNo: personalInformationData?.mobileNo || "",
                pan: personalInformationData?.pan || "",
                permanentAddress:
                  personalInformationData?.permanentAddress || "",
                productType: personalInformationData?.productType || "",
                qualification: personalInformationData?.qualification || "",
                updatedBy: id,
              }}
              // validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log(values);
                axios
                  .request(`${APIURL}/v2/api/cpv/updatePersonalInformation`, {
                    method: "POST",
                    data: values,
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  })
                  .then(async (response) => {
                    toast.success(response?.data);
                    console.log("response::: ", response);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              {({ touched, errors }) => (
                <div className="container mt-4">
                  <h2>Personal Information Details</h2>
                  <Form>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="name">Name</label>
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          className={`form-control ${
                            touched.name && errors.name ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="mobileNo" className="form-label">
                          Mobile Number
                        </label>
                        <Field
                          type="text"
                          id="mobileNo"
                          name="mobileNo"
                          className={`form-control ${
                            touched.mobileNo && errors.mobileNo
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="mobileNo"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="aadhaarCard" className="form-label">
                          Aadhaar Card
                        </label>
                        <Field
                          type="text"
                          id="aadhaarCard"
                          name="aadhaarCard"
                          className={`form-control ${
                            touched.aadhaarCard && errors.aadhaarCard
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="aadhaarCard"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="businessAddress" className="form-label">
                          Business Address
                        </label>
                        <Field
                          type="text"
                          id="businessAddress"
                          name="businessAddress"
                          className={`form-control ${
                            touched.businessAddress && errors.businessAddress
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="businessAddress"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="caseId" className="form-label">
                          Case ID
                        </label>
                        <Field
                          type="text"
                          id="caseId"
                          name="caseId"
                          className={`form-control ${
                            touched.caseId && errors.caseId ? "is-invalid" : ""
                          }`}
                          disabled
                        />
                        <ErrorMessage
                          component="div"
                          name="caseId"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="client" className="form-label">
                          Client Name
                        </label>
                        <Field
                          as="select"
                          id="client"
                          name="client"
                          className={`form-control ${
                            touched.client && errors.client ? "is-invalid" : ""
                          }`}
                        >
                          <option value="">Select Client Name</option>
                          {clientDetails?.map((ids) => (
                            <option value={ids.name} key={ids.name}>
                              {ids.name}
                            </option>
                          ))}
                        </Field>

                        <ErrorMessage
                          component="div"
                          name="client"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="clientBranch" className="form-label">
                          Client Branch
                        </label>
                        <Field
                          as="select"
                          id="clientBranch"
                          name="clientBranch"
                          className={`form-control ${
                            touched.clientBranch && errors.clientBranch
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Client Branch</option>
                          {branchDetails?.map((ids) => (
                            <option value={ids.branchName} key={ids.branchName}>
                              {ids.branchName}
                            </option>
                          ))}
                        </Field>

                        <ErrorMessage
                          component="div"
                          name="clientBranch"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="clientId" className="form-label">
                          Client ID
                        </label>
                        <Field
                          type="number"
                          id="clientId"
                          name="clientId"
                          className={`form-control ${
                            touched.clientId && errors.clientId
                              ? "is-invalid"
                              : ""
                          }`}
                          disabled
                        />
                        <ErrorMessage
                          component="div"
                          name="clientId"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label
                          htmlFor="currentResidenceAddress"
                          className="form-label"
                        >
                          Current Residence Address
                        </label>
                        <Field
                          type="text"
                          id="currentResidenceAddress"
                          name="currentResidenceAddress"
                          className={`form-control ${
                            touched.currentResidenceAddress &&
                            errors.currentResidenceAddress
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="currentResidenceAddress"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="fatherOrSpouseName">
                          Father or Spouse Name
                        </label>
                        <Field
                          type="text"
                          id="fatherOrSpouseName"
                          name="fatherOrSpouseName"
                          className={`form-control ${
                            touched.fatherOrSpouseName &&
                            errors.fatherOrSpouseName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="fatherOrSpouseName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="dobOrDoi" className="form-label">
                          Date of Birth/Issue
                        </label>
                        <Field
                          type="date"
                          id="dobOrDoi"
                          name="dobOrDoi"
                          className={`form-control ${
                            touched.dobOrDoi && errors.dobOrDoi
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="dobOrDoi"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="pan" className="form-label">
                          PAN
                        </label>
                        <Field
                          type="text"
                          id="pan"
                          name="pan"
                          className={`form-control ${
                            touched.pan && errors.pan ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="pan"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="greenfinchBranch">
                          Greenfinch Branch
                        </label>
                        <Field
                          as="select"
                          id="greenfinchBranch"
                          name="greenfinchBranch"
                          className={`form-control ${
                            touched.greenfinchBranch && errors.greenfinchBranch
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Branch</option>
                          {branchDetails?.map((branch) => (
                            <option
                              value={branch.branchName}
                              key={branch.branchName}
                            >
                              {branch.branchName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="greenfinchBranch"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="greenfinchClientId">
                          Greenfinch Client ID
                        </label>
                        <Field
                          type="text"
                          id="greenfinchClientId"
                          name="greenfinchClientId"
                          className={`form-control ${
                            touched.greenfinchClientId &&
                            errors.greenfinchClientId
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name="greenfinchClientId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="maritalStatus">Marital Status</label>
                        <Field
                          as="select"
                          id="maritalStatus"
                          name="maritalStatus"
                          className={`form-control ${
                            touched.maritalStatus && errors.maritalStatus
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Marital Status</option>
                          <option value="Married">Married</option>
                          <option value="Unmarried">Unmarried</option>
                        </Field>
                        <ErrorMessage
                          name="maritalStatus"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="productType" className="form-label">
                          Product Type
                        </label>
                        {/* <Field
                          as="select"
                          id="productType"
                          name="productType"
                          className={`form-control ${
                            touched.productType && errors.productType
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="">Select Product Type</option>
                          {productDetails?.map((ids) => (
                            <option value={ids.shortName} key={ids.shortName}>
                              {ids.name}
                            </option>
                          ))}
                        </Field> */}
                        <Field
                          type="text"
                          id="productType"
                          name="productType"
                          className={`form-control ${
                            touched.productType && errors.productType
                              ? "is-invalid"
                              : ""
                          }`}
                          disabled
                        />
                        <ErrorMessage
                          component="div"
                          name="productType"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label
                          htmlFor="permanentAddress"
                          className="form-label"
                        >
                          Permanent Address
                        </label>
                        <Field
                          type="text"
                          id="permanentAddress"
                          name="permanentAddress"
                          className={`form-control ${
                            touched.permanentAddress && errors.permanentAddress
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="permanentAddress"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="qualification" className="form-label">
                          Qualification
                        </label>
                        <Field
                          type="text"
                          id="qualification"
                          name="qualification"
                          className={`form-control ${
                            touched.qualification && errors.qualification
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="qualification"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </Form>
                </div>
              )}
            </Formik>
            <div className="my-4">
              <h3>Verification</h3>
              {collateralVerificationStatus !== 1 &&
              businessVerificationStatus !== 1 &&
              residenceVerificationStatus !== 1 ? (
                <p>Verification status not valid for Update form.</p>
              ) : (
                <>
                  <div className="d-flex align-items-center">
                    {collateralVerificationStatus === 1 && (
                      <div className="form-check me-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="verification"
                          value="collateral"
                          onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label">
                          Collateral Verification
                        </label>
                      </div>
                    )}

                    {businessVerificationStatus === 1 && (
                      <div className="form-check me-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="verification"
                          value="business"
                          onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label">
                          Business Verification
                        </label>
                      </div>
                    )}

                    {residenceVerificationStatus === 1 && (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="verification"
                          value="residence"
                          onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label">
                          Residence Verification
                        </label>
                      </div>
                    )}
                  </div>

                  <div className="mt-3">
                    {formStates.collateral &&
                      collateralVerificationData !== "" && (
                        <ApprovedUpdateCollateral
                          data={collateralVerificationData}
                          setFormStates={setFormStates}
                          formStates={formStates}
                        />
                      )}
                    {formStates.business && businessVerificationData !== "" && (
                      <ApprovedUpdateBusiness
                        data={businessVerificationData}
                        setFormStates={setFormStates}
                        formStates={formStates}
                      />
                    )}
                    {formStates.residence &&
                      residenceVerificationData !== "" && (
                        <ApprovedUpdateResidencd
                          data={residenceVerificationData}
                          setFormStates={setFormStates}
                          formStates={formStates}
                        />
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApprovedDataUpdate;
