import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { DownloadTableExcel } from "react-export-table-to-excel";
import axios from "axios";
import { APIURL } from "../../../Config";
import { Form } from "react-bootstrap";

const Rejected = () => {
  const token = localStorage.getItem("token");
  const ids = localStorage.getItem("id");
  const role = localStorage.getItem("role"); //Engineer
  const tableRef = React.useRef(null);
  const [selectDate, setSelectDate] = useState(false);
  const [dateValue1, setDateValue1] = useState(
    moment(new Date()).subtract(20, "days").format("YYYY-MM-DD")
  );
  const [dateValue2, setDateValue2] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  console.log("dateValue2::: ", dateValue2);
  const [data, setData] = useState([]);

  const [selectValue, setSelectValue] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [clientDetails, setClientDetails] = useState([]);
  const [engineerDetail, setEngineerDetail] = useState([]);

  useEffect(() => {
    const todayDate = new Date();
    setDateValue2(moment(todayDate).format("YYYY-MM-DD"));
    const sevenDaysBeforeDate = new Date();
    sevenDaysBeforeDate.setDate(todayDate.getDate() - 20);
    setDateValue1(moment(sevenDaysBeforeDate).format("YYYY-MM-DD"));
    handleSubmit();
  }, []);

  useEffect(() => {
    fetchClientDetails();
    fetchAssignTo();
  }, []);
  const fetchClientDetails = async () => {
    await axios
      .get(`${APIURL}/api/ClientDetail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("response::: ", response);
        setClientDetails(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchAssignTo = async () => {
    await axios
      .get(`${APIURL}/api/EngineerDetail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("response::: ", response);
        setEngineerDetail(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadExcel = () => {
    let url =
      role === "Engineer"
        ? `${APIURL}/api/pv/cpvGenerateExcel/0/${ids}/${dateValue1}/${dateValue2}`
        : `${APIURL}/api/pv/cpvGenerateExcel/0/${ids}/${dateValue1}/${dateValue2}`;

    if (selectValue !== "" && assignTo !== "") {
      url = `${APIURL}/api/pv/cpvGenerateExcel/0/${ids}/${dateValue1}/${dateValue2}/${selectValue}/${assignTo}`;
    } else if (selectValue !== "") {
      url = `${APIURL}/api/pv/cpvGenerateExcel/0/${ids}/${dateValue1}/${dateValue2}/${selectValue}`;
    } else if (assignTo !== "") {
      url = `${APIURL}/api/pv/vGenerateExcel/0/${ids}/${dateValue1}/${dateValue2}/${assignTo}`;
    }
    axios
      .request(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // important
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Reject.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const downloadXls = (xlsData) => {
    const blob = new Blob([xlsData], { type: "application/vnd.ms-excel" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Rejected.xls";
    link.click();
  };

  const handleSubmit = () => {
    console.log(":::::::", dateValue1, dateValue2);
    let url =
      role === "Engineer"
        ? `${APIURL}/api/clientStatusEngineer/${ids}/2/${dateValue1}/${dateValue2}`
        : `${APIURL}/api/clientStatus/${ids}/2/${dateValue1}/${dateValue2}`;

    if (selectValue !== "" && assignTo !== "") {
      url = `${APIURL}/api/clientStatusEngineerAprAndRejectByClientsAndAssignTo/${ids}/2/${assignTo}/${selectValue}/${dateValue1}/${dateValue2}`;
    } else if (selectValue !== "") {
      url = `${APIURL}/api/clientStatusEngineerAprAndRejectByClients/${ids}/2/${selectValue}/${dateValue1}/${dateValue2}`;
    } else if (assignTo !== "") {
      url = `${APIURL}/api/clientStatusEngineerAprAndRejectByAssignTo/${ids}/2/${assignTo}/${dateValue1}/${dateValue2}`;
    }
    axios
      .request(url, {
        method: "GET",

        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div class="pt-4">
      <h4 className="text-center my-3">Rejected</h4>

      <div className="pt-3">
        <div className="card">
          <div className="p-3 pt-4">
            <div className="p-3 d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div>
                <button
                  className="btn btn-success mb-3 mb-md-0"
                  style={{ width: "120px", background: "blue" }}
                  onClick={() => downloadExcel()}
                >
                  Download
                </button>
              </div>
              <div className="d-flex flex-column flex-md-row align-items-center">
                {!selectDate ? (
                  <button
                    className="btn btn-info"
                    style={{ width: "150px" }}
                    onClick={() => setSelectDate(true)}
                  >
                    Filters
                  </button>
                ) : (
                  <>
                    <div className="d-flex" style={{ width: "100%" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div className="mt-2 mx-1">
                          <Form.Select
                            options={clientDetails}
                            value={selectValue}
                            type="number"
                            className="form-control ml-2"
                            onChange={(e) => setSelectValue(e.target.value)}
                          >
                            <option value="">Select Client</option>
                            {clientDetails.map((ids) => (
                              <option value={ids.id} key={ids.id}>
                                {ids.name}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                        <div className="mt-2 mx-2">
                          <Form.Select
                            options={engineerDetail}
                            value={assignTo}
                            type="text"
                            className="form-control ml-2"
                            onChange={(e) => setAssignTo(e.target.value)}
                          >
                            <option value="">Select Rejected By</option>

                            {engineerDetail.map((ids) => (
                              <option value={ids.id} key={ids.id}>
                                {ids.name}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                        <div>
                          <DatePicker
                            className="ml-2"
                            label="To Date"
                            value={dateValue1}
                            inputFormat="dd-MM-yyyy"
                            onChange={(e) =>
                              setDateValue1(moment(e).format("YYYY-MM-DD"))
                            }
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                        <div>
                          <DatePicker
                            className="ml-2"
                            label="From Date"
                            value={dateValue2}
                            inputFormat="dd-MM-yyyy"
                            onChange={(e) =>
                              setDateValue2(moment(e).format("YYYY-MM-DD"))
                            }
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                      </LocalizationProvider>
                      <div className="mt-2 mx-2">
                        <button
                          className="btn btn-success"
                          style={{
                            background: "#66a725",
                            width: "100px",
                          }}
                          onClick={() => handleSubmit()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="table-responsive mt-3">
              <table class="table table-boarded" ref={tableRef}>
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Case No</th>
                    <th>Applicant Name</th>
                    <th>Mobile Number</th>
                    <th>Address</th>

                    <th>client name</th>

                    <th>Rejected By</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item?.caseReferenceNumber}</td>
                      <td>{item?.applicantName}</td>
                      <td>{item?.mobile}</td>
                      <td>{item?.address}</td>

                      <td>{item?.clientName}</td>

                      <td>{item?.rejectedBy}</td>
                      <td>{item?.remarks}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rejected;
